import { makeStyles } from "@material-ui/core/styles"

import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    Dialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(",")
    },
    MainGrid: {
        width: "98vw"
    },
    GridMargin: {
        marginTop: "10px"
    },
    RowPaper: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "10px",
        marginBottom: "15px"
    },
    ColumnBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },

    ButtonPaper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxHeight: "80px",
        alignItems: "center",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            height: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 2px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            height: "10px"
        }
    },
    ButtonContainerPaper: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        maxHeight: "80px",
        alignItems: "center",
        marginLeft: "10px",
        overflow: "hidden",
        marginBottom: "15px"
    },
    ButtonGrid: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        direction: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        wrap: "nowrap"
    },
    Stack: {
        marginLeft: "10px",
        paddingBottom: "10px"
    },
    CharacteristicStack: {
        marginLeft: "10px",
        marginBottom: "15px"
    },
    ItemPaper: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    VerificationPaper: {
        height: "100%",
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: "10px"
    },
    ItemSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        maxWidth: "98%",
        marginRight: "5px",
        marginLeft: "5px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    ItemAutoComplete: {
        marginRight: theme.spacing(2)
    },
    CloneItemSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        minWidth: "150px",
        marginRight: "5px",
        marginLeft: "5px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    ItemGridItem: {
        marginRight: "5px"
    },
    PrimarySelector: {
        minWidth: "130px",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    VerificationSelector: {
        minWidth: "130px",
        marginLeft: "5px",
        marginRight: "5px",
        marginBottom: "5px"
    },
    PPAPSelector: {
        minWidth: "130px",
        [theme.breakpoints.down("sm")]: {
            align: "right !important"
        }
    },
    Button: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        border: "1px solid",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        textTransform: "none"
    },
    ButtonNoBorder: {
        color: "white !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        marginLeft: "5px",
        marginRight: "5px",
        textTransform: "none"
    },
    AddCharacteristicButton: {
        color: "white !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        textTransform: "none"
    },
    ArchiveButton: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "84px",
        border: "1px solid",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        textTransform: "none",
        backgroundColor: "#CC1A21",
        borderColor: "#CC1A21"
    },
    PrintButton: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "64px",
        border: "1px solid",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        textTransform: "none"
    },
    CloneButton: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "76px",
        border: "1px solid",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        textTransform: "none"
    },
    HelpButton: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        fontWeight: "bold",
        minWidth: "84px",
        border: "1px solid",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        textTransform: "none",
        backgroundColor: "#DF7A00",
        borderColor: "#DF7A00"
    },
    GetOpsButton: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        border: "1px solid",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        textTransform: "none"
    },
    RevisionSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        maxWidth: "98%",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    RevisionAutoComplete: {
        marginRight: theme.spacing(2)
    },
    CloneRevisionSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        maxWidth: "100px",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    CurrentRevision: {
        fontSize: "20px !important",
        paddingLeft: "10px"
    },
    MOSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        marginLeft: "5px",
        marginRight: "5px",
        width: "180px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    WorkcenterSelector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        marginLeft: "5px",
        marginRight: "5px",
        width: "180px",
        marginTop: "5px",
        marginBottom: "5px"
    },
    GridContent: {
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
        textOverflow: "clip",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "16px"
    },
    GridContentRight: {
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "5px",
        paddingBottom: "5px",
        [theme.breakpoints.down("sm")]: {
            align: "right"
        },
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "16px"
    },
    ContainerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    DragDivider: {
        marginTop: "5px",
        marginBottom: "5px"
    },
    ResetRouting: {},
    TableCell: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px !important",
        margingTop: "1px !important"
    },
    TableCellMargin: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px !important",
        margin: "2px"
    },
    RowBorder: {
        borderBottom: "1px solid",
        borderRight: "1px solid",
        borderLeft: "1px solid"
    },
    RowBorderLast: {
        borderTop: "1px solid",
        borderRight: "1px solid",
        borderLeft: "1px solid",
        borderBottom: "1px solid"
    },
    CharacteristicPaper: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        maxHeight: "50vh",
        alignItems: "center",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            width: "10px"
        }
    },
    CharacteristicPaperAlt: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        maxHeight: "400px",
        alignItems: "center",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            width: "10px"
        }
    },
    LeftGrid: {
        overflow: "hidden",
        overflowX: "hidden"
    },
    CharacteristicContainerPaper: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        maxHeight: "60vh",
        alignItems: "center",
        marginLeft: "10px",
        marginBottom: "15px",
        overflowX: "hidden"
    },
    CharacteristicGrid: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        marginRight: "10px",
        wrap: "nowrap"
    },
    ColumnHeaderGrid: {
        marginLeft: "5px"
    },
    ColumnHeader: {
        fontSize: "14px",
        overflow: "hidden !important",
        textOverflow: "ellipsis",
        fontWeight: "bold"
    },
    CellDivider: {
        marginRight: "-1px"
    },
    CharacteristicDialog: {
        fontFamily: ["SlimIMS, Century !important"].join(",")
    },
    FullWidth: {
        width: "100% !important"
    },
    FavoritesTab: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: "10px",
        marginBottom: "5px",
        alignItems: "center",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            width: "10px"
        }
    },
    ControlTab: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        overflow: "hidden",
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: "10px",
        marginBottom: "10px",
        alignItems: "center",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
            height: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 2px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            height: "10px"
        }
    },
    ControlGrid: {},
    CharacteristicBox: {
        fontFamily: ["SlimIMS, Century !important"].join(","),
        minWidth: "350px",
        margingTop: "1px !important"
    },
    ToFrom: {
        marginBottom: "15px"
    },
    CloneGrid: {
        paddingLeft: "5px"
    },
    CloneDialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(",")
    },
    EmailButton: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(","),
        fontSize: "14px",
        fontWeight: "bold",
        border: "1px solid",
        minWidth: "136px",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "10px",
        marginBottom: "5px",
        textTransform: "none"
    },
    EditButton: {
        fontSize: "16px !important"
    },
    CloneContainer: {
        width: "600px"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
}))

export default useStyles
