import React from "react"
import brokenGears from "../../resources/images/brokenGears.png"

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error) {
        console.log(error)
    }

    render() {
        if (this.state.hasError) {
            return (
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "100px"
                    }}
                >
                    <h1>Something went wrong</h1>
                    <h2>
                        Please contact the Global Digital Solutions team for
                        support
                    </h2>
                    <img
                        style={{ maxWidth: "80%", marginTop: "50px" }}
                        src={brokenGears}
                        alt="broken gears"
                    />
                </div>
            )
        }

        return this.props.children
    }
}
