import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Paper, Grid, Snackbar, Typography } from "@material-ui/core"
import { getTolerances } from "../../actions/maintenanceActions"
import useStyles from "./styles/GTSKeyboardStyles"
import Alert from "../main/Alert"

function FavoritesTab() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const maintenance = useSelector((state) => state.maintenance)
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    //dialog state
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertType, setAlertType] = useState("")

    /*Create result in the right order with containers*/
    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                if (maintenance.frequencies.length === 0)
                    await dispatch(getTolerances())
            }
            runUseEffectFunctions()
        }
    }, [loggedIn, dispatch, maintenance.frequencies.length])

    const handleFavoritedToleranceButtonClick = (event) => {
        navigator.clipboard.writeText(
            event.target.value ? event.target.value : event.target.innerText
        )
        setAlertType("success")
        setAlertText("Copied to Clipboard!")
        setAlertDialog(true)
    }

    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    return (
        <>
            <Paper className={classes.FavoritesPaper} elevation={0}>
                <Grid container column>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignContent="flex-start"
                    >
                        <Typography variant="h5">Favorites</Typography>
                    </Grid>
                    {maintenance.tolerances.map((row, index) => (
                        <Grid item key={index}>
                            <Button
                                className={classes.Button}
                                variant="outlined"
                                onClick={handleFavoritedToleranceButtonClick}
                            >
                                {row.Tolerance}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
            {/* Alert Dialog */}
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert
                        onClose={handleAlertDialogClose}
                        severity={alertType}
                    >
                        {alertText}
                    </Alert>
                </Snackbar>
            </>
        </>
    )
}

export default FavoritesTab
