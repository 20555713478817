import dotenv from "dotenv"
dotenv.config()

let APPLICATION_ID
let backendURL
let emailBackendURL
let appAdminBackendURL
let firebaseConfig
let loginURL

const gearDepartmentNumbers = ["300"]

const ppapDepartmentNumbers = ["504"]

const emailRecipients =
    "InspectionReport_GageEmails6717@precipart0.onmicrosoft.com"

const homeURL = "https://mingle-portal.inforcloudsuite.com/PRECIPART_PRD"

const environment = process.env.REACT_APP_ENV

const unprotectedToken = process.env.REACT_APP_UNPROTECTED_TOKEN

if (process.env.REACT_APP_ENV === "development") {
    APPLICATION_ID = "3"
    backendURL = "http://localhost:5001"
    appAdminBackendURL = "http://localhost:5000"
    loginURL = "http://localhost:3000"
    emailBackendURL = "https://email-server-tdnsdphoqq-uk.a.run.app"

    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "gcpdevelopment") {
    APPLICATION_ID = "3"
    backendURL = "https://inspection-report-server-tdnsdphoqq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-tdnsdphoqq-uk.a.run.app/"
    loginURL =
        "https://application-administration-client-tdnsdphoqq-uk.a.run.app"
    emailBackendURL = "https://email-server-tdnsdphoqq-uk.a.run.app"
    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "test") {
    APPLICATION_ID = "5"
    backendURL = "https://inspection-report-server-b34iv6u2nq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-b34iv6u2nq-uk.a.run.app/"
    loginURL =
        "https://application-administration-client-b34iv6u2nq-uk.a.run.app"
    emailBackendURL = "https://email-server-b34iv6u2nq-uk.a.run.app"
    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
} else if (process.env.REACT_APP_ENV === "production") {
    APPLICATION_ID = "5"
    backendURL = "https://inspection-report-server-jaf747essq-uk.a.run.app"
    appAdminBackendURL =
        "https://application-administration-server-jaf747essq-uk.a.run.app/"
    loginURL =
        "https://application-administration-client-jaf747essq-uk.a.run.app"
    emailBackendURL = "https://email-server-jaf747essq-uk.a.run.app"
    firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASURMENTID
    }
}

export {
    APPLICATION_ID,
    backendURL,
    emailBackendURL,
    appAdminBackendURL,
    firebaseConfig,
    loginURL,
    gearDepartmentNumbers,
    ppapDepartmentNumbers,
    homeURL,
    environment,
    emailRecipients,
    unprotectedToken
}
