import {
    GET_CONTROL_METHODS,
    CREATE_CONTROL_METHOD,
    UPDATE_CONTROL_METHOD,
    DELETE_CONTROL_METHOD,
    GET_INSPECTION_METHODS,
    CREATE_INSPECTION_METHOD,
    UPDATE_INSPECTION_METHOD,
    DELETE_INSPECTION_METHOD,
    GET_FREQUENCIES,
    CREATE_FREQUENCY,
    UPDATE_FREQUENCY,
    DELETE_FREQUENCY,
    GET_TOLERANCES,
    CREATE_TOLERANCE,
    UPDATE_TOLERANCE,
    DELETE_TOLERANCE,
    GET_FAVORITES,
    CREATE_FAVORITE,
    DELETE_FAVORITE,
    GET_TOOLS,
    CREATE_TOOL,
    UPDATE_TOOL,
    DELETE_TOOL,
    UPDATE_SELECTED,
    UPDATE_IS_BEING_EDITED,
    UPDATE_MAINT_ALERT_DIALOG,
    UPDATE_MAINT_ALERT_TEXT
} from "../actions/types"

const initialState = {
    controlMethods: [],
    inspectionMethods: [],
    frequencies: [],
    tolerances: [],
    tools: [],
    favorites: [],
    selected: [],
    isBeingEdited: false,
    alertText: "",
    alertDialog: false
}

const controlMethodsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTROL_METHODS:
            return { ...state, controlMethods: action.payload }
        case CREATE_CONTROL_METHOD:
            return { ...state }
        case UPDATE_CONTROL_METHOD:
            return {
                ...state
            }
        case DELETE_CONTROL_METHOD:
            return { ...state }
        case GET_INSPECTION_METHODS:
            return { ...state, inspectionMethods: action.payload }
        case CREATE_INSPECTION_METHOD:
            return { ...state }
        case UPDATE_INSPECTION_METHOD:
            return { ...state }
        case DELETE_INSPECTION_METHOD:
            return { ...state }
        case GET_FREQUENCIES:
            return { ...state, frequencies: action.payload }
        case CREATE_FREQUENCY:
            return { ...state }
        case UPDATE_FREQUENCY:
            return { ...state }
        case DELETE_FREQUENCY:
            return { ...state }
        case GET_TOLERANCES:
            return { ...state, tolerances: action.payload }
        case CREATE_TOLERANCE:
            return { ...state }
        case UPDATE_TOLERANCE:
            return { ...state }
        case DELETE_TOLERANCE:
            return { ...state }
        case GET_FAVORITES:
            return { ...state, favorites: action.payload }
        case CREATE_FAVORITE:
            return { ...state }
        case DELETE_FAVORITE:
            return { ...state }
        case GET_TOOLS:
            return { ...state, tools: action.payload }
        case CREATE_TOOL:
            return { ...state }
        case UPDATE_TOOL:
            return { ...state }
        case DELETE_TOOL:
            return { ...state }
        case UPDATE_SELECTED:
            return { ...state, selected: action.payload }
        case UPDATE_IS_BEING_EDITED:
            return { ...state, isBeingEdited: action.payload }
        case UPDATE_MAINT_ALERT_DIALOG:
            return { ...state, alertDialog: action.payload }
        case UPDATE_MAINT_ALERT_TEXT:
            return { ...state, alertText: action.payload }
        default:
            return state
    }
}

export default controlMethodsReducer
