import React from "react"
import { useSelector } from "react-redux"
import { Grid, Typography, Box } from "@material-ui/core"
import InProcessHeader from "./InProcessHeader"
import InProcessFooter from "./InProcessFooter"

import useStyles from "./styles/OutputStyles"

function InProcessContent() {
    const classes = useStyles()
    const output = useSelector((state) => state.output)

    const numDates = [1, 2, 3, 4, 5]
    return (
        <>
            {output.characteristics
                .sort((a, b) => a.SequenceNumber - b.SequenceNumber)
                .map((item, index) => (
                    <>
                        {((index + 1) % output.numCells === 1 ||
                            index === 0) && (
                            <Box>
                                <InProcessHeader />
                            </Box>
                        )}
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Grid
                                item
                                xs={6}
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Grid
                                    item
                                    xs={4}
                                    className={
                                        item.Key
                                            ? classes.CharacteristicCellKey
                                            : classes.CharacteristicCell
                                    }
                                >
                                    <Typography
                                        multiline
                                        fullWidth
                                        variant="subtitle1"
                                        className={
                                            item.Key
                                                ? classes.CharacteristicKey
                                                : classes.Characteristic
                                        }
                                        align="center"
                                    >
                                        {item.Key ? "ëÊÄØê " : null}
                                        {item.Characteristic
                                            ? item.Characteristic
                                            : ""}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.CharacteristicCellBlank}
                                >
                                    <Typography
                                        multiline
                                        variant="subtitle1"
                                        className={classes.CharacteristicBlank}
                                        align="left"
                                    ></Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.CharacteristicCellBlank}
                                >
                                    <Typography
                                        multiline
                                        variant="subtitle1"
                                        className={classes.CharacteristicBlank}
                                        align="left"
                                    >
                                        {item.Frequency}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.CharacteristicCellBlank}
                                >
                                    <Typography
                                        multiline
                                        variant="subtitle1"
                                        className={classes.CharacteristicBlank}
                                        align="left"
                                    >
                                        {item.InspectionMethod}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.CharacteristicCellBlank}
                                >
                                    <Typography
                                        multiline
                                        variant="subtitle1"
                                        className={classes.CharacteristicBlank}
                                        align="left"
                                    >
                                        {item.Tool ? item.Tool : ""}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.CharacteristicCellBlank}
                                >
                                    {!output.ppap ? (
                                        <Typography
                                            variant="subtitle1"
                                            multiline
                                            className={
                                                classes.CharacteristicBlank
                                            }
                                            align="left"
                                        >
                                            {item.Location ? item.Location : ""}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="subtitle1"
                                            multiline
                                            className={
                                                classes.CharacteristicBlankSmall
                                            }
                                            align="left"
                                        >
                                            {item.ControlMethod}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                {!output.ppap ? null : (
                                    <>
                                        <Grid
                                            item
                                            className={
                                                classes.ControlCharacteristicEvenSpaceSmall
                                            }
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                align="left"
                                                className={
                                                    classes.CharacteristicBlank
                                                }
                                            >
                                                {item.Reaction
                                                    ? item.Reaction
                                                    : "See Q.E. or Supervisor"}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            className={
                                                classes.ControlCharacteristicEvenSpaceSmall
                                            }
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                multiline
                                                align="center"
                                                className={
                                                    classes.CharacteristicBlank
                                                }
                                            >
                                                {item.Location
                                                    ? item.Location
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                {numDates.map((item) => (
                                    <Grid
                                        item
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        className={
                                            !output.ppap
                                                ? classes.CharacteristicEvenSpace
                                                : classes.ControlCharacteristicEvenSpace
                                        }
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                            className={
                                                classes.CharacteristicEntry1
                                            }
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                className={
                                                    classes.CharacteristicEntryNum1
                                                }
                                                align="left"
                                            >
                                                1
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                className={
                                                    classes.CharacteristicEntry1
                                                }
                                                align="left"
                                                fullWidth
                                            ></Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                className={
                                                    classes.CharacteristicEntryNum2
                                                }
                                                align="left"
                                            >
                                                2
                                            </Typography>
                                            <Typography
                                                variant="subtitle1"
                                                align="left"
                                                fullWidth
                                            ></Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {(index + 1) % output.numCells === 0 &&
                            index !== 0 &&
                            index !== output.characteristics.length - 1 && (
                                <Box className={classes.BottomPush}>
                                    <InProcessFooter />
                                    <Typography
                                        variant="h4"
                                        className={classes.PageBreak}
                                        noWrap
                                        align="right"
                                    >
                                        {`Page ${Math.ceil(
                                            (index + 1) / output.numCells
                                        )} of ${Math.ceil(
                                            output.characteristics.length /
                                                output.numCells
                                        )}`}
                                    </Typography>
                                </Box>
                            )}
                        {index === output.characteristics.length - 1 && (
                            <Box className={classes.BottomPush}>
                                <InProcessFooter />
                                <Typography
                                    variant="h4"
                                    className={classes.PageBreakNo}
                                    noWrap
                                    align="right"
                                >
                                    {`Page ${Math.ceil(
                                        output.characteristics.length /
                                            output.numCells
                                    )} of ${Math.ceil(
                                        output.characteristics.length /
                                            output.numCells
                                    )}`}
                                </Typography>
                            </Box>
                        )}
                    </>
                ))}
        </>
    )
}

export default InProcessContent
