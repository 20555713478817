import { GET_AUDIT_LOG, GET_ACTIVITY, GET_ACTIVITY_BY_USER } from "./types"

import axios from "../auth/axiosAuth"
import { backendURL, appAdminBackendURL } from "../config"

export const getAuditLog = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/admin/audit`)
    const employees = await axios.get(`${appAdminBackendURL}/employee`)

    const auditLogWithEmployees = res.data.map((log) => {
        const employee = employees.data.filter(
            (employee) => employee.AssociateOID === log.ChangeBy
        )[0]

        const EmployeeName = employee
            ? `${employee.FirstName} ${employee.LastName}`
            : "Unknown"
        return {
            ChangeId: log.ChangeId,
            AlteredId: log.AlteredId,
            Action: log.Action,
            OldValue: log.OldValue,
            NewValue: log.NewValue,
            ChangeBy: EmployeeName,
            ChangeDate: log.ChangeDate
        }
    })

    dispatch({
        type: GET_AUDIT_LOG,
        payload: auditLogWithEmployees
    })
}

export const getActivity = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/admin/activity`)
    dispatch({
        type: GET_ACTIVITY,
        payload: res.data
    })
}

export const getUserActivity = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/admin/user-activity`)
    const employees = await axios.get(`${appAdminBackendURL}/employee`)

    const userActivityWithEmployees = res.data.map((activity) => {
        const employee = employees.data.filter(
            (employee) => employee.AssociateOID === activity.x
        )[0]

        const EmployeeName = employee
            ? `${employee.FirstName} ${employee.LastName}`
            : "Unknown"
        return {
            Employee: EmployeeName,
            ActionCount: activity.y
        }
    })

    dispatch({
        type: GET_ACTIVITY_BY_USER,
        payload: userActivityWithEmployees
    })
}
