import React from "react"
import { Grid } from "@material-ui/core"
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid"
import useStyles from "../styles/AdminStyles"

function AuditToolBar() {
    const classes = useStyles()
    return (
        <Grid>
            {/*ToolBar*/}
            <GridToolbarContainer className={classes.Button}>
                <GridToolbarFilterButton className={classes.Button} />
                <GridToolbarDensitySelector className={classes.Button} />
                <GridToolbarExport className={classes.Button} />
            </GridToolbarContainer>
        </Grid>
    )
}

export default AuditToolBar
