import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Tabs, Tab, Box, AppBar, Snackbar } from "@material-ui/core"
import { useTheme } from "@mui/material/styles"
import SwipeableViews from "react-swipeable-views"
import PropTypes from "prop-types"
import useStyles from "./styles/MaintenanceStyles"
import LoginPrompt from "../login/LoginPrompt"
import ControlMethod from "./controlMethod/ControlMethodGrid"
import InspectionMethod from "./inspectionMethod/InspectionMethodGrid"
import Frequency from "./frequency/FrequencyGrid"
import Tool from "./tools/ToolGrid"
import Alert from "../main/Alert"
import { setAlertDialog } from "../../actions/maintenanceActions"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    }
}

function MaintenancePage() {
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()

    // Store Variables
    const auth = useSelector((state) => state.auth)
    const alertDialog = useSelector((state) => state.maintenance.alertDialog)
    const alertText = useSelector((state) => state.maintenance.alertText)

    // Tab State
    const [value, setValue] = useState(0)

    // Tab Functions
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        dispatch(setAlertDialog(false))
    }

    return (
        <>
            {auth.authenticated ? (
                <>
                    <Typography
                        variant="h4"
                        align="center"
                        className={classes.MaintenanceTitle}
                    >
                        Maintenance
                    </Typography>
                    <Box
                        sx={{
                            bgcolor: "background.paper",
                            borderRadius: "10px"
                        }}
                        className={classes.TabPanel}
                    >
                        <AppBar position="Static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                            >
                                <Tab
                                    label="Control Methods"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label="Inspection Methods"
                                    {...a11yProps(1)}
                                />
                                <Tab label="Frequencies" {...a11yProps(2)} />
                                {/* <Tab label="Tools" {...a11yProps(3)} /> */}
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                                className={classes.TabPanel}
                            >
                                <ControlMethod />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={1}
                                dir={theme.direction}
                                className={classes.TabPanel}
                            >
                                <InspectionMethod />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={2}
                                dir={theme.direction}
                                className={classes.TabPanel}
                            >
                                <Frequency />
                            </TabPanel>
                            <TabPanel
                                value={value}
                                index={3}
                                dir={theme.direction}
                                className={classes.TabPanel}
                            >
                                <Tool />
                            </TabPanel>
                        </SwipeableViews>
                    </Box>

                    {/* Alert Dialog */}
                    <div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={alertDialog}
                            autoHideDuration={5000}
                            onClose={handleAlertDialogClose}
                        >
                            <Alert
                                onClose={handleAlertDialogClose}
                                severity={"Error"}
                                className={classes.Dialog}
                            >
                                {alertText}
                            </Alert>
                        </Snackbar>
                    </div>
                </>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default MaintenancePage
