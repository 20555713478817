import {
    GET_INSPECTIONS,
    CREATE_INSPECTION,
    CREATE_ARCHIVE_INSPECTION,
    UPDATE_INSPECTION,
    DELETE_INSPECTION,
    SET_INSPECTION,
    UPDATE_PPAP,
    GET_LAST_UPDATED_BY
} from "../actions/types"
import axios from "../auth/axiosAuth"
import { backendURL, appAdminBackendURL, unprotectedToken } from "../config"

export const getInspections = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/inspections`, {
        headers: {
            "token": unprotectedToken
        }
    })
    const employees = await axios.get(`${appAdminBackendURL}/employee`)
    const inspectionsWithEmployee = res.data.map((inspection) => {
        const verifyEmployee = employees.data.filter(
            (employee) => employee.AssociateOID === inspection.VerifiedBy
        )[0]

        const EmployeeName = verifyEmployee
            ? `${verifyEmployee.FirstName} ${verifyEmployee.LastName}`
            : "Unknown"

        const secondEmployee = employees.data.filter(
            (employee) => employee.AssociateOID === inspection.SecondVerifiedBy
        )[0]

        const SecondEmployeeName = secondEmployee
            ? `${secondEmployee.FirstName} ${secondEmployee.LastName}`
            : "Unknown"

        return {
            InspectionId: inspection.InspectionId,
            ItemId: inspection.ItemId,
            Operation: inspection.Operation,
            Workcenter: inspection.Workcenter,
            PrimaryFlag: inspection.PrimaryFlag,
            PPAPFlag: inspection.PPAPFlag,
            ArchiveFlag: inspection.ArchiveFlag,
            VerifiedBy: EmployeeName,
            VerifiedByOID: inspection.VerifiedBy,
            VerifiedDate: inspection.VerifiedDate,
            SecondVerifiedBy: SecondEmployeeName,
            SecondVerifiedByOID: inspection.SecondVerifiedBy,
            SecondVerifiedDate: inspection.SecondVerifiedDate,
            ManufacturingOrder: inspection.ManufacturingOrder,
            Material: inspection.Material,
            Certification: inspection.Certification
        }
    })

    dispatch({
        type: GET_INSPECTIONS,
        payload: inspectionsWithEmployee
    })
}

export const createInspection = (data) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/inspections`, data)
    dispatch({
        type: CREATE_INSPECTION,
        payload: res.data
    })
}

export const archiveInspection = (data) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/inspections/archive`, data)
    dispatch({
        type: CREATE_ARCHIVE_INSPECTION,
        payload: res.data
    })
}

export const updateInspection = (data) => async (dispatch) => {
    const res = await axios.put(`${backendURL}/inspections/`, data)
    dispatch({
        type: UPDATE_INSPECTION,
        payload: res.data
    })
}

export const deleteInspection = (toDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/inspections/`, { data: toDelete })
    dispatch({
        type: DELETE_INSPECTION,
        payload: toDelete
    })
}

export const setInspection = (inspection) => async (dispatch) => {
    dispatch({
        type: SET_INSPECTION,
        payload: inspection
    })
}

export const updatePPAP = (data) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/inspections/ppap`, data)
    dispatch({
        type: UPDATE_PPAP,
        payload: res.data
    })
}

export const getLastUpdatedBy = (data) => async (dispatch) => {
    const res = await axios.get(`${appAdminBackendURL}/inspections/audit`, data)
    dispatch({
        type: GET_LAST_UPDATED_BY,
        payload: res.data
    })
}
