import {
    GET_INSPECTIONS,
    CREATE_INSPECTION,
    CREATE_ARCHIVE_INSPECTION,
    UPDATE_INSPECTION,
    DELETE_INSPECTION,
    SET_INSPECTION
} from "../actions/types"

const initialState = {
    inspections: [],
    inspectionId: ""
}

const inspectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INSPECTIONS:
            return { ...state, inspections: action.payload }
        case CREATE_INSPECTION:
            return {
                ...state,
                inspections: [...state.inspections, action.payload]
            }
        case CREATE_ARCHIVE_INSPECTION:
            return {
                ...state,
                inspections: [...state.inspections, action.payload]
            }
        case UPDATE_INSPECTION:
            return { ...state }
        case DELETE_INSPECTION:
            return {
                ...state,
                inspections: state.inspections.filter(
                    (inspection) => inspection._id !== action.payload
                )
            }
        case SET_INSPECTION:
            return { ...state, inspectionId: action.payload }
        default:
            return state
    }
}

export default inspectionReducer
