import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import LoginPrompt from "../login/LoginPrompt"
import {
    Button,
    Typography,
    Snackbar,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Box,
    Tabs,
    Tab,
    AppBar,
    Paper,
    IconButton,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@material-ui/core"
import {
    createTolerance,
    deleteTolerance,
    getTolerances
} from "../../actions/maintenanceActions"
import { useTheme } from "@mui/material/styles"
import SwipeableViews from "react-swipeable-views"
import PropTypes from "prop-types"
import useStyles from "./styles/GTSKeyboardStyles"
import Alert from "../main/Alert"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import {
    leadingSymbols,
    tolerances,
    datums,
    alphabets,
    roots,
    fractions,
    additionalModifiers,
    leadingContainers,
    trailingContainers,
    containers,
    modifier,
    modifierLabel,
    row1,
    row2,
    row3,
    row4
} from "./GTSConstants"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`
    }
}

function GTSKeyboard() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme()

    const maintenance = useSelector((state) => state.maintenance)
    const permissions = useSelector((state) => state.auth.permissions)
    const auth = useSelector((state) => state.auth)
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    // state Variables
    const [leadingSymbol, setLeadingSymbol] = useState("")
    const [toleranceSymbol, setToleranceSymbol] = useState("")
    const [tolerance, setTolerance] = useState("")
    const [datum, setDatum] = useState("")
    const [toleranceExists, setToleranceExists] = useState(false)

    //symbol tab states
    const [additionalSymbolString, setAdditionalSymbolString] = useState("")
    const [additionalRoot, setAdditionalRoot] = useState("")
    const [additionalFraction, setAdditionalFraction] = useState("")
    const [additionalDatum, setAdditionalDatum] = useState("")
    const [additionalModifier, setAdditionalModifier] = useState("")
    const [additionalContainer, setAdditionalContainer] = useState({
        selected: "",
        leading: "",
        trailing: ""
    })

    //aplhabet state for datums
    const [additonalDatum1, setAdditionalDatum1] = useState("")
    const [additonalDatum2, setAdditionalDatum2] = useState("")
    const [additonalDatum3, setAdditionalDatum3] = useState("")

    //datum states
    const [alphaDatum1, setAlphaDatum1] = useState("")
    const [alphaDatum2, setAlphaDatum2] = useState("")
    const [alphaDatum3, setAlphaDatum3] = useState("")
    //final output
    const [result, setResult] = useState("")

    //dialog state
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertType, setAlertType] = useState("")

    //checkbox state
    const [checkbox, setCheckbox] = useState(false)

    // Tab State
    const [value, setValue] = useState(0)

    // Delete Dialog State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    /*Create result in the right order with containers*/
    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getTolerances())
            }
            runUseEffectFunctions()
        }
    }, [loggedIn, dispatch, maintenance.tolerances])

    useEffect(() => {
        setResult(
            [
                additionalContainer.leading
                    ? leadingContainers.includes(additionalContainer.leading)
                        ? additionalContainer.leading
                        : ""
                    : "",
                leadingSymbol ? ["", leadingSymbol, "\xec"].join("") : "",
                toleranceSymbol,
                tolerance,
                datum,
                alphaDatum1
                    ? [
                          "\xec",
                          alphaDatum1,
                          additonalDatum1 ? additonalDatum1 : "",
                          additonalDatum2 ||
                          additonalDatum3 ||
                          alphaDatum2 ||
                          alphaDatum3
                              ? "\xec"
                              : ""
                      ].join("")
                    : additonalDatum1
                    ? [
                          "\xec",
                          additonalDatum1,
                          additonalDatum2 ||
                          additonalDatum3 ||
                          alphaDatum2 ||
                          alphaDatum3
                              ? "\xec"
                              : ""
                      ].join("")
                    : "",
                alphaDatum2
                    ? [
                          alphaDatum2,
                          additonalDatum2 ? additonalDatum2 : "",
                          additonalDatum3 || alphaDatum3 ? "\xec" : ""
                      ].join("")
                    : additonalDatum2
                    ? [
                          additonalDatum2,
                          additonalDatum3 || alphaDatum3 ? "\xec" : ""
                      ].join("")
                    : "",
                alphaDatum3
                    ? [
                          alphaDatum3,
                          additonalDatum3 ? additonalDatum3 : ""
                      ].join("")
                    : additonalDatum3,
                additionalSymbolString,
                additionalContainer.trailing
                    ? trailingContainers.includes(additionalContainer.trailing)
                        ? additionalContainer.trailing
                        : ""
                    : ""
            ].join("")
        )
        if (
            maintenance.tolerances.filter((t) => t.Tolerance === result)
                .length > 0
        ) {
            setToleranceExists(true)
        } else {
            setToleranceExists(false)
        }
    }, [
        additionalRoot,
        maintenance,
        leadingSymbol,
        toleranceSymbol,
        tolerance,
        datum,
        alphaDatum1,
        alphaDatum2,
        alphaDatum3,
        additonalDatum1,
        additonalDatum2,
        additonalDatum3,
        additionalSymbolString,
        additionalContainer,
        result
    ])
    // Tab Functions
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    //Tolerance tab handelers
    const leadingSymbolOnChange = (event) => {
        setLeadingSymbol(event.target.value)
        additionalContainer
            ? setAdditionalContainer({
                  selected: "",
                  leading: "\xeb",
                  trailing: "\xed"
              })
            : setAdditionalContainer(...additionalContainer)
    }

    const toleranceOnChange = (event) => {
        setTolerance(event.target.value)
    }

    const datumOnChange = (event) => {
        setDatum(event.target.value)
    }

    const handleCheckboxChange = (event) => {
        setCheckbox(event.target.checked)
        setToleranceSymbol(event.target.checked ? modifier : "")
    }

    const handleDatum1Change = (event) => {
        setAdditionalDatum1(event.target.value)
    }

    const handleDatum2Change = (event) => {
        setAdditionalDatum2(event.target.value)
    }

    const handleDatum3Change = (event) => {
        setAdditionalDatum3(event.target.value)
    }

    const handleAlphaDatum1Change = (event) => {
        setAlphaDatum1(event.target.value)
    }

    const handleAlphaDatum2Change = (event) => {
        setAlphaDatum2(event.target.value)
    }

    const handleAlphaDatum3Change = (event) => {
        setAlphaDatum3(event.target.value)
    }

    //keyboard tab handlers
    const handleKeyboardClick = (event) => {
        setAdditionalSymbolString(
            [
                ...additionalSymbolString,
                event.target.value
                    ? event.target.value
                    : event.target.innerHTML ===
                      "ë\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013í"
                    ? "\u2013"
                    : event.target.innerText
            ].join("")
        )
    }

    const handleAdditionalDatumChange = (event) => {
        setAdditionalDatum(event.target.value)
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.value].join("")
        )
    }

    const handleAdditionalDatumClick = (event) => {
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.dataset.value].join("")
        )
    }

    const handleAdditionalFractionChange = (event) => {
        setAdditionalFraction(event.target.value)
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.value].join("")
        )
    }

    const handleAdditionalFractionClick = (event) => {
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.dataset.value].join("")
        )
    }

    const handleAdditionalRootChange = (event) => {
        setAdditionalRoot(event.target.value)
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.value].join("")
        )
    }

    const handleAdditionalRootClick = (event) => {
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.dataset.value].join("")
        )
        console.log(row1)
    }

    const handleAdditionalModifierChange = (event) => {
        setAdditionalModifier(event.target.value)
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.value].join("")
        )
    }

    const handleAdditionalModifierClick = (event) => {
        setAdditionalSymbolString(
            [...additionalSymbolString, event.target.dataset.value].join("")
        )
    }

    const handleAdditionalContainerChange = (event) => {
        if (leadingContainers.includes(event.target.value)) {
            setAdditionalContainer({
                ...additionalContainer,
                leading: event.target.value,
                selected: event.target.value
            })
        } else if (trailingContainers.includes(event.target.value)) {
            setAdditionalContainer({
                ...additionalContainer,
                trailing: event.target.value,
                selected: event.target.value
            })
        } else {
            setAdditionalContainer({
                ...additionalContainer,
                selected: event.target.value
            })
            setAdditionalSymbolString(
                [...additionalSymbolString, event.target.value].join("")
            )
        }
    }

    //Result Handelers and Dialog
    const handleCopyButtonClick = () => {
        navigator.clipboard.writeText(result)
        setAlertType("success")
        setAlertText("Copied to Clipboard!")
        setAlertDialog(true)
    }

    const handleFavoriteClick = async () => {
        console.log(result)
        if (result !== "") {
            try {
                const favorite = result
                const newFavorite = {
                    Tolerance: favorite,
                    AuditLog: {
                        Action: "Favorite Tolerance",
                        OldValue: "",
                        NewValue: favorite,
                        AssociateOID: auth.userData.AssociateOID
                    }
                }
                await dispatch(createTolerance(newFavorite))
                await dispatch(getTolerances())
            } catch (error) {
                console.log(error)
            }
        } else {
            setAlertType("error")
            setAlertText("Tolerance field must be filled out")
            setAlertDialog(true)
        }
    }

    const handleUnFavoriteClick = async () => {
        try {
            const favorite = result
            const deleteFavorite = {
                Tolerance: favorite,
                AuditLog: {
                    Action: "Unfavorite Tolerance",
                    OldValue: favorite,
                    NewValue: "",
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(deleteTolerance(deleteFavorite))
            await dispatch(getTolerances())
            setDeleteDialogOpen(false)
        } catch (error) {
            console.log(error)
            setAlertType("error")
            setAlertText("Cannot Unfavorite Tolerance")
            setAlertDialog(true)
        }
    }

    const handleDeleteDialogClose = () => {
        handleClearButtonClick()
        setDeleteDialogOpen(false)
    }

    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true)
    }

    const handleFavoritedToleranceButtonClick = (event) => {
        handleClearButtonClick()
        navigator.clipboard.writeText(
            event.target.value ? event.target.value : event.target.innerText
        )
        setAdditionalContainer({
            selected: "",
            leading: event.target.value
                ? event.target.value.charAt(0)
                : event.target.innerText.charAt(0),
            trailing: event.target.value
                ? event.target.value.charAt(event.target.value.length - 1)
                : event.target.innerText.charAt(
                      event.target.innerText.length - 1
                  )
        })
        setAdditionalSymbolString(
            event.target.value
                ? leadingContainers.includes(event.target.value.charAt(0))
                    ? event.target.value.substring(
                          1,
                          event.target.value.length - 1
                      )
                    : event.target.value
                : leadingContainers.includes(event.target.innerText.charAt(0))
                ? event.target.innerText.substring(
                      1,
                      event.target.innerText.length - 1
                  )
                : event.target.innerText
        )

        setAlertType("success")
        setAlertText("Copied to Clipboard!")
        setAlertDialog(true)
    }

    const handleClearButtonClick = () => {
        setLeadingSymbol("")
        setToleranceSymbol("")
        setTolerance("")
        setDatum("")
        setResult("")
        setAdditionalDatum1("")
        setAdditionalDatum2("")
        setAdditionalDatum3("")
        setAlphaDatum1("")
        setAlphaDatum2("")
        setAlphaDatum3("")
        setCheckbox(false)
        setAdditionalSymbolString("")
        setAdditionalRoot("")
        setAdditionalDatum("")
        setAdditionalFraction("")
        setAdditionalModifier("")
        setAdditionalContainer({
            leading: "",
            trailing: "",
            selected: ""
        })
    }

    const handleBackSpace = () => {
        setAdditionalSymbolString(
            [...additionalSymbolString].slice(0, -1).join("")
        )
    }

    const handleEnterButtonClick = () => {
        setAdditionalSymbolString([...additionalSymbolString, "\n"].join(""))
    }

    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    return (
        <>
            {auth.authenticated ? (
                <Box className={classes.ContainerBox}>
                    <Typography variant="h4" className={classes.Title}>
                        GTS Keyboard
                    </Typography>
                    <Paper
                        sx={{ bgcolor: "background.paper" }}
                        className={classes.PaperContainer}
                        elevation={1}
                    >
                        <AppBar position="Static">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                            >
                                <Tab label="Favorites" {...a11yProps(0)} />
                                <Tab label="Tolerance" {...a11yProps(1)} />
                                <Tab label="Keyboard" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                        <SwipeableViews
                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                        >
                            <TabPanel
                                value={value}
                                index={0}
                                dir={theme.direction}
                            >
                                <Paper
                                    className={classes.TransparentPaper}
                                    elevation={0}
                                >
                                    <Grid container columns={{ xs: 2 }}>
                                        {maintenance.tolerances.map(
                                            (row, index) => (
                                                <Grid item key={index}>
                                                    <Button
                                                        className={
                                                            classes.Button
                                                        }
                                                        variant="outlined"
                                                        onClick={
                                                            handleFavoritedToleranceButtonClick
                                                        }
                                                    >
                                                        {row.Tolerance}
                                                    </Button>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </Paper>
                            </TabPanel>
                            {/* Tolerance Tab*/}
                            <TabPanel
                                value={value}
                                index={1}
                                dir={theme.direction}
                            >
                                <Box className={classes.RowBoxBaseLine}>
                                    <Box className={classes.RowBox}>
                                        <Box className={classes.RowBoxBorder}>
                                            {/* Leading Symbol Slector*/}
                                            <FormControlLabel
                                                control={
                                                    <Select
                                                        id="leading-symbol-select"
                                                        value={leadingSymbol}
                                                        onChange={
                                                            leadingSymbolOnChange
                                                        }
                                                        className={
                                                            classes.Selector
                                                        }
                                                    >
                                                        <MenuItem
                                                            value=""
                                                            className={
                                                                classes.General
                                                            }
                                                        >
                                                            {"\u2013"}
                                                        </MenuItem>
                                                        {leadingSymbols.map(
                                                            (symbol) => (
                                                                <MenuItem
                                                                    key={symbol}
                                                                    value={
                                                                        symbol
                                                                    }
                                                                    className={
                                                                        classes.General
                                                                    }
                                                                >
                                                                    {symbol}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                }
                                                labelPlacement="top"
                                                label="Symbol"
                                            />
                                        </Box>
                                        {/* Tolerance Box*/}
                                        <Box
                                            className={classes.RowBoxTolerance}
                                        >
                                            {/* Tolerance Symbol Selector*/}
                                            <FormControlLabel
                                                control={
                                                    <Box
                                                        className={
                                                            classes.RowBox
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    value={
                                                                        toleranceSymbol
                                                                    }
                                                                    checked={
                                                                        checkbox
                                                                    }
                                                                    className={
                                                                        classes.General
                                                                    }
                                                                    onChange={
                                                                        handleCheckboxChange
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                modifierLabel
                                                            }
                                                            className={
                                                                classes.General
                                                            }
                                                            labelPlacement="end"
                                                        />
                                                        {/* Tolerance Number Selector*/}
                                                        <Select
                                                            id="tolerance-select"
                                                            value={tolerance}
                                                            onChange={
                                                                toleranceOnChange
                                                            }
                                                            className={
                                                                classes.Selector
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {tolerances.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        {/* Tolerance Datum Selector*/}
                                                        <Select
                                                            id="datumns-select"
                                                            value={datum}
                                                            onChange={
                                                                datumOnChange
                                                            }
                                                            className={
                                                                classes.Selector
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {datums.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                }
                                                label="Tolerance"
                                                labelPlacement="top"
                                            />
                                        </Box>
                                    </Box>
                                    {/* Additional Datum Selector*/}
                                    <Box className={classes.ContainerBoxBorder}>
                                        <FormControlLabel
                                            control={
                                                <Box
                                                    className={
                                                        classes.ContainerBox
                                                    }
                                                >
                                                    <Box
                                                        className={
                                                            classes.RowBox
                                                        }
                                                    >
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={alphaDatum1}
                                                            onChange={
                                                                handleAlphaDatum1Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {alphabets.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={
                                                                additonalDatum1
                                                            }
                                                            onChange={
                                                                handleDatum1Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {datums.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.RowBox
                                                        }
                                                    >
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={alphaDatum2}
                                                            onChange={
                                                                handleAlphaDatum2Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {alphabets.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={
                                                                additonalDatum2
                                                            }
                                                            onChange={
                                                                handleDatum2Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {datums.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        className={
                                                            classes.RowBox
                                                        }
                                                    >
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={alphaDatum3}
                                                            onChange={
                                                                handleAlphaDatum3Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {"\u2013"}
                                                            </MenuItem>
                                                            {alphabets.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                        <Select
                                                            className={
                                                                classes.Selector
                                                            }
                                                            value={
                                                                additonalDatum3
                                                            }
                                                            onChange={
                                                                handleDatum3Change
                                                            }
                                                        >
                                                            <MenuItem
                                                                value=""
                                                                className={
                                                                    classes.General
                                                                }
                                                            ></MenuItem>
                                                            {datums.map(
                                                                (symbol) => (
                                                                    <MenuItem
                                                                        key={
                                                                            symbol
                                                                        }
                                                                        value={
                                                                            symbol
                                                                        }
                                                                        className={
                                                                            classes.General
                                                                        }
                                                                    >
                                                                        {symbol}
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            }
                                            labelPlacement="top"
                                            label="Datums"
                                        ></FormControlLabel>
                                    </Box>
                                </Box>
                            </TabPanel>
                            {/*Keyboard Tab*/}
                            <TabPanel
                                value={value}
                                index={2}
                                dir={theme.direction}
                            >
                                <Box className={classes.ContainerBox}>
                                    <Box className={classes.RowBox}>
                                        {/* Container Selector */}
                                        <FormControlLabel
                                            control={
                                                <Select
                                                    className={classes.Selector}
                                                    value={
                                                        additionalContainer.selected
                                                    }
                                                    onChange={
                                                        handleAdditionalContainerChange
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        {"\u2013"}
                                                    </MenuItem>
                                                    {containers.map(
                                                        (symbol) => (
                                                            <MenuItem
                                                                key={symbol}
                                                                value={symbol}
                                                                className={
                                                                    classes.General
                                                                }
                                                            >
                                                                {symbol}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            }
                                            label="Containers"
                                            labelPlacement="top"
                                        ></FormControlLabel>
                                        {/* Modifier Selector */}
                                        <FormControlLabel
                                            control={
                                                <Select
                                                    className={classes.Selector}
                                                    value={additionalModifier}
                                                    onChange={
                                                        handleAdditionalModifierChange
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        {"\u2013"}
                                                    </MenuItem>
                                                    {additionalModifiers.map(
                                                        (symbol) => (
                                                            <MenuItem
                                                                key={symbol}
                                                                value={symbol}
                                                                className={
                                                                    classes.General
                                                                }
                                                                onClick={
                                                                    handleAdditionalModifierClick
                                                                }
                                                            >
                                                                {symbol}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            }
                                            label="Modifiers"
                                            labelPlacement="top"
                                        ></FormControlLabel>
                                        {/* Fraction Selector */}
                                        <FormControlLabel
                                            control={
                                                <Select
                                                    className={classes.Selector}
                                                    value={additionalFraction}
                                                    onChange={
                                                        handleAdditionalFractionChange
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        {"\u2013"}
                                                    </MenuItem>
                                                    {fractions.map((symbol) => (
                                                        <MenuItem
                                                            key={symbol}
                                                            value={symbol}
                                                            className={
                                                                classes.General
                                                            }
                                                            onClick={
                                                                handleAdditionalFractionClick
                                                            }
                                                        >
                                                            {symbol}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            label="Fractions"
                                            labelPlacement="top"
                                        ></FormControlLabel>
                                        <FormControlLabel
                                            control={
                                                <Select
                                                    className={classes.Selector}
                                                    value={additionalRoot}
                                                    onChange={
                                                        handleAdditionalRootChange
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        {"\u2013"}
                                                    </MenuItem>
                                                    {roots.map((symbol) => (
                                                        <MenuItem
                                                            key={symbol}
                                                            value={symbol}
                                                            className={
                                                                classes.General
                                                            }
                                                            onClick={
                                                                handleAdditionalRootClick
                                                            }
                                                        >
                                                            {symbol}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            label="Roots"
                                            labelPlacement="top"
                                        ></FormControlLabel>
                                        {/* Datum Selector */}
                                        <FormControlLabel
                                            control={
                                                <Select
                                                    className={classes.Selector}
                                                    value={additionalDatum}
                                                    onChange={
                                                        handleAdditionalDatumChange
                                                    }
                                                >
                                                    <MenuItem value="">
                                                        {"\u2013"}
                                                    </MenuItem>
                                                    {datums.map((symbol) => (
                                                        <MenuItem
                                                            key={symbol}
                                                            value={symbol}
                                                            className={
                                                                classes.General
                                                            }
                                                            onClick={
                                                                handleAdditionalDatumClick
                                                            }
                                                        >
                                                            {symbol}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            }
                                            label="Datums"
                                            labelPlacement="top"
                                        ></FormControlLabel>
                                    </Box>
                                    <Box className={classes.RowBox}>
                                        {row1.map((symbol) => (
                                            <Button
                                                key={symbol}
                                                value={symbol}
                                                className={classes.Button}
                                                onClick={handleKeyboardClick}
                                                variant="outlined"
                                            >
                                                {symbol}
                                            </Button>
                                        ))}
                                    </Box>
                                    <Box className={classes.RowBox}>
                                        {row2.map((symbol) => (
                                            <Button
                                                key={symbol}
                                                value={symbol}
                                                className={classes.Button}
                                                onClick={handleKeyboardClick}
                                                variant="outlined"
                                            >
                                                {symbol}
                                            </Button>
                                        ))}
                                    </Box>
                                    <Box className={classes.RowBox}>
                                        {row3.map((symbol) => (
                                            <Button
                                                key={symbol}
                                                value={symbol}
                                                className={classes.Button}
                                                onClick={handleKeyboardClick}
                                                variant="outlined"
                                            >
                                                {symbol}
                                            </Button>
                                        ))}
                                        <Button
                                            id="enter"
                                            onClick={handleEnterButtonClick}
                                            variant="outlined"
                                        >
                                            Enter
                                        </Button>
                                    </Box>
                                    <Box className={classes.RowBox}>
                                        {row4.map((symbol) => (
                                            <Button
                                                key={symbol}
                                                value={symbol}
                                                className={classes.Button}
                                                onClick={handleKeyboardClick}
                                                variant="outlined"
                                            >
                                                {symbol}
                                            </Button>
                                        ))}

                                        <Button
                                            id="backspace"
                                            onClick={handleBackSpace}
                                            variant="outlined"
                                        >
                                            BackSpace
                                        </Button>
                                    </Box>
                                    <Button
                                        className={classes.Button}
                                        value={"\u2013"}
                                        key={"\u2013"}
                                        onClick={handleKeyboardClick}
                                        variant="outlined"
                                    >
                                        {
                                            "ë\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013\u2013í"
                                        }
                                    </Button>
                                </Box>
                            </TabPanel>
                        </SwipeableViews>
                    </Paper>

                    {/* Bottom Buttons And Result*/}
                    <Box className={classes.RowBox}>
                        {result && (
                            <IconButton
                                aria-label="Favorite"
                                alertText="Favorite"
                                disabled={!permissions.EditFavorites}
                                onClick={
                                    permissions.EditFavorites
                                        ? toleranceExists
                                            ? handleDeleteDialogOpen
                                            : handleFavoriteClick
                                        : null
                                }
                            >
                                {toleranceExists ? (
                                    <StarIcon />
                                ) : (
                                    <StarBorderIcon />
                                )}
                            </IconButton>
                        )}
                        <Typography
                            value={result}
                            variant="h3"
                            className={classes.Result}
                        >
                            {result}
                        </Typography>
                    </Box>
                    <Box className={classes.RowBox}>
                        <Button id="copy" onClick={handleCopyButtonClick}>
                            Copy
                        </Button>
                        <Button
                            id="clearButton"
                            onClick={handleClearButtonClick}
                        >
                            Clear
                        </Button>
                    </Box>
                </Box>
            ) : (
                <LoginPrompt />
            )}
            {/*Delete Tolerance Dialog*/}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                className={classes.Dialog}
            >
                <DialogTitle>Unfavorite Tolerance</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText className={classes.Dialog}>
                        Are you sure you would like to unfavorite {result}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleUnFavoriteClick}
                        className={classes.Dialog}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleDeleteDialogClose}
                        className={classes.Dialog}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Alert Dialog */}
            <>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert
                        onClose={handleAlertDialogClose}
                        severity={alertType}
                    >
                        {alertText}
                    </Alert>
                </Snackbar>
            </>
        </>
    )
}

export default GTSKeyboard
