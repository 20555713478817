import { makeStyles } from "@material-ui/core/styles"

import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    itemNumberText: {
        paddingRight: theme.spacing(1),
        marginLeft: theme.spacing(2)
    },
    revisionText: {
        paddingRight: theme.spacing(1)
    },
    operationText: {
        paddingRight: theme.spacing(1)
    },
    descriptionText: {
        paddingRight: theme.spacing(4),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(3),
        width: "100%"
    },
    errorMessage: {
        color: theme.palette.error.main
    },
    Dialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(",")
    }
}))

export default useStyles
