import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button } from "@material-ui/core"
import {
    getItmrva,
    getItmprc,
    getFacmst,
    getRtgopr
} from "../../actions/erpActions"
import {
    getInspections,
    updateInspection
} from "../../actions/inspectionActions"

function WorkcenterUpdate() {
    const dispatch = useDispatch()

    const auth = useSelector((state) => state.auth)
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const itmrva = useSelector((state) => state.erp.itmrva)
    const itmprc = useSelector((state) => state.erp.itmprc)
    const facmst = useSelector((state) => state.erp.facmst)
    const rtgopr = useSelector((state) => state.erp.rtgopr)
    const inspections = useSelector((state) => state.inspection.inspections)

    const [loading, setLoading] = React.useState(true)

    useEffect(() => {
        if (loggedIn) {
            async function fetchData() {
                setLoading(true)
                try {
                    if (inspections.length === 0)
                        await dispatch(getInspections())
                    if (itmrva.length === 0) await dispatch(getItmrva())
                    if (itmprc.length === 0) await dispatch(getItmprc())
                    if (facmst.length === 0) await dispatch(getFacmst())
                    if (rtgopr.length === 0) await dispatch(getRtgopr())
                } catch (error) {
                    console.log(error)
                }
                setLoading(false)
            }
            fetchData()
        }
    }, [dispatch, loggedIn, inspections, itmrva, itmprc, facmst, rtgopr])

    const updateWorkcenterClick = async () => {
        setLoading(true)

        const newOps = rtgopr
        const oldOps = inspections

        for (let i = 0; i < oldOps.length; i++) {
            //Get information required to find the RTGOPR record
            const rtgoprKeys = itmprc.filter(
                (item) =>
                    `${item.STID.trim()}:::${item.ITNBR.trim()}:::${item.ITRV.trim()}` ===
                    oldOps[i].ItemId
            )[0]
            const itemRTID = rtgoprKeys?.RTID
            const itemRTVR = rtgoprKeys?.RTVR

            //Get the RTGOPR record
            const rtgoprID = `PRD:::${itemRTID?.trimEnd()}:::${
                itemRTVR?.trimEnd() === "blank" ? "" : itemRTVR?.trimEnd()
            }:::${oldOps[i].Operation}`
            const rtgoprInspection = newOps.find(
                (item) => item.RTGOPRID === rtgoprID
            )
            //Create Updated Inspection
            if (
                rtgoprInspection?.WKCTR?.trim() !== "" &&
                rtgoprInspection?.WKCTR !== null &&
                rtgoprInspection?.WKCTR !== undefined &&
                rtgoprInspection?.WKCTR?.trim() !==
                    oldOps[i]?.Workcenter?.trim()
            ) {
                const newInspection = {
                    InspectionId: oldOps[i]?.InspectionId,
                    Workcenter: rtgoprInspection?.WKCTR,
                    AuditLog: {
                        Action: "Mass Routing Update",
                        OldValue: oldOps[i]?.Workcenter,
                        NewValue: rtgoprInspection?.WKCTR,
                        AssociateOID: auth.userData.AssociateOID
                    }
                }
                await dispatch(updateInspection(newInspection))
            }
        }
        setLoading(false)
        console.log("Done")
    }

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={updateWorkcenterClick}
                disabled={loading}
            >
                Update Workcenters
            </Button>
        </>
    )
}

export default WorkcenterUpdate
