import React from "react"
import { Grid, Card, CardContent } from "@material-ui/core"
import useStyles from "./styles/LoginPageStyles"
import Login from "./Login"
import logo from "../../resources/images/precipartLogoLight.png"

function LoginPage() {
    const classes = useStyles()

    return (
        <>
            <Grid container justify="center" className={classes.LoginPage}>
                <Card className={classes.LoginCard} elevation={15}>
                    <CardContent align="center">
                        <img
                            alt="Precipart Logo"
                            src={logo}
                            className={classes.Logo}
                        />
                        <Login />
                    </CardContent>
                </Card>
            </Grid>
        </>
    )
}

export default LoginPage
