import { makeStyles } from "@material-ui/core/styles"
import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    DataGridWrapper: {
        width: "100%",
        height: 550
    },
    DataGrid: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["SlimIMS, Century !important"].join(",")
    },
    Dialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["Century !important"].join(",")
    },
    Button: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        textTransform: "none",
        fontFamily: ["Century !important"].join(",")
    },
    ContainedButton: {
        color: "black !important",
        textTransform: "none",
        fontFamily: ["Century !important"].join(",")
    },
    MaintenanceTitle: {
        paddingBottom: "15px",
        fontWeight: "bold",
        fontFamily: ["Century !important"].join(",")
    },
    ColumnBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    MainBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    ColumnBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    HeatMapBox: {
        justifyContent: "left",
        alignContent: "left"
    },
    AuditGridBox: {
        width: "90vw",
        height: "63vh",
        marginRight: "10px",
        marginLeft: "10px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    Label: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["Century !important"].join(","),
        fontWeight: "bold",
        textAlign: "left"
    }
}))

export default useStyles
