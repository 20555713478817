import {
    GET_CHARACTERISTICS,
    CREATE_CHARACTERISTIC,
    UPDATE_CHARACTERISTIC,
    DELETE_CHARACTERISTIC,
    SET_CHARACTERISTIC,
    SET_CHARACTERISTIC_DIALOG,
    SET_TO_DELETE,
    ARCHIVE_CHARACTERISTIC,
    CHARACTERISTIC_ROUTING,
    CLONE_CHARACTERISTIC,
    NEW_UPDATE_SEQUENCE
} from "./types"

import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getCharacteristics = (inspectionId) => async (dispatch) => {
    const res = await axios.get(
        `${backendURL}/characteristics/${inspectionId?.replace("/", "%2F")}`
    )
    dispatch({
        type: GET_CHARACTERISTICS,
        payload: res.data
    })
}

export const createCharacteristic = (characteristic) => async (dispatch) => {
    const res = await axios.post(
        `${backendURL}/characteristics`,
        characteristic
    )
    dispatch({
        type: CREATE_CHARACTERISTIC,
        payload: res.data
    })
}

//method to update characteristic
export const updateCharacteristic = (characteristic) => async (dispatch) => {
    const res = await axios.put(
        `${backendURL}/characteristics/`,
        characteristic
    )
    dispatch({
        type: UPDATE_CHARACTERISTIC,
        payload: res.data
    })
}

export const deleteCharacteristic = (toDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/characteristics/`, { data: toDelete })
    dispatch({
        type: DELETE_CHARACTERISTIC,
        payload: toDelete
    })
}

export const cloneCharacteristic = (to, from, audit) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/characteristics/clone`, {
        to,
        from,
        audit
    })
    dispatch({
        type: CLONE_CHARACTERISTIC,
        payload: res.data
    })
}

export const setCharacteristic = (characteristic) => async (dispatch) => {
    dispatch({
        type: SET_CHARACTERISTIC,
        payload: characteristic
    })
}

export const newUpdateSequence = (characteristics) => async (dispatch) => {
    await axios.post(
        `${backendURL}/characteristics/newUpdateSequence`,
        characteristics
    )
    dispatch({
        type: NEW_UPDATE_SEQUENCE,
        payload: characteristics
    })
}

export const archiveCharacteristic = (characteristics) => async (dispatch) => {
    await Promise.all(
        characteristics.characteristics.map(async (characteristic) => {
            await axios.post(
                `${backendURL}/characteristics/archive`,
                characteristic
            )
            dispatch({
                type: ARCHIVE_CHARACTERISTIC,
                payload: characteristics
            })
        })
    )
}

export const characteristicRouting = (id) => async (dispatch) => {
    axios.post(`${backendURL}/characteristics/routing`, id)
    dispatch({
        type: CHARACTERISTIC_ROUTING,
        payload: id
    })
}

export const setCharacteristicDialog = (bool) => async (dispatch) => {
    dispatch({
        type: SET_CHARACTERISTIC_DIALOG,
        payload: bool
    })
}

export const setToDelete = (toDelete) => async (dispatch) => {
    dispatch({
        type: SET_TO_DELETE,
        payload: toDelete
    })
}
