import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getActivity } from "../../actions/adminActions"
import { Box, Typography } from "@material-ui/core"
import useStyles from "./styles/AdminStyles"
import CalendarHeatmap from "react-calendar-heatmap"
import ReactTooltip from "react-tooltip"
import moment from "moment"
import "react-calendar-heatmap/dist/styles.css"
import "./styles/styles.css"

const today = moment.utc().startOf("day")

function getDatesInRange(startDate, endDate) {
    const dates = []
    let currentDate = moment.utc(startDate).startOf("day")

    while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.toDate())
        currentDate.add(1, "days")
    }

    return dates
}

export default function ActivityHeatMap() {
    const dispatch = useDispatch()
    const classes = useStyles()

    const admin = useSelector((state) => state.admin)
    const auth = useSelector((state) => state.auth)

    useEffect(() => {
        if (auth.authenticated) {
            dispatch(getActivity())
        }
    }, [dispatch, auth])

    const auditLogEntries = admin.activity
    const range = moment
        .utc(today)
        .subtract(4, "months")
        .startOf("day")
        .toDate()

    const data = getDatesInRange(range, today.toDate()).map((date) => {
        let tempCount = 0
        const formattedDate = moment.utc(date).format("YYYY-MM-DD")

        auditLogEntries.forEach((element) => {
            const elementDate = moment.utc(element.date).format("YYYY-MM-DD")

            if (elementDate === formattedDate) {
                tempCount = element.count
            }
        })

        return {
            date,
            count: tempCount
        }
    })

    return (
        <Box className={classes.ColumnBox}>
            <Typography variant="h4" className={classes.Label}>
                Activity Heat Map
            </Typography>
            <div style={{ position: "relative", color: "#888", width: "100%" }}>
                <CalendarHeatmap
                    startDate={moment.utc(range).format("YYYY-MM-DD")}
                    endDate={moment.utc(today).format("YYYY-MM-DD")}
                    values={data}
                    classForValue={(value) => {
                        if (!value) {
                            return "color-empty"
                        }
                        return `color-github-${
                            value.count > 600
                                ? 5
                                : value.count > 500
                                ? 4
                                : value.count > 400
                                ? 3
                                : value.count > 300
                                ? 2
                                : value.count > 100
                                ? 1
                                : "0-light"
                        }`
                    }}
                    tooltipDataAttrs={(value) => {
                        return {
                            "data-tip": `${
                                value.count ? value.count : 0
                            } actions preformed on ${moment(value.date).format(
                                "MM/DD/YYYY"
                            )} `
                        }
                    }}
                />
                <ReactTooltip />
            </div>
        </Box>
    )
}
