import React from "react"

export const useControlMethodMutation = () => {
    return React.useCallback(
        (controlMethod) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (controlMethod.ControlMethod?.trim() === "") {
                        reject()
                    } else {
                        resolve(controlMethod)
                    }
                }, 200)
            ),
        []
    )
}

export function computeMutation(newRow, oldRow) {
    if (
        newRow.ControlMethod !== oldRow.ControlMethod &&
        newRow.ControlMethod?.trim() !== ""
    ) {
        return `Control Method from '${oldRow.ControlMethod}' to '${newRow.ControlMethod}'`
    }
    return null
}

export const useInspectionMethodMutation = () => {
    return React.useCallback(
        (inspectionMethod) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (inspectionMethod.InspectionMethod?.trim() === "") {
                        reject()
                    } else {
                        resolve(inspectionMethod)
                    }
                }, 200)
            ),
        []
    )
}

export function computeInspectionMutation(newRow, oldRow) {
    if (oldRow && newRow) {
        if (
            newRow.InspectionMethod !== oldRow.InspectionMethod &&
            newRow.InspectionMethod?.trim() !== ""
        ) {
            return `Inspection Method from '${oldRow.InspectionMethod}' to '${newRow.InspectionMethod}'`
        }
    }
    return null
}

export const useFrequencyMutation = () => {
    return React.useCallback(
        (frequency) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (frequency.Frequency?.trim() === "") {
                        reject()
                    } else {
                        resolve(frequency)
                    }
                }, 200)
            ),
        []
    )
}

export function computeFrequencyMutation(newRow, oldRow) {
    if (oldRow && newRow) {
        if (
            newRow.Frequency !== oldRow.Frequency &&
            newRow.Frequency?.trim() !== ""
        ) {
            return `Frequency from '${oldRow.Frequency}' to '${newRow.Frequency}'`
        }
    }
    return null
}

export const useToolMutation = () => {
    return React.useCallback(
        (tool) =>
            new Promise((resolve, reject) =>
                setTimeout(() => {
                    if (tool.Tool?.trim() === "") {
                        reject()
                    } else {
                        resolve(tool)
                    }
                }, 200)
            ),
        []
    )
}

export function computeToolMutation(newRow, oldRow) {
    if (oldRow && newRow) {
        if (newRow.Tool !== oldRow.Tool && newRow.Tool?.trim() !== "") {
            return `Tool from '${oldRow.Tool}' to '${newRow.Tool}'`
        }
    }
    return null
}

export const openPopup = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=600,height=370,left=100,top=100`
    window.open("/#/gts", "GTS Keyboard", params)
}

//function to convert pascal case to title case
function addSpaces(str) {
    return str.replace(/([A-Z])/g, " $1").trim()
}

export const toColumns = (Array, permissions) => {
    return Array.map((element) => {
        if (element.includes("Id")) {
            return {
                field: element,
                headerName: addSpaces(element),
                editable: false,
                filterable: false,
                hideable: false
            }
        } else {
            return {
                field: element,
                headerName: addSpaces(element),
                editable: permissions.EditMaintenance ? true : false,
                flex: 1,
                color: "white",
                renderHeader: () => <strong>{addSpaces(element)}</strong>
            }
        }
    })
}
