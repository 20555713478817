import {
    GET_MOMAST,
    GET_MOROUT,
    GET_ITMSIT,
    GET_ITMRVA,
    GET_ITMPRC,
    SET_ITMRVA,
    GET_RTGOPR,
    GET_OPERATIONS,
    GET_MANUFACTURING_ORDERS,
    GET_MANUFACTURING_OPS,
    GET_FACMST,
    GET_CURRENT_REVISION
} from "../actions/types"

const initialState = {
    momast: [],
    morout: [],
    itmsit: [],
    itmrva: [],
    itmprc: [],
    rtgopr: [],
    facmst: [],
    rtgoprOps: [],
    manufacturingOrders: [],
    manufacturingOrderOps: [],
    currentRevision: "",
    items: {
        itm: "",
        itmName: "",
        itemID: "",
        rtid: "",
        rtvr: "",
        itemsList: []
    },
    revisions: {
        rev: "",
        revisionsList: []
    },
    operations: {
        op: "",
        operationsList: []
    },
    manufacturingOrder: "",
    moOps: {
        moOp: "",
        moOpsList: []
    },
    viewOps: false
}

const erpReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_MOMAST:
            return { ...state, momast: action.payload }
        case GET_MOROUT:
            return { ...state, morout: action.payload }
        case GET_ITMSIT:
            return { ...state, itmsit: action.payload }
        case GET_ITMRVA:
            return { ...state, itmrva: action.payload }
        case GET_ITMPRC:
            return { ...state, itmprc: action.payload }
        case SET_ITMRVA:
            return { ...state, itmrva: action.payload }
        case GET_RTGOPR:
            return { ...state, rtgopr: action.payload }
        case GET_OPERATIONS:
            return { ...state, rtgoprOps: action.payload }
        case GET_MANUFACTURING_ORDERS:
            return { ...state, manufacturingOrders: action.payload }
        case GET_MANUFACTURING_OPS:
            return { ...state, manufacturingOrderOps: action.payload }
        case GET_FACMST:
            return { ...state, facmst: action.payload }
        case GET_CURRENT_REVISION:
            return { ...state, currentRevision: action.payload }
        default:
            return state
    }
}

export default erpReducer
