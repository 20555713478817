import axios from "../auth/axiosAuth"

import { emailBackendURL } from "../config"
import { backendURL } from "../config"

import {
    GET_FIRST_VERIFICATION_EMAILS,
    GET_SECOND_VERIFICATION_EMAILS,
    GET_PPAP_EMAILS,
    GET_UPDATE_ROUTING_EMAILS,
    GET_ALL_EMAILS
} from "./types"

export const sendEmail = async (emailObject) => {
    await axios.post(`${emailBackendURL}/email`, emailObject)
}

export const getFirstVerificationEmails = (data) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/email/firstVerification`)
    dispatch({
        type: GET_FIRST_VERIFICATION_EMAILS,
        payload: res.data
    })
}

export const getSecondVerificationEmails = (data) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/email/secondVerification`)
    dispatch({
        type: GET_SECOND_VERIFICATION_EMAILS,
        payload: res.data
    })
}

export const getPPAPEmails = (data) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/email/ppap`)
    dispatch({
        type: GET_PPAP_EMAILS,
        payload: res.data
    })
}

export const getUpdateRoutingEmails = (data) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/email/updateRouting`)
    dispatch({
        type: GET_UPDATE_ROUTING_EMAILS,
        payload: res.data
    })
}

export const getAllEmails = (data) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/email/${data.DeptCode}`)
    dispatch({
        type: GET_ALL_EMAILS,
        payload: res.data
    })
}
