import {
    GET_CONTROL_METHODS,
    CREATE_CONTROL_METHOD,
    UPDATE_CONTROL_METHOD,
    DELETE_CONTROL_METHOD,
    GET_INSPECTION_METHODS,
    CREATE_INSPECTION_METHOD,
    UPDATE_INSPECTION_METHOD,
    DELETE_INSPECTION_METHOD,
    GET_FREQUENCIES,
    CREATE_FREQUENCY,
    UPDATE_FREQUENCY,
    DELETE_FREQUENCY,
    GET_TOLERANCES,
    CREATE_TOLERANCE,
    UPDATE_TOLERANCE,
    DELETE_TOLERANCE,
    GET_FAVORITES,
    CREATE_FAVORITE,
    DELETE_FAVORITE,
    GET_TOOLS,
    CREATE_TOOL,
    UPDATE_TOOL,
    DELETE_TOOL,
    UPDATE_SELECTED,
    UPDATE_IS_BEING_EDITED,
    UPDATE_MAINT_ALERT_DIALOG,
    UPDATE_MAINT_ALERT_TEXT,
    CHECK_CONTROL_METHOD
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

//Control Methods
export const getControlMethods = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/controlmethods`)
    dispatch({
        type: GET_CONTROL_METHODS,
        payload: res.data
    })
}

export const createControlMethod = (controlMethod) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/controlmethods`, controlMethod)
    dispatch({
        type: CREATE_CONTROL_METHOD,
        payload: res.data
    })
}

//method to update control method
export const updateControlMethod = (controlMethod) => async (dispatch) => {
    const res = await axios.put(`${backendURL}/controlmethods/`, controlMethod)
    dispatch({
        type: UPDATE_CONTROL_METHOD,
        payload: res.data
    })
}

export const deleteControlMethod = (toDelete) => async (dispatch) => {
    const res = await axios.delete(`${backendURL}/controlmethods/`, {
        data: toDelete
    })
    dispatch({
        type: DELETE_CONTROL_METHOD,
        payload: toDelete,
        response: res.data
    })
}

export const checkControlMethod = (toCheck) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/controlmethods/`, {
        data: toCheck
    })
    dispatch({
        type: CHECK_CONTROL_METHOD,
        payload: toCheck,
        response: res.data
    })
}

//Inspection Methods
export const getInspectionMethods = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/inspectionmethods`)
    dispatch({
        type: GET_INSPECTION_METHODS,
        payload: res.data
    })
}

export const createInspectionMethod =
    (inspectionMethod) => async (dispatch) => {
        const res = await axios.post(
            `${backendURL}/inspectionmethods`,
            inspectionMethod
        )
        dispatch({
            type: CREATE_INSPECTION_METHOD,
            payload: res.data
        })
    }

//method to update inspection method
export const updateInspectionMethod =
    (inspectionMethod) => async (dispatch) => {
        const res = await axios.put(
            `${backendURL}/inspectionmethods/`,
            inspectionMethod
        )
        dispatch({
            type: UPDATE_INSPECTION_METHOD,
            payload: res.data
        })
    }

export const deleteInspectionMethod = (toDelete) => async (dispatch) => {
    const res = await axios.delete(`${backendURL}/inspectionmethods/`, {
        data: toDelete
    })
    dispatch({
        type: DELETE_INSPECTION_METHOD,
        payload: toDelete,
        response: res.data
    })
}

//Frequency
export const getFrequencies = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/frequencies`)
    dispatch({
        type: GET_FREQUENCIES,
        payload: res.data
    })
}

export const createFrequency = (frequency) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/frequencies`, frequency)
    dispatch({
        type: CREATE_FREQUENCY,
        payload: res.data
    })
}

//method to update frequency
export const updateFrequency = (frequency) => async (dispatch) => {
    const res = await axios.put(`${backendURL}/frequencies/`, frequency)
    dispatch({
        type: UPDATE_FREQUENCY,
        payload: res.data
    })
}

export const deleteFrequency = (toDelete) => async (dispatch) => {
    const res = await axios.delete(`${backendURL}/frequencies/`, {
        data: toDelete
    })
    dispatch({
        type: DELETE_FREQUENCY,
        payload: toDelete,
        response: res.data
    })
}

//Tools
export const getTools = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/tools`)
    dispatch({
        type: GET_TOOLS,
        payload: res.data
    })
}

export const createTool = (tool) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/tools`, tool)
    dispatch({
        type: CREATE_TOOL,
        payload: res.data
    })
}

//method to update tool
export const updateTool = (tool) => async (dispatch) => {
    const res = await axios.put(`${backendURL}/tools/`, tool)
    dispatch({
        type: UPDATE_TOOL,
        payload: res.data
    })
}

export const deleteTool = (toDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/tools/`, { data: toDelete })
    dispatch({
        type: DELETE_TOOL,
        payload: toDelete
    })
}

//Tolerance
export const getTolerances = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/tolerances`)
    dispatch({
        type: GET_TOLERANCES,
        payload: res.data
    })
}

//method to create tolerance
export const createTolerance = (tolerance) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/tolerances`, tolerance)
    dispatch({
        type: CREATE_TOLERANCE,
        payload: res.data
    })
}

//method to update tolerance
export const updateTolerance = (tolerance) => async (dispatch) => {
    const res = await axios.put(`${backendURL}/tolerances/`, tolerance)
    dispatch({
        type: UPDATE_TOLERANCE,
        payload: res.data
    })
}

export const deleteTolerance = (toDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/tolerances/`, { data: toDelete })
    dispatch({
        type: DELETE_TOLERANCE,
        payload: toDelete
    })
}

export const getFavorites = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/favorites`)
    dispatch({
        type: GET_FAVORITES,
        payload: res.data
    })
}

export const createFavorite = (favorite) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/favorites`, favorite)
    dispatch({
        type: CREATE_FAVORITE,
        payload: res.data
    })
}

export const deleteFavorite = (toDelete) => async (dispatch) => {
    await axios.delete(`${backendURL}/favorites/`, { data: toDelete })
    dispatch({
        type: DELETE_FAVORITE,
        payload: toDelete
    })
}

export const updateselected = (controlMethod) => (dispatch) => {
    const res = controlMethod
    dispatch({
        type: UPDATE_SELECTED,
        payload: res
    })
}

export const updateIsBeingEdited = (isBeingEdited) => (dispatch) => {
    dispatch({
        type: UPDATE_IS_BEING_EDITED,
        payload: isBeingEdited
    })
}

export const setAlertDialog = (maintAlertDialog) => (dispatch) => {
    dispatch({
        type: UPDATE_MAINT_ALERT_DIALOG,
        payload: maintAlertDialog
    })
}

export const setAlertText = (maintAlertDialogText) => (dispatch) => {
    dispatch({
        type: UPDATE_MAINT_ALERT_TEXT,
        payload: maintAlertDialogText
    })
}
