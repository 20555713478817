import {
    GET_CHARACTERISTICS,
    CREATE_CHARACTERISTIC,
    UPDATE_CHARACTERISTIC,
    DELETE_CHARACTERISTIC,
    SET_CHARACTERISTIC,
    UPDATE_SEQUENCE,
    SET_CHARACTERISTIC_DIALOG,
    SET_TO_DELETE,
    ARCHIVE_CHARACTERISTIC,
    CHARACTERISTIC_ROUTING,
    CLONE_CHARACTERISTIC,
    NEW_UPDATE_SEQUENCE
} from "../actions/types"

const initialState = {
    characteristics: [],
    inspection: "",
    characteristicDialog: false,
    zoomDialog: false,
    toDelete: {},
    zoom: {}
}

const characteristicReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHARACTERISTICS:
            return { ...state, characteristics: action.payload }
        case CREATE_CHARACTERISTIC:
            return {
                ...state,
                characteristics: [...state.characteristics, action.payload]
            }
        case UPDATE_CHARACTERISTIC:
            return {
                ...state
            }
        case DELETE_CHARACTERISTIC:
            return {
                ...state,
                characteristics: state.characteristics.filter(
                    (characteristic) => {
                        return (
                            characteristic.CharacteristicId !==
                            action.payload.CharacteristicId
                        )
                    }
                )
            }
        case CLONE_CHARACTERISTIC:
            return {
                ...state,
                characteristics: [...state.characteristics, action.payload]
            }
        case SET_CHARACTERISTIC:
            return {
                ...state,
                characteristics: action.payload
            }
        case UPDATE_SEQUENCE:
            return {
                ...state
            }
        case SET_CHARACTERISTIC_DIALOG:
            return {
                ...state,
                characteristicDialog: action.payload
            }
        case SET_TO_DELETE:
            return {
                ...state,
                toDelete: action.payload
            }
        case ARCHIVE_CHARACTERISTIC:
            return {
                ...state
            }
        case CHARACTERISTIC_ROUTING:
            return {
                ...state
            }
        case NEW_UPDATE_SEQUENCE:
            return {
                ...state
            }
        default:
            return state
    }
}

export default characteristicReducer
