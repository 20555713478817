import React from "react"
import { Grid, Typography, Box, Paper } from "@material-ui/core"
import useStyles from "./styles/LoadingScreenStyles"
import SpinningGears from "../../resources/spinningGears"

function LoadingScreen() {
    // Store Variables
    const classes = useStyles()

    return (
        <Grid container justify="center" className={classes.Page}>
            <Paper className={classes.CenterCard} elevation={10}>
                <Paper elevation={0} className={classes.SpinningGears}>
                    <SpinningGears />
                </Paper>
                <Box className={classes.CenterText}>
                    <Typography variant="h3">Loading</Typography>
                </Box>
            </Paper>
        </Grid>
    )
}

export default LoadingScreen
