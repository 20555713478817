import { Box } from "@material-ui/core"

import InProcessContent from "./InProcessContent"

import useStyles from "./styles/OutputStyles"

function InProcess() {
    const classes = useStyles()

    return (
        <>
            <Box className={classes.OutputContainer}>
                <InProcessContent />
            </Box>
        </>
    )
}

export default InProcess
