import {
    GET_MOMAST,
    GET_MOROUT,
    GET_ITMSIT,
    GET_ITMRVA,
    GET_ITMPRC,
    SET_ITMRVA,
    GET_RTGOPR,
    GET_FACMST,
    GET_OPERATIONS,
    GET_MANUFACTURING_ORDERS,
    GET_MANUFACTURING_OPS,
    GET_CURRENT_REVISION
} from "./types"
import axios from "../auth/axiosAuth"
import { backendURL } from "../config"

export const getMomast = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/momast`)
    dispatch({
        type: GET_MOMAST,
        payload: res.data
    })
}

export const getMorout = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/morout`)
    dispatch({
        type: GET_MOROUT,
        payload: res.data
    })
}

export const getItmsit = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/itmsit`)
    dispatch({
        type: GET_ITMSIT,
        payload: res.data
    })
}

export const getItmrva = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/itmrva`)
    dispatch({
        type: GET_ITMRVA,
        payload: res.data
    })
}

export const getCurrentRevision = (item) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/itmrva/${item}`)
    dispatch({
        type: GET_CURRENT_REVISION,
        payload: res.data
    })
}

export const getItmprc = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/itmprc`)
    dispatch({
        type: GET_ITMPRC,
        payload: res.data
    })
}

export const setItmrva = (data) => async (dispatch) => {
    dispatch({
        type: SET_ITMRVA,
        payload: data
    })
}

export const getRtgopr = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/rtgopr`)
    dispatch({
        type: GET_RTGOPR,
        payload: res.data
    })
}

export const getOperations = (id) => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/rtgopr/${id}`)
    dispatch({
        type: GET_OPERATIONS,
        payload: res.data
    })
}

export const getManufacturingOrders = (data) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/erp/mo`, data)
    dispatch({
        type: GET_MANUFACTURING_ORDERS,
        payload: res.data
    })
}

export const getManufacturingOps = (data) => async (dispatch) => {
    const res = await axios.post(`${backendURL}/erp/mo/${data}`)
    dispatch({
        type: GET_MANUFACTURING_OPS,
        payload: res.data
    })
}

export const getFacmst = () => async (dispatch) => {
    const res = await axios.get(`${backendURL}/erp/facmst`)
    dispatch({
        type: GET_FACMST,
        payload: res.data
    })
}
