import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Container, Typography, Grid } from "@material-ui/core"
import useStyles from "./styles/FooterStyles"
import { APPLICATION_ID, appAdminBackendURL } from "../../config"
import axios from "../../auth/axiosAuth"

function Footer() {
    const classes = useStyles()

    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    const [version, setVersion] = useState(null)

    useEffect(() => {
        const initialVersion = async (applicationID) => {
            if (loggedIn && !version) {
                try {
                    const fetchedVersion = await getVersion(APPLICATION_ID)
                    setVersion(fetchedVersion)
                } catch (error) {
                    console.error(error)
                }
            }
            return () => {}
        }
        initialVersion()
    }, [loggedIn, version])

    const getVersion = async (applicationID) => {
        const res = await axios.get(
            `${appAdminBackendURL}/application/${applicationID}`
        )
        let fetchedVersion = null
        if (res.data[0]) {
            fetchedVersion = `v${res.data[0].MajorVersion}.${res.data[0].MinorVersion}.${res.data[0].FixVersion}`
        }

        return fetchedVersion
    }

    return (
        <>
            <div className={classes.Footer}>
                <Container>
                    <Grid container className={classes.FooterItems}>
                        <Grid item>
                            <Typography variant="h6">
                                Precipart Global Digital Solutions
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {version || "Loading version..."}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Footer
