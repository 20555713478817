import {
    GET_AUDIT_LOG,
    GET_ACTIVITY,
    GET_ACTIVITY_BY_USER,
    GET_FIRST_VERIFICATION_EMAILS,
    GET_SECOND_VERIFICATION_EMAILS,
    GET_PPAP_EMAILS,
    GET_UPDATE_ROUTING_EMAILS,
    GET_ALL_EMAILS
} from "../actions/types"

const initialState = {
    userActivity: [],
    auditLog: [],
    activity: [],
    firstVerificationEmails: [],
    secondVerificationEmails: [],
    PPAPEmails: [],
    updateRoutingEmails: []
}

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_AUDIT_LOG:
            return { ...state, auditLog: action.payload }
        case GET_ACTIVITY:
            return { ...state, activity: action.payload }
        case GET_ACTIVITY_BY_USER:
            return { ...state, userActivity: action.payload }
        case GET_FIRST_VERIFICATION_EMAILS:
            return { ...state, firstVerificationEmails: action.payload }
        case GET_SECOND_VERIFICATION_EMAILS:
            return { ...state, secondVerificationEmails: action.payload }
        case GET_PPAP_EMAILS:
            return { ...state, PPAPEmails: action.payload }
        case GET_UPDATE_ROUTING_EMAILS:
            return { ...state, updateRoutingEmails: action.payload }
        case GET_ALL_EMAILS:
            return {
                ...state,
                firstVerificationEmails: action.payload.firstVerifiers,
                secondVerificationEmails: action.payload.secondVerifiers,
                PPAPEmails: action.payload.ppap,
                updateRoutingEmails: action.payload.updateRouting
            }
        default:
            return state
    }
}

export default adminReducer
