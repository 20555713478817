import { makeStyles } from "@material-ui/core/styles"
import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    TabPanel: {
        marginLeft: "20px",
        marginRight: "20px",
        marginBottom: "20px"
    },
    DataGridWrapper: {
        width: "100%",
        height: 600
    },
    DataGrid: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["Century !important"].join(",")
    },
    Dialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["Century !important"].join(",")
    },
    Button: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        textTransform: "none",
        fontFamily: ["Century !important"].join(",")
    },
    ContainedButton: {
        color: "black !important",
        textTransform: "none",
        fontFamily: ["Century !important"].join(",")
    },
    MaintenanceTitle: {
        paddingBottom: "10px",
        fontWeight: "bold",
        fontFamily: ["Century !important"].join(",")
    },
    ColumnBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    ColumnBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    }
}))

export default useStyles
