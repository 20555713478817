import { SET_OUTPUT } from "../actions/types"

const initialState = {
    partNumber: "",
    partName: "",
    partRevision: "",
    manufacturingOrder: "",
    operation: "",
    customer: "",
    department: "",
    machine: "",
    secondVerified: "",
    secondVerifiedBy: "",
    ppap: "",
    material: "",
    certification: "",
    numCells: 8,
    characteristics: []
}

const outputReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OUTPUT:
            return {
                ...state,
                partNumber: action.payload.partNumber,
                partName: action.payload.partName,
                partRevision: action.payload.partRevision,
                manufacturingOrder: action.payload.manufacturingOrder,
                operation: action.payload.operation,
                customer: action.payload.customer,
                department: action.payload.department,
                machine: action.payload.machine,
                secondVerified: action.payload.secondVerified,
                secondVerifiedBy: action.payload.secondVerifiedBy,
                ppap: action.payload.ppap,
                material: action.payload.material,
                certification: action.payload.certification,
                numCells: action.payload.numCells,
                characteristics: action.payload.characteristics
            }

        default:
            return state
    }
}

export default outputReducer
