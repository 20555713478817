import { makeStyles } from "@material-ui/core/styles"
import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    Selector: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["preciIMS !important"].join(", "),
        marginLeft: "10px",
        marginBottom: "10px"
    },
    Title: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["Century !important"].join(", "),
        alignContent: "center"
    },
    General: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["preciIMS !important"].join(", ")
    },
    Button: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["preciIMS !important"].join(", "),
        textTransform: "none",
        marginLeft: "1px",
        marginRight: "1px",
        marginBottom: "1px"
    },
    MainContainerBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "755px"
    },
    ContainerBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    ContainerBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBoxBorder: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid black"
    },
    RowBoxTolerance: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderTop: "1px solid black",
        borderBottom: "1px solid black"
    },
    RowBox: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    RowBoxBaseLine: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "center"
    },
    Result: {
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["PreciIms !important"]
    },
    PaperContainer: {
        width: "860px",
        height: "350px",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px"
    },
    DataGridWrapper: {
        width: "50%",
        height: 250
    },
    DataGrid: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["PreciIms !important"].join(","),
        fontSize: "20px !important",
        marginLeft: "10px"
    },
    TransparentPaper: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "10px",
        maxHeight: "263px",
        overflow: "auto",
        backgroundColor: "transparent"
    },
    FavoritesPaper: {
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "5px",
        marginTop: "5px",
        marginLeft: "5px",
        maxHeight: "296px",
        minHeight: "296px",
        overflow: "auto",
        backgroundColor: "transparent",
        "&::-webkit-scrollbar": {
            width: "10px"
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 10px rgba(0,0,0,0.00)"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "grey",
            width: "10px"
        }
    },
    Dialog: {
        //set color to white if dark mode is on or else set to black
        color:
            theme.palette.type === "dark"
                ? "white !important"
                : "black !important",
        fontFamily: ["preciIMS !important"].join(",")
    }
}))

export default useStyles
