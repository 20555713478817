import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    Footer: {
        marginTop: "auto",
        backgroundColor: "#d1d4d3",
        color: "black"
    },
    FooterItems: {
        display: "flex",
        justifyContent: "space-between"
    },
    Alert: {
        borderRadius: "40px",
        backgroundColor: "#f44336",
        color: "#fff",
        boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)"
    },
    AlertText: {
        fontSize: "16px",
        color: "#131215",
        margin: theme.spacing(0.5, 3)
    },
    AlertTitle: {
        fontSize: "16px",
        color: "#131215",
        fontWeight: "bold",
        margin: theme.spacing(0.5, 3)
    },
    LowerCaseButtonRight: {
        textTransform: "none",
        backgroundColor: "#fff",
        color: "#131215",
        borderRadius: "5px",
        marginTop: theme.spacing(1.5),
        marginLeft: theme.spacing(1)
    },
    LowerCaseButtonLeft: {
        textTransform: "none",
        backgroundColor: "#fff",
        color: "#131215",
        borderRadius: "5px",
        marginTop: theme.spacing(1.5)
    }
}))

export default useStyles
