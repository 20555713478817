import React from "react"
import moment from "moment"

function addSpaces(str) {
    return str.replace(/([A-Z])/g, " $1").trim()
}

export const toColumns = (Array) => {
    return Array.map((element) => {
        if (element.includes("Date")) {
            return {
                field: element,
                headerName: addSpaces(element),
                editable: false,
                flex: 1,
                color: "white",
                valueFormatter: (params) =>
                    moment(params?.value).format("MM/DD/YYYY hh:mm A"),
                renderHeader: () => <strong>{addSpaces(element)}</strong>
            }
        } else {
            return {
                field: element,
                headerName: addSpaces(element),
                editable: false,
                flex: 1,
                color: "white",
                renderHeader: () => <strong>{addSpaces(element)}</strong>
            }
        }
    })
}
