import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import LoginPrompt from "../login/LoginPrompt"
import { Box, Typography } from "@material-ui/core"
import AuditGrid from "./audit/AuditGrid"
import ActivityHeatMap from "./ActivityHeatMap"
import WorkcenterUpdate from "./WorkcenterUpdate"
import useStyles from "./styles/AdminStyles"
import resetHeaders from "../../Functions/ResetHeaders"

function AdminPage() {
    // Store Variables
    const auth = useSelector((state) => state.auth)
    const permissions = useSelector((state) => state.auth.permissions)
    const classes = useStyles()

    useEffect(() => {
        if (auth.authenticated) {
            resetHeaders()
        }
    }, [auth])

    return (
        <>
            {auth.authenticated && permissions.ViewAdminPage ? (
                <Box className={classes.MainBox}>
                    {permissions.BulkAdmin && (
                        <Box className={classes.RowBox}>
                            <Box className={classes.WorkcenterUpdateBox}>
                                <WorkcenterUpdate />
                            </Box>
                        </Box>
                    )}
                    <Box className={classes.RowBox}>
                        <Box className={classes.HeatMapBox}>
                            <ActivityHeatMap />
                        </Box>
                    </Box>
                    <Box className={classes.AuditGridBox}>
                        <Typography variant="h4" className={classes.Label}>
                            Audit Log
                        </Typography>
                        <AuditGrid />
                    </Box>
                </Box>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default AdminPage
