import React, { useState } from "react"
import clsx from "clsx"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    IconButton,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Tooltip,
    Chip
} from "@material-ui/core"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import { environment } from "../../config"
import MenuIcon from "@mui/icons-material/Menu"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService"
import HandymanIcon from "@mui/icons-material/Handyman"
import Brightness3Icon from "@mui/icons-material/Brightness3"
import WbSunnyIcon from "@mui/icons-material/WbSunny"
import HelpRoundedIcon from "@mui/icons-material/HelpRounded"
import HomeIcon from "@mui/icons-material/Home"
import LogoutIcon from "@mui/icons-material/Logout"
import logo from "../../resources/images/precipartLogoSquare.png"
import useStyles from "./styles/NavigationStyles"
import { homeURL } from "../../config"
import {
    getUserData,
    getUserName,
    toggleDarkMode,
    logoutUser
} from "../../actions/authActions"
import firebaseAuth from "../../auth/firebaseAuth"

function Navigation() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector((state) => state.auth)
    const permissions = useSelector((state) => state.auth.permissions)
    const userData = useSelector((state) => state.auth.userData)

    const [open, setOpen] = useState(false)

    const navigationItems = [
        {
            text: "Admin Panel",
            path: "/admin",
            visible: permissions.ViewAdminPage,
            icon: <AdminPanelSettingsIcon />
        },
        {
            text: "Inspections",
            path: "/inspection",
            visible: permissions.ViewInspection,
            icon: <HandymanIcon />
        },
        {
            text: "Maintenance",
            path: "/maintenance",
            visible: permissions.EditMaintenance,
            icon: <HomeRepairServiceIcon />
        }
    ]

    const handleDarkModeToggle = async () => {
        await dispatch(toggleDarkMode(auth.userData))
        await dispatch(getUserData(auth.userData.GoogleID))
        await dispatch(getUserName(auth.userData.AssociateOID))
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleGoHome = async () => {
        setOpen(false)
        window.open(homeURL, "_self")
    }

    const handleGoToHelp = async () => {
        setOpen(false)
        window.open(
            "https://precipart0.sharepoint.com/sites/PrecipartWebApplications/SitePages/InspectionReport.aspx",
            "_blank"
        )
    }

    const openPopup = () => {
        let params = `personalbar=no,scrollbars=no,resizable=no,statusbar=no,locationbar=no,toolbar=no,menubar=no,
        width=900,height=485,left=100,top=100`
        window.open("/#/gts", "GTS Keyboard", params)
    }

    const handleLogout = async () => {
        handleDrawerClose()
        dispatch(logoutUser())
        await firebaseAuth.signOut()
        navigate("/")
    }

    const handleKeyDown = async (e) => {
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    return (
        <div className={classes.Navigation}>
            <AppBar
                position="fixed"
                className={clsx(classes.AppBar, {
                    [classes.AppBarShift]: open
                })}
                PaperProps={{ elevation: 24 }}
                onKeyDown={handleKeyDown}
            >
                <Toolbar>
                    <img
                        alt="Precipart Logo"
                        src={logo}
                        className={classes.Logo}
                    />
                    <Typography
                        variant="h6"
                        className={classes.ApplicationName}
                        noWrap
                    >
                        Inspection Report{" "}
                        {environment && environment !== "production" && (
                            <Chip
                                icon={<NewReleasesIcon />}
                                label={environment.toUpperCase()}
                                style={{ marginLeft: "5px" }}
                            />
                        )}
                    </Typography>
                    {userData.AssociateOID && (
                        <Tooltip title={open ? "Close Menu" : "Open Menu"}>
                            <IconButton
                                color="inherit"
                                aria-label={
                                    open ? "close drawer" : "open drawer"
                                }
                                edge="end"
                                onClick={
                                    open ? handleDrawerClose : handleDrawerOpen
                                }
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
            </AppBar>

            <div
                className={clsx(classes.Content, {
                    [classes.contentShift]: open
                })}
            >
                <div className={classes.DrawerHeader} />
            </div>
            <Drawer
                className={classes.Drawer}
                variant="persistent"
                anchor="right"
                open={open}
                PaperProps={{ elevation: 15 }}
            >
                <div className={classes.DrawerHeader}></div>
                <div className={classes.DrawerHeader}>
                    <Tooltip title="Return Home">
                        <IconButton>
                            <HomeIcon onClick={handleGoHome} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Logout">
                        <IconButton>
                            <LogoutIcon onClick={handleLogout} />
                        </IconButton>
                    </Tooltip>
                    {auth.userData && (
                        <Tooltip title="Toggle Darkmode">
                            <IconButton onClick={handleDarkModeToggle}>
                                {auth.userData.DarkMode === "dark" ? (
                                    <WbSunnyIcon />
                                ) : (
                                    <Brightness3Icon />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
                <Divider />
                <List>
                    {navigationItems
                        .filter((item) => item.visible)
                        .map((item) => (
                            <ListItem
                                button
                                key={item.text}
                                component={Link}
                                to={item.path}
                                onClick={handleDrawerClose}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}
                </List>
                <Divider />
                <ListItem button key="Keyboard" onClick={openPopup}>
                    <ListItemIcon>
                        <KeyboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="GTS Keyboard" />
                </ListItem>
                <ListItem button key="Help" onClick={handleGoToHelp}>
                    <ListItemIcon>
                        <HelpRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                </ListItem>
            </Drawer>
        </div>
    )
}

export default Navigation
