import React, { useState } from "react"
import { useSelector } from "react-redux"

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import Alert from "../main/Alert"
import useStyles from "./styles/TicketDialogStyles"
import { sendEmail } from "../../actions/emailActions"

export default function TicketDialog({
    open,
    handleClose,
    itmNumber,
    itmRevision,
    op,
    primary,
    setMainAlertDialog,
    setMainAlertSeverity,
    setMainAlertText
}) {
    const auth = useSelector((state) => state.auth)
    const classes = useStyles()

    const [itemNumber, setItemNumber] = useState({
        value: itmNumber ? itmNumber : "",
        error: false
    })

    const [itemRevision, setItemRevision] = useState({
        value: itmRevision ? itmRevision : "",
        error: false
    })

    const [operation, setOperation] = useState({
        value: op ? op : "",
        error: false
    })

    const [description, setDescription] = useState({
        value: "",
        error: false
    })

    const [alertDialog, setAlertDialog] = useState(false)
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [alertText, setAlertText] = useState("")

    const [sendingEmail, setSendingEmail] = useState(false)

    const onSubmit = async () => {
        if (!description.value) {
            setDescription({
                value: description.value,
                error: true
            })
            setAlertText("Please enter a description")
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        } else {
            let email = `<!DOCTYPE html>
            <html>
            
            <head>
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Century+Gothic:wght@400;700&display=swap');
            
                    body {
                        font-family: 'Century Gothic', sans-serif;
                        color: #333333;
                    }
            
                    /* Add a class to style the description section */
                    .description {
                        margin-left: 20px; /* Adjust the desired indentation value */
                    }
                </style>
            </head>
            
            <body>
                <div class="email-content">
                    <p>Hello Team,</p>
                    <p>We need your assistance with the Inspection Report for Item ${itmNumber} Revision ${itmRevision} Operation ${op} - ${
                primary ? "Primary" : "Alternate"
            }.</p>
            
                    
                    <p><b>Issue Description:</b></p>
                    <code class="description">${description.value}</code>
            
                    <p>Best,</p>
                    <p>${auth.user.displayName} (${auth.user.email})</p>
                </div>
            </body>
            
            </html>`
            const emails = "servicedesk@precipart.com"
            const emailInfoObject = {
                emailList: emails,
                emailSubject: `Support Request: Inspection Report for Item ${itmNumber} Rev. ${itmRevision} Operation ${op} ${
                    primary ? "Primary" : "Alternate"
                }`,
                emailContent: email
            }
            try {
                setSendingEmail(true)
                await sendEmail(emailInfoObject)
                setMainAlertSeverity("success")
                setMainAlertDialog(true)
                setMainAlertText("Ticket sent successfully")
            } catch (error) {
                setAlertSeverity("error")
                setAlertDialog(true)
                setAlertText("Error sending ticket")
                console.log(error)
            }
            clearStates()
            setSendingEmail(false)
            handleClose()
        }
    }

    const handleDescriptionChange = (e) => {
        setDescription({
            value: e.target.value,
            error: false
        })
    }

    const closeDialog = () => {
        clearStates()
        handleClose()
    }

    //close alerts
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
        setAlertText("")
    }

    //clear all states
    const clearStates = () => {
        setItemNumber({
            value: "",
            error: false
        })
        setItemRevision({
            value: "",
            error: false
        })
        setOperation({
            value: "",
            error: false
        })
        setDescription({
            value: "",
            error: false
        })
        setSendingEmail(false)
    }

    return (
        <Dialog open={open} onClose={handleClose} className={classes.Dialog}>
            <DialogTitle>
                <Typography variant="h5">Ticket Details</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <TextField
                            label="Item Number"
                            value={itmNumber}
                            className={classes.itemNumberText}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            label="Item Revision"
                            value={itmRevision}
                            className={classes.revisionText}
                        />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                        <TextField
                            label="Operation"
                            value={op}
                            className={classes.operationText}
                        />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                        <TextField
                            label="Description"
                            multiline
                            required
                            variant="outlined"
                            onChange={handleDescriptionChange}
                            error={description.error}
                            value={description.value}
                            rows={4}
                            className={classes.descriptionText}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="center"
            >
                {description.error && (
                    <Alert
                        onClose={handleAlertDialogClose}
                        severity={alertSeverity}
                        className={classes.Alert}
                        open={alertDialog}
                    >
                        {alertText}
                    </Alert>
                )}
            </Grid>
            <DialogActions>
                <Button
                    onClick={onSubmit}
                    className={classes.Dialog}
                    color="primary"
                    disabled={sendingEmail}
                >
                    Submit
                </Button>
                <Button
                    onClick={closeDialog}
                    className={classes.Dialog}
                    color="primary"
                    disabled={sendingEmail}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}
