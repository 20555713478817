export const GET_USER_BY_SESSION = "GET_USER_BY_SESSION"
export const GET_USER_DATA = "GET_USER_DATA"
export const TOGGLE_DARKMODE = "TOGGLE_DARKMODE"
export const LOGOUT_USER = "LOGOUT_USER"
export const UPDATE_USER_SESSION = "UPDATE_USER_SESSION"
export const GET_AUTHORIZATION_FOR_APPLICATION =
    "GET_AUTHORIZATION_FOR_APPLICATION"
export const GET_USER_NAME = "GET_USER_NAME"
export const CREATE_PENDING_USER = "CREATE_PENDING_USER"
export const CREATE_AUDIT_TRAIL = "CREATE_AUDIT_TRAIL"
export const LOGIN_USER = "LOGIN_USER"

export const GET_AUDIT_LOG = "GET_AUDIT_LOG"
export const GET_ACTIVITY = "GET_ACTIVITY"
export const GET_ACTIVITY_BY_USER = "GET_ACTIVITY_BY_USER"

export const GET_CONTROL_METHODS = "GET_CONTROL_METHODS"
export const CREATE_CONTROL_METHOD = "CREATE_CONTROL_METHOD"
export const UPDATE_CONTROL_METHOD = "UPDATE_CONTROL_METHOD"
export const DELETE_CONTROL_METHOD = "DELETE_CONTROL_METHOD"

export const GET_INSPECTION_METHODS = "GET_INSPECTION_METHODS"
export const CREATE_INSPECTION_METHOD = "CREATE_INSPECTION_METHOD"
export const UPDATE_INSPECTION_METHOD = "UPDATE_INSPECTION_METHOD"
export const DELETE_INSPECTION_METHOD = "DELETE_INSPECTION_METHOD"

export const GET_FREQUENCIES = "GET_FREQUENCIES"
export const CREATE_FREQUENCY = "CREATE_FREQUENCY"
export const UPDATE_FREQUENCY = "UPDATE_FREQUENCY"
export const DELETE_FREQUENCY = "DELETE_FREQUENCY"

export const GET_TOLERANCES = "GET_TOLERANCES"
export const CREATE_TOLERANCE = "CREATE_TOLERANCE"
export const UPDATE_TOLERANCE = "UPDATE_TOLERANCE"
export const DELETE_TOLERANCE = "DELETE_TOLERANCE"

export const GET_FAVORITES = "GET_FAVORITES"
export const CREATE_FAVORITE = "CREATE_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"

export const GET_TOOLS = "GET_TOOLS"
export const CREATE_TOOL = "CREATE_TOOL"
export const UPDATE_TOOL = "UPDATE_TOOL"
export const DELETE_TOOL = "DELETE_TOOL"

export const UPDATE_SELECTED = "UPDATE_SELECTED"
export const UPDATE_IS_BEING_EDITED = "UPDATE_IS_BEING_EDITED"
export const UPDATE_MAINT_ALERT_DIALOG = "MAINT_ALERT_DIALOG"
export const UPDATE_MAINT_ALERT_TEXT = "MAINT_ALERT_TEXT"
export const CHECK_CONTROL_METHOD = "CHECK_CONTROL_METHOD"

export const GET_MOMAST = "GET_MOMAST"
export const GET_MOROUT = "GET_MOROUT"
export const GET_ITMSIT = "GET_ITMSIT"
export const GET_ITMRVA = "GET_ITMRVA"
export const SET_ITMRVA = "SET_ITMRVA"
export const GET_ITMPRC = "GET_ITMPRC"
export const GET_RTGOPR = "GET_RTGOPR"
export const GET_FACMST = "GET_FACMST"
export const GET_OPERATIONS = "GET_OPERATIONS"
export const GET_MANUFACTURING_ORDERS = "GET_MANUFACTURING_ORDERS"
export const GET_MANUFACTURING_OPS = "GET_MANUFACTURING_OPS"
export const GET_CURRENT_REVISION = "GET_CURRENT_REVISION"

export const GET_INSPECTIONS = "GET_INSPECTIONS"
export const GET_INSPECTION = "GET_INSPECTION"
export const SET_INSPECTION = "SET_INSPECTION"
export const CREATE_INSPECTION = "CREATE_INSPECTION"
export const CREATE_ARCHIVE_INSPECTION = "CREATE_ARCHIVE_INSPECTION"
export const UPDATE_INSPECTION = "UPDATE_INSPECTION"
export const DELETE_INSPECTION = "DELETE_INSPECTION"
export const UPDATE_PPAP = "UPDATE_PPAP"
export const GET_LAST_UPDATED_BY = "GET_LAST_UPDATED_BY"
export const SET_INSPECTION_DETAILS = "SET_INSPECTION_DETAILS"

export const GET_CHARACTERISTICS = "GET_CHARACTERISTICS"
export const CREATE_CHARACTERISTIC = "CREATE_CHARACTERISTIC"
export const UPDATE_CHARACTERISTIC = "UPDATE_CHARACTERISTIC"
export const DELETE_CHARACTERISTIC = "DELETE_CHARACTERISTIC"
export const CLONE_CHARACTERISTIC = "CLONE_CHARACTERISTIC"
export const SET_CHARACTERISTIC = "SET_CHARACTERISTIC"
export const UPDATE_SEQUENCE = "UPDATE_SEQUENCE"
export const NEW_UPDATE_SEQUENCE = "NEW_UPDATE_SEQUENCE"
export const SET_CHARACTERISTIC_DIALOG = "SET_CHARACTERISTIC_DIALOG"
export const SET_TO_DELETE = "SET_TO_DELETE"
export const ARCHIVE_CHARACTERISTIC = "ARCHIVE_CHARACTERISTIC"
export const CHARACTERISTIC_ROUTING = "CHARACTERISTIC_ROUTING"

export const GET_FIRST_VERIFICATION_EMAILS = "GET_FIRST_VERIFICATION_EMAILS"
export const GET_SECOND_VERIFICATION_EMAILS = "GET_SECOND_VERIFICATION_EMAILS"
export const GET_PPAP_EMAILS = "GET_PPAP_EMAILS"
export const GET_UPDATE_ROUTING_EMAILS = "GET_UPDATE_ROUTING_EMAILS"
export const GET_ALL_EMAILS = "GET_ALL_EMAILS"

export const SET_OUTPUT = "SET_OUTPUT"

export const GET_APPLICATION_AUTH = "GET_APPLICATION_AUTH"
