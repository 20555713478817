import axios from "../auth/axiosAuth"
import firebaseAuth from "../auth/firebaseAuth"

export default async function resetHeaders() {
    const user = firebaseAuth.currentUser

    if (user) {
        const token = await user.getIdToken()
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        axios.defaults.headers.common["gid"] = user.uid
    }
}
