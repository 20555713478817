// Importing necessary modules and hooks from React, Redux, Material-UI and local modules
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
    Grid,
    Button,
    Snackbar,
    Backdrop,
    makeStyles,
    Typography
} from "@material-ui/core"
import Alert from "./Alert"
import UpdateGears from "../../resources/images/updateGears.png"
import RefreshIcon from "@material-ui/icons/Refresh"
import CloseIcon from "@material-ui/icons/Close"
import axios from "../../auth/axiosAuth"
import useStyles from "./styles/VersionAlertStyles"
import { APPLICATION_ID, appAdminBackendURL } from "../../config"

// Customizing styles for snackbar (alert component)
const useSnackbarStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    alert: {
        padding: theme.spacing(2.5)
    },
    backdrop: {
        zIndex: theme.zIndex.snackbar + 4,
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, .8)"
    },
    snackbar: {
        height: "75%",
        zIndex: theme.zIndex.snackbar + 10
    }
}))

// Main component for displaying version alerts
function VersionAlert({ children }) {
    const classes = useStyles()
    const snackbarClasses = useSnackbarStyles()

    // Selecting logged-in user's auth status from Redux store
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )

    // Defining local state for version, latestVersion, alert open status, backdrop open status, and showAlertAgain status
    const [version, setVersion] = useState(null)
    const [latestVersion, setLatestVersion] = useState(null)
    const [open, setOpen] = useState(false)
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [showAlertAgain, setShowAlertAgain] = useState(false)

    // useEffect to handle re-opening of the update notification after specific interval if the showAlertAgain flag is set
    useEffect(() => {
        if (showAlertAgain) {
            const timeoutId = setTimeout(() => {
                setOpen(true)
                setBackdropOpen(true)
                setShowAlertAgain(false)
            }, 5 * 60 * 1000) // 5 minutes in milliseconds

            return () => clearTimeout(timeoutId)
        }
    }, [showAlertAgain])

    // useEffect to initialize version state after user log in
    useEffect(() => {
        const initialVersion = async () => {
            if (loggedIn && !version) {
                try {
                    const fetchedVersion = await getVersion(APPLICATION_ID)
                    setVersion(fetchedVersion)
                } catch (error) {
                    console.error(error)
                }
            }
            return () => {}
        }
        initialVersion()
    }, [loggedIn, version])

    // useEffect to periodically check for updates
    useEffect(() => {
        const checkVersion = async () => {
            try {
                const fetchedVersion = await getVersion(APPLICATION_ID)
                setLatestVersion(fetchedVersion)
            } catch (error) {
                console.error(error)
            }
        }

        checkVersion() // Run immediately on mount
        const intervalId = setInterval(checkVersion, 6000) // then every 1 minute

        return () => clearInterval(intervalId) // cleanup on unmount
    }, [])

    // useEffect to show the alert if new version is detected
    useEffect(() => {
        if (version && latestVersion && version !== latestVersion) {
            setOpen(true)
            setBackdropOpen(true)
        }
    }, [latestVersion, version])

    // Function to remove 'v' from version string
    function removeV(word) {
        return word.replace(/v/g, "")
    }

    // Function to get the application version
    const getVersion = async (applicationID) => {
        const res = await axios.get(
            `${appAdminBackendURL}/application/${applicationID}`
        )
        let fetchedVersion = null
        if (res.data[0]) {
            fetchedVersion = `v${res.data[0].MajorVersion}.${res.data[0].MinorVersion}.${res.data[0].FixVersion}`
        }

        return fetchedVersion
    }

    // Handler function for closing the alert and scheduling it to show again
    const handleAlertDismiss = () => {
        setOpen(false)
        setBackdropOpen(false)
        setShowAlertAgain(true)
    }

    // Handler function for refreshing the page to update the app
    const handleRefresh = () => {
        window.location.reload()
    }

    // Render alert and backdrop along with children
    return (
        <>
            <div className={snackbarClasses.root}>
                <Snackbar
                    className={snackbarClasses.snackbar}
                    anchorOrigin={{
                        vertical: "",
                        horizontal: "center"
                    }}
                    open={open}
                    onClose={handleAlertDismiss}
                >
                    <Alert
                        severity="warning"
                        icon={false}
                        className={snackbarClasses.alert}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item align="center">
                                <img
                                    alt="Update Icon"
                                    src={UpdateGears}
                                    className={classes.Logo}
                                />
                            </Grid>
                            <Grid item align="center">
                                <Typography
                                    variant="h6"
                                    className={classes.AlertTitle}
                                >
                                    {`New Update Available!`}
                                </Typography>
                            </Grid>
                            <Grid item align="center">
                                <Typography
                                    variant="body1"
                                    className={classes.AlertText}
                                >
                                    {`Version ${
                                        latestVersion
                                            ? removeV(latestVersion)
                                            : ""
                                    }  is now ready for your usage. Press 'Update' to enhance your experience.`}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.AlertText}
                                >
                                    If you choose to 'Dismiss', you will be
                                    reminded again in 5 minutes.
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Button
                                    startIcon={<RefreshIcon />}
                                    color="inherit"
                                    onClick={handleRefresh}
                                    className={classes.LowerCaseButtonLeft}
                                    variant="contained"
                                >
                                    Update
                                </Button>
                                <Button
                                    endIcon={<CloseIcon />}
                                    color="inherit"
                                    onClick={handleAlertDismiss}
                                    className={classes.LowerCaseButtonRight}
                                    variant="contained"
                                >
                                    Dismiss
                                </Button>
                            </Grid>
                        </Grid>
                    </Alert>
                </Snackbar>
                <Backdrop
                    className={snackbarClasses.backdrop}
                    open={backdropOpen}
                />
            </div>
            {children}
        </>
    )
}

export default VersionAlert
