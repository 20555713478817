import React from "react"
import { useSelector } from "react-redux"
import { Grid, Typography, Box } from "@material-ui/core"
import Stack from "@mui/material/Stack"
import { gearDepartmentNumbers } from "../../../config"

import useStyles from "./styles/OutputStyles"

function InProcessFooter() {
    const classes = useStyles()
    const output = useSelector((state) => state.output)

    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.FooterBox}
            >
                <Grid item className={classes.BaseLineFooter}>
                    <Typography
                        multiline
                        variant="subtitle1"
                        className={classes.FooterContent}
                    >
                        Baseline:
                    </Typography>
                </Grid>
                <Grid item className={classes.Padding}>
                    <Stack>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                        >
                            1) Less than .0005 Tolerance: -Supermic
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                        >
                            .0006 thru .003 Tolerance: Tenth Mic for OD .xxxx
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContentRight}
                            align="right"
                        >
                            Mic/Height Gages for Lengths .xxx
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            2) More than .003 Tolerance: Caliper for OD, ID,
                            Dim, Length .xxx, .x
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item className={classes.Padding}>
                    <Stack>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                        >
                            3) Radius: Comparator / Radius Gage
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                        >
                            4) Surface Finish: Profilometer / Surface Comparator
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            5) Runout / Concentricity: Tenth Indicator / Centers
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            6) Parallel / Square: Tenth Indicator
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item className={classes.Padding}>
                    <Stack>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            7) Chamfers: Comparator
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            8) True Position: CMM / Vision System
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            9) Internal Threads / Spline: Thread / Spline Plug
                            Gage
                        </Typography>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            10) External Threads / Spline: Thread / Spline Ring
                            Gage
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item className={classes.PaddingLarge}>
                    <Stack>
                        <Typography
                            multiline
                            variant="subtitle1"
                            className={classes.FooterContent}
                            align="left"
                        >
                            11) The acceptable method of hole diameter
                            acceptance for holes larger than .375 dia with a
                            .0003 tolerance max is to use Sunnen Gage, Air Gage,
                            or Bore Gages to inspect for size, taper, and out of
                            round conditions. For holes less than .375 dia,
                            Go-No Go pins are acceptable except for product
                            using pins for acceptance in which pins utilized
                            more than 500X, carbide pins must be used.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Grid item className={classes.OperatorInit}>
                    <Typography
                        multiline
                        variant="subtitle1"
                        className={classes.Initials}
                    >
                        Operator's Initials or Inspector Stamp
                    </Typography>
                </Grid>
                {output.ppap ? (
                    <>
                        <Grid item className={classes.ControlLargeBlank}></Grid>
                        <Grid item className={classes.ControlSmallBlank}></Grid>
                        <Grid item className={classes.ControlSmallBlank}></Grid>
                        <Grid item className={classes.ControlSmallBlank}></Grid>
                        <Grid item className={classes.ControlSmallBlank}></Grid>
                        <Grid item className={classes.ControlSmallBlank}></Grid>
                    </>
                ) : (
                    <>
                        <Grid item className={classes.LargeBlank}></Grid>
                        <Grid item className={classes.SmallBlank}></Grid>
                        <Grid item className={classes.SmallBlank}></Grid>
                        <Grid item className={classes.SmallBlank}></Grid>
                        <Grid item className={classes.SmallBlank}></Grid>
                        <Grid item className={classes.SmallBlank}></Grid>
                    </>
                )}
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {output.ppap ? (
                    <Typography
                        multiline
                        variant="subtitle1"
                        className={classes.OpInstructions}
                    >
                        If There Are No Frequency Callouts, Operator Inspects
                        All Dimensions Twice (2) per shift and Records Actual
                        Readings
                    </Typography>
                ) : (
                    <Typography
                        multiline
                        variant="subtitle1"
                        className={classes.OpInstructions}
                    >
                        Operator Inspects All Dimensions Twice (2) per shift and
                        Records Actual Readings
                    </Typography>
                )}
            </Grid>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography
                    multiline
                    variant="subtitle1"
                    className={classes.PQFandDate}
                >
                    {gearDepartmentNumbers.includes(output.department.trim())
                        ? output.ppap
                            ? "PQF 1010-22 Rev 3"
                            : "PQF 1010-23 Rev 3"
                        : output.ppap
                        ? "PQF 1010-09 Rev 8"
                        : "PQF 1010-07 Rev 13"}
                </Typography>
                {output.ppap ? (
                    <Typography
                        multiline
                        variant="subtitle1"
                        className={classes.PQFandDate}
                    >
                        If Required PPK/CPK Is Not Achieved, 100% Read & Record
                        Is Required.
                    </Typography>
                ) : null}
                <Typography
                    multiline
                    variant="subtitle1"
                    className={classes.PQFandDate}
                >
                    {new Date().toLocaleDateString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric"
                    })}
                </Typography>
            </Grid>
        </Box>
    )
}

export default InProcessFooter
