import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Tooltip,
    DialogActions,
    Grid,
    TextField,
    Paper,
    Typography,
    FormControlLabel,
    Switch,
    Button,
    IconButton,
    Snackbar,
    Box,
    LinearProgress,
    CircularProgress,
    Zoom,
    Select,
    MenuItem
} from "@material-ui/core"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import Draggable from "react-draggable"
import Autocomplete from "@mui/material/Autocomplete"
import moment from "moment"

//Icons
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded"
import SyncProblemRoundedIcon from "@mui/icons-material/SyncProblemRounded"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded"
import WarningRoundedIcon from "@mui/icons-material/WarningRounded"
import PrintRoundedIcon from "@mui/icons-material/PrintRounded"
import CircleIcon from "@mui/icons-material/Circle"
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded"
import SendRoundedIcon from "@mui/icons-material/SendRounded"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"

import useStyles from "./styles/InspectionStyle"

import {
    getItmrva,
    getItmprc,
    getFacmst,
    getOperations,
    getManufacturingOrders,
    getManufacturingOps,
    getCurrentRevision
} from "../../actions/erpActions"
import {
    getInspections,
    createInspection,
    archiveInspection,
    updateInspection,
    setInspection,
    updatePPAP
} from "../../actions/inspectionActions"
import {
    getCharacteristics,
    deleteCharacteristic,
    cloneCharacteristic,
    setCharacteristicDialog,
    setToDelete
} from "../../actions/characteristicActions"
import {
    getControlMethods,
    getInspectionMethods,
    getFrequencies
} from "../../actions/maintenanceActions"

//Dialogs
import TicketDialog from "../dialog/TicketDialog"

import { setOutput } from "../../actions/outputActions"
import { sendEmail, getAllEmails } from "../../actions/emailActions"
import LoginPrompt from "../login/LoginPrompt"
import LoadingScreen from "../main/LoadingScreen"
import Alert from "../main/Alert"
import resetHeaders from "../../Functions/ResetHeaders"
import CharacteristicTable from "./CharacteristicTable"
import FavoritesTab from "../gtsKeyboard/FavortitesTab"

import {
    backendURL,
    gearDepartmentNumbers,
    emailRecipients
} from "../../config"
import axios from "../../auth/axiosAuth"

import "../../resources/fonts/fonts.css"

const EMAIL_RECIPIENTS = emailRecipients

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    )
}

function InspectionPage() {
    const dispatch = useDispatch()
    // Store Variables
    const auth = useSelector((state) => state.auth)
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const permissions = useSelector((state) => state.auth.permissions)

    const maintenance = useSelector((state) => state.maintenance)

    const firstVerificationEmails = useSelector(
        (state) => state.admin.firstVerificationEmails
    )
    const secondVerificationEmails = useSelector(
        (state) => state.admin.secondVerificationEmails
    )
    const ppapEmails = useSelector((state) => state.admin.PPAPEmails)
    const updateRoutingEmails = useSelector(
        (state) => state.admin.updateRoutingEmails
    )

    const itmrva = useSelector((state) => state.erp.itmrva)
    const itmprc = useSelector((state) => state.erp.itmprc)
    const facmst = useSelector((state) => state.erp.facmst)
    const rtgoprOps = useSelector((state) => state.erp.rtgoprOps)
    const manufacturingOrders = useSelector(
        (state) => state.erp.manufacturingOrders
    )
    const manufacturingOrderOps = useSelector(
        (state) => state.erp.manufacturingOrderOps
    )
    const currentRevision = useSelector((state) => state.erp.currentRevision)
    const inspections = useSelector((state) => state.inspection.inspections)
    const classes = useStyles()
    const inspection = useSelector((state) => state.inspection)
    const characteristic = useSelector((state) => state.characteristic)

    //XA States
    const [revisions, setRevisions] = useState({
        rev: "",
        revisionsList: []
    })
    const [operations, setOperations] = useState({
        op: "",
        operationsList: []
    })
    const [moOps, setMoOps] = useState({
        moOp: "",
        moOpsList: []
    })
    const [items, setItems] = useState({
        itm: "",
        itmName: "",
        itemID: "",
        rtid: "",
        rtvr: "",
        itemsList: []
    })
    const [department, setDepartment] = useState("")
    const [workcenter, setWorkcenter] = useState({
        wkctr: "",
        workcenterList: []
    })
    const [mo, setMo] = useState("")

    //Verification States
    const [verifiedBy, setVerifiedBy] = useState("")
    const [verifiedByOID, setVerifiedByOID] = useState("")
    const [verified, setVerified] = useState(false)
    const [verifiedDate, setVerifiedDate] = useState("")
    const [secondVerifiedBy, setSecondVerifiedBy] = useState("")
    const [secondVerifiedByOID, setSecondVerifiedByOID] = useState("")
    const [secondVerified, setSecondVerified] = useState(false)
    const [secondVerifiedDate, setSecondVerifiedDate] = useState("")

    //Storage States
    const [ppap, setPpap] = useState(false)
    const [primary, setPrimary] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingOperations, setLoadingOperations] = useState(false)
    const [manufacturingOrder, setManufacturingOrder] = useState("")
    const [viewOps, setViewOps] = useState(false)
    const [newOp, setNewOp] = useState("")
    const [newWKCTR, setNewWKCTR] = useState("")
    const [inspectionId, setInspectionId] = useState("")
    const [material, setMaterial] = useState("")
    const [certification, setCertification] = useState("")

    // Alert State
    const [alertDialog, setAlertDialog] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("")

    //Alternate Dialog States
    const [alternateDialog, setAlternateDialog] = useState(false)
    const [alternateText, setAlternateText] = useState("")

    //Alternate NoMO Dialog States
    const [alternateNoMODialog, setAlternateNoMODialog] = useState(false)
    const [alternateNoMOText, setAlternateNoMOText] = useState("")

    //Create Operation Dialog States
    const [createOperationDialog, setCreateOperationDialog] = useState(false)
    const [createOperationText, setCreateOperationText] = useState("")

    //Edit Workcenter Dialog States
    const [editWorkcenterDialog, setEditWorkcenterDialog] = useState(false)
    const [editWorkcenterText, setEditWorkcenterText] = useState("")

    //Update Routing Dialog States
    const [updateRoutingDialog, setUpdateRoutingDialog] = useState(false)
    const [updateRoutingText, setUpdateRoutingText] = useState("")
    const [updateRoutingPercent, setUpdateRoutingPercent] = useState(0)

    //Archive Dialog States
    const [archiveDialog, setArchiveDialog] = useState(false)
    const [archiveText, setArchiveText] = useState("")
    const [archiveLoading, setArchiveLoading] = useState(false)

    //Verification Dialog States
    const [verificationDialog, setVerificationDialog] = useState(false)
    const [verificationText, setVerificationText] = useState("")

    //Second Verification Dialog States
    const [secondVerificationDialog, setSecondVerificationDialog] =
        useState(false)
    const [secondVerificationText, setSecondVerificationText] = useState("")

    //Manufacturing Order Dialog States
    const [manufacturingOrderDialog, setManufacturingOrderDialog] =
        useState(false)
    const [manufacturingOrderText, setManufacturingOrderText] = useState("")

    //Clone Dialog States
    const [cloneDialog, setCloneDialog] = useState(false)
    const [clonePrimary, setClonePrimary] = useState(true)
    const [cloneItem, setCloneItem] = useState("")
    const [cloneRevision, setCloneRevision] = useState("")
    const [cloneOperation, setCloneOperation] = useState("")
    const [toFrom, setToFrom] = useState("to")
    const [cloneConfirm, setCloneConfirm] = useState(false)
    const [cloneList, setCloneList] = useState([])

    //email states
    const [sendingEmail, setSendingEmail] = useState(false)
    const [sendingFirstVerificationEmail, setSendingFirstVerificationEmail] =
        useState(false)
    const [sendingSecondVerificationEmail, setSendingSecondVerificationEmail] =
        useState(false)
    const [sendingPpapEmail, setSendingPpapEmail] = useState(false)
    const [sendingResetRoutingEmail, setSendingResetRoutingEmail] =
        useState(false)

    //Material Dialog States
    const [materialDialog, setMaterialDialog] = useState(false)
    const [newMaterial, setNewMaterial] = useState("")

    //Certification Dialog States
    const [certificationDialog, setCertificationDialog] = useState(false)
    const [newCertification, setNewCertification] = useState("")

    const [itemsLimit, setItemsLimit] = useState(20)

    const [openTicketDialog, setOpenTicketDialog] = useState(false)

    const [blurTimer, setBlurTimer] = useState(null)

    const [lockToFrom, setLockToFrom] = useState(false)
    const [blankReport, setBlankReport] = useState(false)

    const inspectionMemo = useMemo(
        () =>
            inspections.filter(
                (item) =>
                    item.ItemId === items.itemID && item.PrimaryFlag === primary
            ),
        [inspections, items, primary]
    )

    const inspectionOpMemo = useMemo(
        () =>
            inspections
                .filter((item, i, arr) => arr.indexOf(item) === i)
                .filter(
                    (item) =>
                        item.ItemId === items.itemID &&
                        item.PrimaryFlag === primary
                ),
        [inspections, items, primary]
    )

    const filterOptions = createFilterOptions({
        ignoreCase: true
    })

    //close alerts
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function fetchData() {
                try {
                    setLoading(true)
                    if (maintenance.controlMethods.length === 0) {
                        await dispatch(getControlMethods())
                    }
                    if (maintenance.inspectionMethods.length === 0) {
                        await dispatch(getInspectionMethods())
                    }
                    if (maintenance.frequencies.length === 0) {
                        await dispatch(getFrequencies())
                    }
                    if (inspections.length === 0)
                        await dispatch(getInspections())

                    if (itmrva.length === 0) await dispatch(getItmrva())
                    if (itmprc.length === 0) await dispatch(getItmprc())
                    if (facmst.length === 0) await dispatch(getFacmst())
                    setLoading(false)
                } catch (error) {
                    if (error.message === "Network Error") {
                        setAlertSeverity("error")
                        setAlertText("Error Fetching Data")
                        setAlertDialog(true)
                    }
                }
            }
            fetchData()
        }
    }, [
        dispatch,
        loggedIn,
        department,
        maintenance.frequencies.length,
        inspections.length,
        itmrva.length,
        itmprc.length,
        facmst.length
    ])

    useEffect(() => {
        if (loggedIn) {
            resetHeaders()
            async function fetchData() {
                try {
                    if (firstVerificationEmails.length === 0) {
                        await dispatch(
                            getAllEmails({
                                DeptCode: ""
                            })
                        )
                    }
                } catch (error) {
                    if (error.message === "Network Error") {
                        setAlertSeverity("error")
                        setAlertText("Error Fetching Data")
                        setAlertDialog(true)
                    }
                }
            }
            fetchData()
        }
    }, [dispatch, loggedIn, department, inspectionId, firstVerificationEmails])

    const clearVerifications = useCallback(() => {
        setVerified(false)
        setSecondVerified(false)
        setVerifiedBy("")
        setSecondVerifiedBy("")
        setVerifiedByOID("")
        setSecondVerifiedByOID("")
        setVerifiedDate("")
        setSecondVerifiedDate("")
    }, [])

    const clearOutputs = useCallback(() => {
        dispatch(setOutput({}))
    }, [dispatch])

    //Item Selector On Change Fetches all active revisions for selected item. Clears all data when cleared
    const handleItemInputChange = useCallback(
        async (event, value) => {
            if (event === null || value === null) {
                return
            }

            const itm = value

            // If the selected item is different from the previous selection,
            // clear the itemsList and revisionsList arrays, and reset other values
            if (itm !== items.itm || itm.trim() === "") {
                setItems({
                    ...items,
                    itmName: "",
                    itemID: "",
                    rtid: "",
                    rtvr: ""
                })
                setRevisions({
                    rev: "",
                    revisionsList: []
                })
                setOperations({
                    op: "",
                    operationsList: []
                })
                setMoOps({
                    moOp: "",
                    moOpsList: []
                })
                dispatch(setInspection(""))
                setViewOps(false)
                setManufacturingOrder("")
                clearVerifications()
                clearOutputs()
                setPrimary(true)
            }

            // Use optimized filtering algorithm to filter the itmrva array
            const filteredItems = itmrva
                .filter((item) =>
                    item.ITNBR?.trim()
                        ?.toLowerCase()
                        ?.startsWith(itm.toLowerCase())
                )
                .slice(0, itemsLimit)

            // Set the itemsList and revisionsList arrays based on the filtered items
            setItems({
                ...items,
                itm,
                itemsList: filteredItems
            })
            setRevisions({
                ...revisions,
                rev: "",
                revisionsList: filteredItems
                    .filter(
                        (item) =>
                            item.ITNBR?.trim().toLowerCase() ===
                            itm.toLowerCase()
                    )
                    .map((item) => item.ITRV.trim())
            })

            //set the current revision
            dispatch(getCurrentRevision(itm))
        },
        [
            items,
            revisions,
            dispatch,
            itmrva,
            clearOutputs,
            clearVerifications,
            itemsLimit
        ]
    )

    const getOperationsClick = async (value) => {
        try {
            setLoadingOperations(true)
            setOperations({ op: "", operationsList: [] })
            setMoOps({ moOp: "", moOpsList: [] })
            clearOutputs()

            if (!items.itm) {
                setAlertSeverity("error")
                setAlertText("Please Select an Item")
                setAlertDialog(true)
                return
            }

            if (!revisions.rev && !value) {
                return
            }

            const itemID = itmrva.find((item) =>
                item.ITNBR.trim() === items.itm.trim() &&
                item.ITRV.trim() === value
                    ? value
                    : revisions.rev && item.ITRV !== ""
            )?.ITMRVAID

            if (!primary) {
                const hasAlternateInspections = inspections.some(
                    (item) =>
                        item.ItemId === itemID && item.PrimaryFlag === false
                )
                if (!hasAlternateInspections) {
                    if (manufacturingOrders.length > 0) {
                        setAlternateText("Please Select a Manufacturing Order")
                        setAlternateDialog(true)
                        setLoadingOperations(false)
                        return
                    } else {
                        setAlternateNoMOText(
                            "No Manufacturing Orders Found, Would you like to use primary routing as a template?"
                        )
                        setAlternateNoMODialog(true)
                        setLoadingOperations(false)
                        return
                    }
                }
            }
            const primaryInspections = inspections.filter(
                (item) => item.ItemId === itemID && item.PrimaryFlag === true
            )

            setOperations({
                operationsList: primaryInspections.map((item) => item.Operation)
            })
            setViewOps(true)
            const alternateInspections = inspections.filter(
                (item) => item.ItemId === itemID && item.PrimaryFlag === false
            )
            setMoOps({
                moOpsList: alternateInspections.map((item) => item.Operation)
            })

            if (
                (primary && operations.op !== "") ||
                (!primary && moOps.moOp !== "")
            ) {
                const inspectionTemp = inspections.find(
                    (item) => item.InspectionId === inspectionId
                )

                if (inspectionTemp) {
                    const {
                        Workcenter,
                        PPAPFlag,
                        Material,
                        Certification,
                        InspectionId,
                        VerifiedBy,
                        VerifiedByOID,
                        VerifiedDate,
                        SecondVerifiedBy,
                        SecondVerifiedByOID,
                        SecondVerifiedDate,
                        ManufacturingOrder
                    } = inspectionTemp

                    setWorkcenter({ ...workcenter, wkctr: Workcenter })
                    setPpap(PPAPFlag)
                    setMaterial(Material)
                    setCertification(Certification)
                    setInspectionId(InspectionId)

                    setVerifiedBy(VerifiedBy)
                    setVerifiedByOID(VerifiedByOID)
                    setVerified(VerifiedBy !== "Unknown")
                    setVerifiedDate(VerifiedDate)

                    setSecondVerifiedBy(SecondVerifiedBy)
                    setSecondVerifiedByOID(SecondVerifiedByOID)
                    setSecondVerified(SecondVerifiedBy !== "Unknown")
                    setSecondVerifiedDate(SecondVerifiedDate)
                    setMo(ManufacturingOrder)
                    dispatch(setInspection(InspectionId))
                }
            }

            await dispatch(getInspections())
        } catch (error) {
            setAlertSeverity("error")
            setAlertText(error.message)
            setAlertDialog(true)
        } finally {
            setLoadingOperations(false)
            await dispatch(getInspections())
        }
    }

    //Revision Selector On Change Fetches all active operations and manufacturing orders for selected revision. Clears all data but item when cleared
    const handleRevisionInputChange = useCallback(
        async (event, value) => {
            clearTimeout(blurTimer)
            const newTimer = setTimeout(() => {
                if (value) {
                    getOperationsClick(value)
                }
            }, 500)
            setBlurTimer(newTimer)

            if (event !== null) {
                const rev = value
                setRevisions({
                    ...revisions,
                    rev: rev
                })
                if (
                    rev?.trim() !== "" &&
                    rev?.trim() !== undefined &&
                    rev?.trim() !== null &&
                    rev?.trim().includes("<svg class=") === false
                ) {
                    if (rev !== revisions.rev) {
                        setItems({
                            ...items,
                            rtid: "",
                            rtvr: "",
                            itemID: "",
                            itmName: ""
                        })
                        dispatch(setInspection(""))
                        setOperations({ op: "", operationsList: [] })
                        setMoOps({ moOp: "", moOpsList: [] })
                        setViewOps(false)
                        setPrimary(true)
                        setManufacturingOrder("")
                        clearVerifications()
                        clearOutputs()
                    }

                    const rtgoprKeys = itmprc.filter(
                        (item) =>
                            item.ITRV.trim() === rev &&
                            item.ITNBR.trim() === items.itm?.trim() &&
                            item.STID === "PRD"
                    )[0]

                    const itemID = itmrva.filter(
                        (item) =>
                            item.ITNBR.trim() === items.itm?.trim() &&
                            item.ITRV.trim() === rev &&
                            item.ITRV !== ""
                    )[0]?.ITMRVAID

                    const itemRTID = rtgoprKeys?.RTID
                    const itemRTVR = rtgoprKeys?.RTVR

                    const itemName = itmrva.filter(
                        (item) =>
                            item.ITNBR.trim() === items.itm?.trim() &&
                            item.ITRV.trim() === rev &&
                            item.ITRV !== ""
                    )[0]?.ITDSC

                    const rtgoprID = `PRD:::${itemRTID?.trimEnd()}:::${
                        itemRTVR?.trimEnd() === "blank"
                            ? ""
                            : itemRTVR?.trimEnd()
                    }:::`
                    await dispatch(getOperations(rtgoprID))
                    const MOKeys = {
                        item: items.itm,
                        revision: rev
                    }
                    setItems({
                        ...items,
                        itemID: itemID,
                        rtid: items.rtid,
                        rtvr: items.rtvr,
                        itmName: itemName
                    })
                    await dispatch(getManufacturingOrders(MOKeys))
                } else {
                    setViewOps(false)
                    setRevisions({
                        ...revisions,
                        rev: ""
                    })
                    setOperations({
                        op: "",
                        operationsList: []
                    })
                    setMoOps({
                        moOp: "",
                        moOpsList: []
                    })
                    setManufacturingOrder("")
                    setViewOps(false)
                    clearVerifications()
                    clearOutputs()
                }
            }
        },
        [
            clearVerifications,
            clearOutputs,
            dispatch,
            items,
            setItems,
            setManufacturingOrder,
            setMoOps,
            setOperations,
            setRevisions,
            setViewOps,
            blurTimer,
            getOperationsClick,
            revisions,
            itmprc,
            itmrva
        ]
    )

    //Primary Slider handler. Carries operation over when switched but updates all details pertaining to that operation
    const primaryOnChange = useCallback(
        async (event) => {
            clearVerifications()
            clearOutputs()
            if (items.itemID === "" || revisions.rev === "") {
                setAlertDialog(true)
                setAlertSeverity("error")
                setAlertText("Please Select an Item and Revision")
                setPrimary(!event.target.checked)
                return
            }
            const op = !primary ? operations.op : moOps.moOp
            const inspection = inspections.find(
                (item) =>
                    item.ItemId === items.itemID &&
                    item.PrimaryFlag === !primary &&
                    item.Operation === (primary ? moOps.moOp : operations.op)
            )
            if (primary) {
                const hasAlternateInspections = inspections.some(
                    (item) =>
                        item.ItemId === items.itemID &&
                        item.PrimaryFlag === false
                )
                if (!hasAlternateInspections) {
                    if (manufacturingOrders.length > 0) {
                        setAlternateText("Please Select a Manufacturing Order")
                        setAlternateDialog(true)
                        setLoadingOperations(false)
                    } else {
                        setAlternateNoMOText(
                            "No Manufacturing Orders Found, Would you like to use primary routing as a template?"
                        )
                        setAlternateNoMODialog(true)
                        setLoadingOperations(false)
                    }
                }
            }

            if (op !== "" && op !== undefined && op !== null) {
                if (!operations.operationsList.includes(op)) {
                    setOperations({
                        ...operations,
                        op: ""
                    })
                } else
                    setOperations({
                        ...operations,
                        op: op
                    })

                if (!moOps.moOpsList.includes(op)) {
                    setMoOps({
                        ...moOps,
                        moOp: ""
                    })
                } else {
                    //characteristic checks
                    setMoOps({
                        ...moOps,
                        moOp: op
                    })
                    const res = await axios.get(
                        `${backendURL}/characteristics/${inspection?.InspectionId?.replace(
                            "/",
                            "%2F"
                        )}`
                    )
                    if (res.data.length <= 0) {
                        setLockToFrom(true)
                        setBlankReport(true)
                        setToFrom("from")
                        setCloneDialog(true)
                        if (cloneList?.length <= 0) {
                            setCloneList(
                                inspections.map((item) => {
                                    const itm = itmprc.find(
                                        (itmp) =>
                                            item?.ItemId === itmp?.ITMPRCID
                                    )
                                    return {
                                        itemNumber: itm?.ITNBR,
                                        revision: itm?.ITRV,
                                        operation: item?.Operation,
                                        primary: item?.PrimaryFlag
                                    }
                                })
                            )
                        }
                    } else {
                        handleCloneClose()
                        setLockToFrom(false)
                        setBlankReport(false)
                    }

                    setMoOps({
                        ...moOps,
                        moOp: op
                    })
                }
                setWorkcenter({
                    ...workcenter,
                    wkctr: inspection?.Workcenter
                })

                setCertification(inspection?.Certification)
                setMaterial(inspection?.Material)
                setPpap(inspection?.PPAPFlag)
                setMo(inspection?.ManufacturingOrder)
                setVerifiedBy(inspection?.VerifiedBy)
                setVerifiedByOID(inspection?.VerifiedByOID)
                inspection?.VerifiedBy !== "Unknown"
                    ? setVerified(true)
                    : setVerified(false)
                setVerifiedDate(inspection?.VerifiedDate)

                setSecondVerifiedBy(inspection?.SecondVerifiedBy)
                setSecondVerifiedByOID(inspection?.SecondVerifiedByOID)
                inspection?.SecondVerifiedBy !== "Unknown"
                    ? setSecondVerified(true)
                    : setSecondVerified(false)
                setSecondVerifiedDate(inspection?.SecondVerifiedDate)

                setInspectionId(inspection?.InspectionId)
                dispatch(setInspection(inspection?.InspectionId))
            }
            await dispatch(getCharacteristics(inspection?.InspectionId))

            setPrimary(event.target.checked)
        },
        [
            clearVerifications,
            clearOutputs,
            primary,
            operations,
            moOps,
            inspections,
            items,
            dispatch,
            workcenter,
            setOperations,
            setMoOps,
            setWorkcenter,
            setPpap,
            setInspectionId,
            setVerifiedBy,
            setVerifiedByOID,
            setVerified,
            setVerifiedDate,
            setSecondVerifiedBy,
            setSecondVerifiedByOID,
            setSecondVerified,
            setSecondVerifiedDate,

            setMo
        ]
    )

    //PPAP Slider handeler. Updates backend when changed
    const PPAPOnChange = async (event) => {
        try {
            setPpap(!ppap)
            const toUpdate = {
                PPAPFlag: !ppap,
                ItemId: items.itemID,
                AuditLog: {
                    Action: ppap ? "PPAP Inspection" : "Remove PPAP Inspection",
                    OldValue: ppap,
                    NewValue: !ppap,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updatePPAP(toUpdate))
            await dispatch(getInspections())
        } catch (error) {
            console.log(error)
        }
    }

    const handleOperationButtonClick = async (event) => {
        const op = event.target.value
            ? event.target.value
            : event.target.innerText

        clearOutputs()
        clearVerifications()
        setLockToFrom(false)
        setBlankReport(false)
        setToFrom("to")

        // Destructure the inspection entry and its values
        const inspection = inspections.find(
            (item) =>
                item.ItemId === items.itemID &&
                item.PrimaryFlag === primary &&
                item.Operation === op
        )

        const {
            Workcenter,
            PPAPFlag,
            Material,
            Certification,
            InspectionId,
            VerifiedBy,
            VerifiedByOID,
            VerifiedDate,
            SecondVerifiedBy,
            SecondVerifiedByOID,
            SecondVerifiedDate,
            ManufacturingOrder
        } = inspection

        // Check if the operation value is not empty or undefined
        if (op.trim() !== "" && op.trim() !== undefined) {
            setOperations({ ...operations, op })
            setMoOps({ ...moOps, moOp: op })

            setDepartment(
                facmst.find(
                    (item) =>
                        item?.WKCTR?.trim() === Workcenter?.trim() &&
                        item?.STID === "PRD"
                )?.DPTNO
            )
            setWorkcenter({ ...workcenter, wkctr: Workcenter })
            setPpap(PPAPFlag)
            setMaterial(Material)
            setCertification(Certification)
            setInspectionId(InspectionId)

            //set verified by and verified state
            setVerifiedBy(VerifiedBy)
            setVerifiedByOID(VerifiedByOID)
            setVerified(VerifiedBy !== "Unknown")
            setVerifiedDate(VerifiedDate)

            //set second verified by and verified state
            setSecondVerifiedBy(SecondVerifiedBy)
            setSecondVerifiedByOID(SecondVerifiedByOID)
            setSecondVerified(SecondVerifiedBy !== "Unknown")
            setSecondVerifiedDate(SecondVerifiedDate)
            setMo(ManufacturingOrder)
            dispatch(setInspection(InspectionId))

            await dispatch(getCharacteristics(InspectionId))

            getDepartmentEmails(Workcenter)

            //characteristic checks
            const res = await axios.get(
                `${backendURL}/characteristics/${InspectionId?.replace(
                    "/",
                    "%2F"
                )}`
            )
            if (res.data.length <= 0) {
                setLockToFrom(true)
                setBlankReport(true)
                setToFrom("from")
                setCloneDialog(true)
                if (cloneList?.length <= 0) {
                    setCloneList(
                        inspections.map((item) => {
                            const itm = itmprc.find(
                                (itmp) => item?.ItemId === itmp?.ITMPRCID
                            )
                            return {
                                itemNumber: itm?.ITNBR,
                                revision: itm?.ITRV,
                                operation: item?.Operation,
                                primary: item?.PrimaryFlag
                            }
                        })
                    )
                }
            } else {
                handleCloneClose()
                setLockToFrom(false)
                setBlankReport(false)
            }
        } else {
            setOperations({ ...operations, op: "" })
            setWorkcenter({ ...workcenter, wkctr: "" })
            setDepartment("")
            setVerifiedBy("")
            setSecondVerifiedBy("")
            setVerifiedDate("")
            setSecondVerifiedDate("")
            setMo("")
        }
    }

    const getDepartmentEmails = async (Workcenter) => {
        dispatch(
            getAllEmails({
                DeptCode: facmst.find(
                    (item) => item?.WKCTR === Workcenter && item?.STID === "PRD"
                )?.DPTNO
            })
        )
    }

    //Verfied Switch Handler. Updates Backend when changed
    const verifiedOnChange = async () => {
        setVerifiedBy(verified ? "" : auth.user.displayName)
        setVerifiedByOID(verified ? "" : auth.userData.AssociateOID)
        setVerifiedDate(verified ? "" : new Date().toISOString())
        try {
            setVerified(!verified)
            if (!verified === false) {
                setSecondVerifiedBy("")
                setSecondVerifiedDate("")
                setSecondVerified(false)
            }
            const insId = inspectionId
            const toUpdate = {
                InspectionId: insId,
                VerifiedBy: verified ? "" : auth.userData.AssociateOID,
                VerifiedDate: verified ? null : new Date().toISOString(),
                SecondVerifiedBy: "",
                SecondVerifiedDate: null,
                AuditLog: {
                    Action: verified
                        ? "Unverify Inspection"
                        : "Verify Inspection",
                    OldValue: verifiedBy,
                    NewValue: verified ? "" : auth.userData.AssociateOID,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updateInspection(toUpdate))
        } catch (error) {
            console.log(error)
        }
        setVerificationDialog(false)
        setVerificationText("")
        await dispatch(getInspections())
    }

    //Second Verfied Switch Handler. Updates Backend when changed
    const secondVerifiedOnChange = async () => {
        setSecondVerifiedBy(secondVerified ? "" : auth.user.displayName)
        setSecondVerifiedByOID(secondVerified ? "" : auth.userData.AssociateOID)
        setSecondVerifiedDate(secondVerified ? "" : new Date().toISOString())
        if (!verified) return
        try {
            setSecondVerified(!secondVerified)
            const insId = inspectionId
            const toUpdate = {
                InspectionId: insId,
                SecondVerifiedBy: secondVerified
                    ? ""
                    : auth.userData.AssociateOID,
                SecondVerifiedDate: secondVerified
                    ? null
                    : new Date().toISOString(),
                AuditLog: {
                    Action: secondVerified
                        ? "Un-second Verify Inspection"
                        : "Second Verify Inspection",
                    OldValue: secondVerifiedBy,
                    NewValue: secondVerified ? "" : auth.userData.AssociateOID,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updateInspection(toUpdate))
        } catch (error) {
            console.log(error)
        }
        setSecondVerificationDialog(false)
        setSecondVerificationText("")
        await dispatch(getInspections())
    }

    //Workcenter Select Handler. Allows users to change the workcenter for an operation.
    //Stores localy here and updates on confirm click
    const handleNewWorkCenterChange = async (event) => {
        const wkctr = event.target.innerHTML
            ? event.target.innerHTML
            : event.target.value
        if (
            event.target.value !== undefined &&
            wkctr.trim() !== "" &&
            wkctr.trim() !== undefined &&
            wkctr.trim().includes("<svg class=") === false
        ) {
            setNewWKCTR(wkctr)
            setDepartment(
                facmst.find(
                    (item) => item?.WKCTR === wkctr && item?.STID === "PRD"
                )?.DPTNO
            )
        } else {
            setNewWKCTR("")
            setDepartment("")
        }
    }

    //Manufacturing Order Select Handler. Allows users to Select the MO for a routing.
    //Stores localy here and updates on confirm click
    const handleManufacturingOrderSelectorChange = async (event) => {
        const mnfgOrder = event.target.innerHTML
            ? event.target.innerHTML
            : event.target.value
        if (
            event.target.value !== undefined &&
            mnfgOrder.trim() !== "" &&
            mnfgOrder.trim() !== undefined &&
            mnfgOrder.trim().includes("<svg class=") === false
        ) {
            setManufacturingOrder(mnfgOrder)
            await dispatch(getManufacturingOps(mnfgOrder))
        } else {
            setManufacturingOrder("")
            setMoOps({
                moOp: "",
                moOpsList: []
            })
        }
    }

    //Confirmation for MO Alternate routing. Fetches all operations for selected MO and creates inspections
    const handleAlternateSelect = async (event) => {
        clearVerifications()
        clearOutputs()
        let itemID = itmrva.find(
            (item) =>
                item.ITNBR.trim() === items.itm?.trim() &&
                item.ITRV.trim() === revisions.rev &&
                item.ITRV !== ""
        )?.ITMRVAID
        setOperations({
            ...operations,
            operationsList: inspections
                .filter(
                    (item) =>
                        item.ItemId === itemID && item.PrimaryFlag === true
                )
                .map((item) => item.Operation)
        })
        if (
            manufacturingOrder === "" ||
            manufacturingOrder === undefined ||
            manufacturingOrder === null
        ) {
            setAlertSeverity("error")
            setAlertText("Please Select a Manufacturing Order")
            setAlertDialog(true)
            return
        }
        setMoOps({
            ...moOps,
            moOpsList: rtgoprOps.map((item) => item.OPSEQ)
        })
        try {
            for (let i = 0; i < rtgoprOps.length; i++) {
                await dispatch(
                    createInspection({
                        ItemId: items.itemID,
                        Operation: rtgoprOps[i]?.OPSEQ,
                        Workcenter: rtgoprOps[i]?.WKCTR,
                        PrimaryFlag: primary,
                        ArchiveFlag: false,
                        PPAPFlag: false,
                        AuditLog: {
                            Action: "Create Inspection",
                            OldValue: "",
                            NewValue: "",
                            AssociateOID: auth.userData.AssociateOID
                        }
                    })
                )
            }
            setAlternateDialog(false)
            setAlternateText("")
            await dispatch(getInspections())
            setLoadingOperations(false)
            setViewOps(true)
        } catch (error) {
            setAlertSeverity("error")
            setAlertText(error)
            setAlertDialog(true)
            setAlternateDialog(false)
        }

        setViewOps(true)
        setAlternateDialog(false)
        setAlternateText("")
    }

    //On close for alternate routing dialog
    const handleAlternateDialogClose = () => {
        setAlternateDialog(false)
        setAlternateText("")
        setLoadingOperations(false)
    }

    const handleSecondayNoMOConfirm = async () => {
        clearVerifications()
        clearOutputs()
        let itemID = itmrva.filter(
            (item) =>
                item.ITNBR.trim() === items.itm?.trim() &&
                item.ITRV.trim() === revisions.rev &&
                item.ITRV !== ""
        )[0]?.ITMRVAID
        setOperations({
            ...operations,
            operationsList: inspections
                .filter(
                    (item) =>
                        item.ItemId === itemID && item.PrimaryFlag === true
                )
                .map((item) => item.Operation)
        })
        setMoOps({
            ...moOps,
            moOpsList: rtgoprOps.map((item) => item.OPSEQ)
        })
        setOperations({
            ...operations,
            operationsList: inspections
                .filter(
                    (item) =>
                        item.ItemId === items.itemID &&
                        item.PrimaryFlag === true
                )
                .map((item) => item.Operation)
        })
        try {
            for (let i = 0; i < rtgoprOps.length; i++) {
                await dispatch(
                    createInspection({
                        ItemId: items.itemID,
                        Operation: rtgoprOps[i]?.OPSEQ,
                        Workcenter: rtgoprOps[i]?.WKCTR,
                        PrimaryFlag: primary,
                        ArchiveFlag: false,
                        PPAPFlag: false,
                        AuditLog: {
                            Action: "Create Inspection",
                            OldValue: "",
                            NewValue: "",
                            AssociateOID: auth.userData.AssociateOID
                        }
                    })
                )
            }
            setAlternateNoMODialog(false)
            setAlternateNoMOText("")
            await dispatch(getInspections())
            setLoadingOperations(false)
            setViewOps(true)
        } catch (error) {
            setAlertSeverity("error")
            setAlertText(error)
            setAlertDialog(true)
            setAlternateDialog(false)
        }
        setViewOps(true)
        setAlternateNoMODialog(false)
        setAlternateNoMOText("")
        setAlternateText("")
    }

    const handleSecondayNoMOClose = () => {
        setAlternateNoMODialog(false)
        setLoadingOperations(false)
    }

    const handlePrimaryOnClick = () => {
        setPrimary(!primary)
    }

    //Opens Dialog for creating a new operation
    const handleCreateOperationOpen = () => {
        setCreateOperationDialog(true)
        setCreateOperationText("Create Operation")
    }

    //Stores the new op number in state
    const handleCreateOperationInput = (event) => {
        setNewOp(event.target.value)
    }

    const handleGoBack = () => {
        setCloneConfirm(false)
    }

    //Creates a new operation and adds it to the database.
    //Ensures operation is a number of length 4 and that it is not already in use
    const handleCreateNewOperation = async () => {
        if (
            newOp.trim() === "" ||
            newOp.trim() === undefined ||
            isNaN(newOp) ||
            newOp.length !== 4
        ) {
            setAlertSeverity("error")
            setAlertText("Please Enter a Valid Operation")
            setAlertDialog(true)
            return
        }
        if (
            moOps.moOpsList.filter((item) => item === newOp.trim()).length > 0
        ) {
            setAlertSeverity("error")
            setAlertText("Operation Already Exists")
            setAlertDialog(true)
            return
        }
        if (newWKCTR.trim() === "" || newWKCTR.trim() === undefined) {
            setAlertSeverity("error")
            setAlertText("Please Select a Valid Workcenter")
            setAlertDialog(true)
            return
        }

        try {
            const toCreate = {
                ItemId: items.itemID,
                Operation: newOp.trim(),
                Workcenter: newWKCTR.trim(),
                PrimaryFlag: false,
                ArchiveFlag: false,
                PPAPFlag: false,
                AuditLog: {
                    Action: "Create Inspection",
                    OldValue: "",
                    NewValue: "",
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            setInspectionId(
                items.itemID + ":::" + newOp.trim() + ":::alternate:::active"
            )
            await dispatch(createInspection(toCreate))
            await dispatch(
                setInspection(
                    items.itemID +
                        ":::" +
                        newOp.trim() +
                        ":::alternate:::active"
                )
            )
            await dispatch(getInspections())
            await dispatch(getCharacteristics())
            await setCreateOperationDialog(false)
            setCreateOperationText("")
            setNewOp("")
            setNewWKCTR("")
        } catch (error) {
            setAlertSeverity("error")
            setAlertText(error)
            setAlertDialog(true)
            setCreateOperationDialog(false)
            setCreateOperationText("")
            setNewOp("")
            setNewWKCTR("")
        }

        setMoOps({
            moOp: newOp.trim(),
            moOpsList: moOps.moOpsList.concat(newOp.trim()).sort()
        })
        setWorkcenter({
            ...workcenter,
            wkctr: newWKCTR.trim()
        })
        setInspectionId(
            items.itemID + ":::" + newOp.trim() + ":::alternate:::active"
        )
        setPpap(false)
        setMaterial("")
        setCertification("")

        clearVerifications()
        clearOutputs()
        setMo("")
    }

    //Closes the create operation dialog
    const handleCreateOperationClose = () => {
        setCreateOperationDialog(false)
        setCreateOperationText("")
    }

    //Opens Edit workcenter dialog
    const handleEditWorkcenterOpen = () => {
        setEditWorkcenterDialog(true)
        setNewWKCTR("")
        setEditWorkcenterText("Edit Workcenter")
    }

    //Ensures a valid workcenter is selected and updates the database
    const handleConfirmEditWorkcenter = async () => {
        if (newWKCTR.trim() === "" || newWKCTR.trim() === undefined) {
            setAlertText("Please Select a Valid Workcenter")
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        } else if (newWKCTR.trim() === workcenter.wkctr.trim()) {
            setAlertText("Workcenter is the same")
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        } else {
            const oldWorkcenter = workcenter.wkctr
            const insId = inspectionId
            const toUpdate = {
                Workcenter: newWKCTR.trim(),
                InspectionId: insId,
                AuditLog: {
                    Action: "Update Workcenter",
                    OldValue: oldWorkcenter,
                    NewValue: newWKCTR.trim(),
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            try {
                await dispatch(updateInspection(toUpdate))
                setWorkcenter({
                    ...workcenter,
                    wkctr: newWKCTR.trim()
                })
                setEditWorkcenterDialog(false)
                setEditWorkcenterText("")
                setNewWKCTR("")
            } catch (error) {
                setAlertText(error)
                setAlertSeverity("error")
                setAlertDialog(true)
            }
            setEditWorkcenterDialog(false)
            await dispatch(getInspections())
        }
    }

    //Closes the edit workcenter dialog
    const handleEditWorkcenterClose = () => {
        setEditWorkcenterDialog(false)
        setNewWKCTR("")
        setEditWorkcenterText("")
    }

    //Opens Archive dialog
    const handleArchiveOpen = () => {
        setArchiveDialog(true)
        setArchiveText(`Are you sure you would like to archive the inspection?`)
    }

    //Handler for archive button. Deletes active version of report
    //then creates archive version of report
    const handleArchive = async () => {
        const operation = primary ? operations.op : moOps.moOp
        setArchiveLoading(true)
        setOperations({
            ...operations,
            op: ""
        })
        setMoOps({
            ...moOps,
            moOp: ""
        })
        try {
            await dispatch(
                archiveInspection({
                    ItemId: items.itemID,
                    Operation: operation,
                    Workcenter: workcenter.wkctr,
                    PrimaryFlag: primary,
                    ArchiveFlag: true,
                    PPAPFlag: ppap,
                    InspectionId: inspectionId,
                    VerifiedBy: verifiedByOID,
                    SecondVerifiedBy: secondVerifiedByOID,
                    ManufacturingOrder: mo,
                    AuditLog: {
                        Action: "Archive Inspection",
                        OldValue: "",
                        NewValue: "",
                        AssociateOID: auth.userData.AssociateOID
                    }
                })
            )
            await dispatch(getInspections())
            setArchiveDialog(false)
            setArchiveLoading(false)
            setArchiveText("")
            primary
                ? setOperations({
                      op: "",
                      operationsList: operations.operationsList.filter(
                          (item) => item !== operation
                      )
                  })
                : setMoOps({
                      moOp: "",
                      moOpsList: moOps.moOpsList.filter(
                          (item) => item !== operation
                      )
                  })
            setAlertText(`Archived Inspection For Op: ${operation}`)
            setAlertSeverity("success")
            setAlertDialog(true)
        } catch (error) {
            setAlertSeverity("error")
            setAlertText(error)
            setAlertDialog(true)
        }
    }

    //Closes the archive dialog
    const handleArchiveClose = () => {
        if (!archiveLoading) {
            setArchiveDialog(false)
            setArchiveLoading(false)
            setArchiveText("")
        }
    }

    //Opens the update routing dialog
    //Different text for primary and alternate
    const handleUpdateRoutingOpen = async () => {
        setUpdateRoutingDialog(true)
        if (primary) {
            setUpdateRoutingText(
                `Would you like to reset the routing for ${items.itm} ${
                    revisions.rev
                } ${primary ? "primary" : "alternate"}? 
                WARNING: All operations that are not on the new routing will be archived and removed from the inspection.`
            )
        } else {
            if (manufacturingOrders.length > 0) {
                setUpdateRoutingText(
                    `Please select a MO to update the routing for ${
                        items.itm
                    } ${revisions.rev} ${primary ? "primary" : "alternate"}. 
                    WARNING: All operations that are not on the new routing will be archived and removed from the inspection.`
                )
            } else {
                setUpdateRoutingText(
                    `There are no MOs for ${items.itm} ${revisions.rev} ${
                        primary ? "primary" : "alternate"
                    }.
                    Would you like to use the primary routing?
                    WARNING: All operations that are not on the new routing will be archived and removed from the inspection.`
                )
            }
        }
    }

    //updates the routing for the selected Item
    //For primary, stores all current ops from operations.operationsList. Alternate from moOps.moOpsList
    //Then creates all new ops that do not already exists and archives all old ops that no longer exist
    const updateRoutingClick = async () => {
        if (!primary && manufacturingOrders.length > 0) {
            if (
                manufacturingOrder === "" ||
                manufacturingOrder === undefined ||
                manufacturingOrder === null
            ) {
                setAlertText("Please Select a Manufacturing Order")
                setAlertSeverity("error")
                setAlertDialog(true)
                return
            }
        }
        const prevOp = operations.op
        setOperations({
            ...operations,
            op: ""
        })
        setMoOps({
            ...moOps,
            moOp: ""
        })
        setViewOps(false)
        setUpdateRoutingText("Fetching New Routing")
        setUpdateRoutingPercent(5)
        const newOps = primary
            ? rtgoprOps
            : manufacturingOrders.length > 0
            ? manufacturingOrderOps
            : rtgoprOps
        const oldOps = inspections.filter(
            (item) =>
                item.ItemId === items.itemID && item.PrimaryFlag === primary
        )
        setUpdateRoutingText("Archiving Old Operations")
        setUpdateRoutingPercent(10)
        const archiveIncrement = 45 / oldOps.length
        const newOperations = newOps.map((item) => item.OPSEQ)
        for (let i = 0; i < oldOps.length; i++) {
            if (!newOperations.includes(oldOps[i].Operation)) {
                //old op does not exist so delete it
                await dispatch(
                    archiveInspection({
                        ItemId: oldOps[i].ItemId,
                        Operation: oldOps[i]?.Operation,
                        Workcenter: oldOps[i]?.Workcenter,
                        PrimaryFlag: oldOps[i].PrimaryFlag,
                        ArchiveFlag: true,
                        PPAPFlag: oldOps[i].PPAPFlag,
                        InspectionId: oldOps[i].InspectionId,
                        VerifiedBy: oldOps[i].VerifiedByOID,
                        SecondVerifiedBy: oldOps[i].SecondVerifiedByOID,
                        ManufacturingOrder: oldOps[i].ManufacturingOrder,
                        AuditLog: {
                            Action: "Archive Inspection",
                            OldValue: oldOps[i].InspectionId,
                            NewValue: "Archived",
                            AssociateOID: auth.userData.AssociateOID
                        }
                    })
                )
                primary
                    ? setOperations({
                          op: "",
                          operationsList: operations.operationsList.filter(
                              (item) => item !== oldOps[i].Operation
                          )
                      })
                    : setMoOps({
                          moOp: "",
                          moOpsList: moOps.moOpsList.filter(
                              (item) => item !== oldOps[i].Operation
                          )
                      })
            } else if (
                oldOps[i]?.Workcenter !==
                    newOps.find((item) => item.OPSEQ === oldOps[i].Operation)
                        ?.WKCTR ||
                oldOps[i]?.Workcenter?.trim() === "" ||
                oldOps[i]?.Workcenter === null
            ) {
                const toUpdate = {
                    ItemId: oldOps[i]?.ItemId,
                    Operation: oldOps[i]?.Operation,
                    Workcenter: newOps.find(
                        (item) => item.OPSEQ === oldOps[i].Operation
                    )?.WKCTR,
                    PrimaryFlag: oldOps[i]?.PrimaryFlag,
                    ArchiveFlag: false,
                    PPAPFlag: oldOps[i]?.PPAPFlag,
                    InspectionId: oldOps[i]?.InspectionId,
                    VerifiedBy: oldOps[i]?.VerifiedByOID,
                    VerifiedDate: oldOps[i]?.VerifiedDate,
                    SecondVerifiedBy: oldOps[i]?.SecondVerifiedByOID,
                    SecondVerifiedDate: oldOps[i]?.SecondVerifiedDate,
                    ManufacturingOrder: oldOps[i]?.ManufacturingOrder,
                    Material: oldOps[i]?.Material,
                    Certification: oldOps[i]?.Certification,
                    AuditLog: {
                        Action: "Update Inspection Routing Reset",
                        OldValue: oldOps[i]?.OPSEQ,
                        NewValue: newOps[i]?.WKCTR,
                        AssociateOID: auth.userData.AssociateOID
                    }
                }

                await dispatch(updateInspection(toUpdate))
            }
            setUpdateRoutingPercent((prev) => prev + archiveIncrement)
        }

        setUpdateRoutingText("Creating New Operations")
        const createIncrement = 45 / newOps.length
        for (let i = 0; i < newOps.length; i++) {
            if (
                !oldOps.map((item) => item.Operation).includes(newOps[i]?.OPSEQ)
            ) {
                //new op does not exist so create it
                await dispatch(
                    createInspection({
                        ItemId: items.itemID,
                        Operation: newOps[i]?.OPSEQ,
                        Workcenter: newOps[i]?.WKCTR,
                        PrimaryFlag: primary,
                        ArchiveFlag: false,
                        PPAPFlag: false,
                        AuditLog: {
                            Action: "Create Inspection",
                            OldValue: "Create From Routing",
                            NewValue: `${items.itemID} - ${newOps[i]?.OPSEQ}`,
                            AssociateOID: auth.userData.AssociateOID
                        }
                    })
                )
                primary
                    ? setOperations({
                          op: prevOp,
                          operationsList: operations.operationsList
                              .concat(newOps[i]?.OPSEQ.trim())
                              .sort()
                      })
                    : setMoOps({
                          moOp: prevOp,
                          moOpsList: moOps.moOpsList
                              .concat(newOps[i]?.OPSEQ.trim())
                              .sort()
                      })
            }
            setUpdateRoutingPercent((prev) => prev + createIncrement)
        }
        await dispatch(getInspections())
        setUpdateRoutingDialog(false)
        setAlertText("Routing Updated")
        setAlertSeverity("success")
        setAlertDialog(true)
        setUpdateRoutingPercent(0)
        setViewOps(true)
    }

    //close update routing dialog
    const handleUpdateRoutingClose = () => {
        if (updateRoutingPercent === 0) {
            setUpdateRoutingDialog(false)
            setUpdateRoutingText("")
            setUpdateRoutingPercent(0)
        }
    }

    //opens verification dialog for first Verification
    const handleVerifyOpen = async () => {
        // const res = await axios.get(
        //     `${backendURL}/inspections/${inspectionId?.replace("/", "%2F")}`
        // )
        // if (res.data[0]?.ChangeBy === auth.userData.AssociateOID) {
        //     if (!verified) {
        //         setAlertSeverity("error")
        //         setAlertText(
        //             "You cannot verify an inspection you have recently made changes to."
        //         )
        //         setAlertDialog(true)
        //         return
        //     }
        // }
        if (!permissions.EditFirstVerification) {
            setAlertText("You do not have permission to verify this inspection")
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        }
        if (!verified) {
            if (characteristic.characteristics.length === 0) {
                setAlertSeverity("error")
                setAlertText(
                    "At least one characteristic must be added to the inspection before it can be verified."
                )
                setAlertDialog(true)
                return
            }

            for (let i = 0; i < characteristic.characteristics.length; i++) {
                const missingFields = []

                if (
                    !characteristic.characteristics[i]?.Characteristic ||
                    !characteristic.characteristics[i]?.Characteristic.trim() ||
                    characteristic.characteristics[i]?.Characteristic ===
                        null ||
                    characteristic.characteristics[i]?.Characteristic.trim() ===
                        ""
                ) {
                    missingFields.push("the characteristic")
                }

                if (
                    characteristic.characteristics[i]?.InspectionMethodId ===
                    null
                ) {
                    missingFields.push("the inspection method")
                }

                if (
                    characteristic.characteristics[i]?.ControlMethodId === null
                ) {
                    missingFields.push("the control method")
                }

                if (characteristic.characteristics[i]?.FrequencyId === null) {
                    missingFields.push("the frequency")
                }

                if (missingFields.length > 0) {
                    let missingFieldsText = ""
                    if (missingFields.length === 1) {
                        missingFieldsText = missingFields[0]
                    } else if (missingFields.length === 2) {
                        missingFieldsText = missingFields.join(" and ")
                    } else {
                        missingFieldsText = `${missingFields
                            .slice(0, -1)
                            .join(", ")}, and ${
                            missingFields[missingFields.length - 1]
                        }`
                    }

                    setAlertSeverity("error")
                    setAlertText(
                        `Characteristic ${
                            i + 1
                        } is missing ${missingFieldsText}.`
                    )
                    setAlertDialog(true)
                    return
                }
            }
        }
        setVerificationDialog(true)
        !verified
            ? setVerificationText(
                  `Are you sure you want to add the First Verification to the inspection?`
              )
            : setVerificationText(
                  `Are you sure you want to remove the First Verification from the inspection?${
                      secondVerified
                          ? "\nSecond Verification Will Also Be Removed."
                          : ""
                  }`
              )
    }

    const handleSecondVerifyOpen = async () => {
        if (ppap) {
            if (!permissions.EditPPAP) {
                setAlertText(
                    "You do not have permission to change the verification of PPAP/ Frozen Proccess Inspections"
                )
                setAlertSeverity("error")
                setAlertDialog(true)
                return
            }
        }
        // const res = await axios.get(
        //     `${backendURL}/inspections/${inspectionId?.replace("/", "%2F")}`
        // )
        // if (res.data[0]?.ChangeBy === auth.userData.AssociateOID) {
        //     if (!secondVerified) {
        //         setAlertSeverity("error")
        //         setAlertText(
        //             "You cannot verify an inspection you have recently made changes to."
        //         )
        //         setAlertDialog(true)
        //         return
        //     }
        // }
        if (!permissions.EditSecondVerification) {
            setAlertText("You do not have permission to verify this inspection")
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        }
        if (!verified) {
            setAlertSeverity("error")
            setAlertText(
                "You must verify the inspection before you can second verify it."
            )
            setAlertDialog(true)
            return
        }
        if (!secondVerified) {
            if (verifiedByOID === auth.userData.AssociateOID) {
                setAlertSeverity("error")
                setAlertText(
                    "You cannot second verify an inspection you have first verified."
                )
                setAlertDialog(true)
                return
            }
            for (let i = 0; i < characteristic.characteristics.length; i++) {
                if (
                    characteristic.characteristics[i]?.Characteristic === "" ||
                    characteristic.characteristics[i]?.Characteristic ===
                        null ||
                    characteristic.characteristics[i]?.InspectionMethodId ===
                        null ||
                    characteristic.characteristics[i]?.ControlMethodId ===
                        null ||
                    characteristic.characteristics[i]?.FrequencyId === null
                ) {
                    setAlertSeverity("error")
                    setAlertText(
                        `You must fill out all required fields before you can second verify the inspection. \n Please unverify the inspection and fill out all required fields.`
                    )
                    setAlertDialog(true)
                    return
                }
            }
            setSecondVerificationDialog(true)
            setSecondVerificationText(
                `Are you sure you want to add the Second Verification to the inspection?`
            )
        } else {
            setSecondVerificationDialog(true)
            setSecondVerificationText(
                `Are you sure you want to remove the Second Verification from the inspection?`
            )
        }
    }

    //closes verification dialog
    const handleVerifyClose = () => {
        setVerificationDialog(false)
        setVerificationText("")
    }

    const handleSecondVerifyClose = () => {
        setSecondVerificationDialog(false)
        setSecondVerificationText("")
    }

    const handleOpenManufacturingOrderDialog = () => {
        if (manufacturingOrders.length === 0) {
            setAlertSeverity("error")
            setAlertText("No Manufacturing Orders Found")
            setAlertDialog(true)
            return
        }
        setManufacturingOrderDialog(true)
        setManufacturingOrderText("Select a Manufacturing Order")
    }

    const handleUpdateManufacturingOrder = async () => {
        if (manufacturingOrder === "" || manufacturingOrder === null) {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Please select a manufacturing order")
            return
        } else if (manufacturingOrder === mo) {
            setManufacturingOrderDialog(false)
            setManufacturingOrderText("")
            setManufacturingOrder("")
            return
        }
        try {
            const toUpdate = {
                InspectionId: inspectionId,
                ManufacturingOrder: manufacturingOrder,
                AuditLog: {
                    Action: "Update Manufacturing Order",
                    OldValue: mo,
                    NewValue: manufacturingOrder,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updateInspection(toUpdate))
            setMo(manufacturingOrder)
        } catch (error) {
            console.log(error)
        }
        setManufacturingOrderDialog(false)
        setManufacturingOrderText("")
        setManufacturingOrder("")
        await dispatch(getInspections())
    }

    const handleCloseManufacturingOrderDialog = () => {
        setManufacturingOrderDialog(false)
        setManufacturingOrderText("")
    }

    const handleCharacteristicDelete = async () => {
        const item = characteristic.toDelete
        await dispatch(
            deleteCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                AuditLog: {
                    Action: "Delete Characteristic",
                    OldValue: item.Characteristic,
                    NewValue: "",
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        dispatch(setCharacteristicDialog(false))
        dispatch(setToDelete({}))
        await dispatch(getCharacteristics(inspectionId))
    }

    const handleCharacteristicClose = () => {
        dispatch(setCharacteristicDialog(false))
        dispatch(setToDelete({}))
    }

    const printOnClick = () => {
        if (!verified) {
            setAlertSeverity("error")
            setAlertText(
                "You must verify the inspection before you can print it."
            )
            setAlertDialog(true)
            return
        }
        if (!secondVerified) {
            setAlertSeverity("error")
            setAlertText(
                "You must second verify the inspection before you can print it."
            )
            setAlertDialog(true)
            return
        }
        if (
            !facmst.filter(
                (item) => item.WKCTR.trim() === workcenter.wkctr.trim()
            )[0]?.DPTNO
        ) {
            setAlertSeverity("error")
            setAlertText(
                "The Inspection selected does not have a workcenter. Please update the routing."
            )
            setAlertDialog(true)
            return
        }
        for (let i = 0; i < characteristic.characteristics.length; i++) {
            if (
                characteristic.characteristics[i]?.Characteristic === "" ||
                characteristic.characteristics[i]?.Characteristic === null ||
                characteristic.characteristics[i]?.InspectionMethodId ===
                    null ||
                characteristic.characteristics[i]?.ControlMethodId === null ||
                characteristic.characteristics[i]?.FrequencyId === null
            ) {
                setAlertSeverity("error")
                setAlertText(
                    "You must fill out all required fields before you can print the inspection."
                )
                setAlertDialog(true)
                return
            }
        }
        if (characteristic.characteristics.length === 0) {
            setAlertSeverity("error")
            setAlertText(
                "At least one characteristic must be added to the inspection before it can be printed."
            )
            setAlertDialog(true)
            return
        }
        const dep = `${
            facmst.filter(
                (item) => item.WKCTR.trim() === workcenter.wkctr.trim()
            )[0]?.DPTNO
        }`
        dispatch(
            setOutput({
                partNumber: items.itm,
                partName: items.itmName,
                partRevision: revisions.rev,
                operation: primary ? operations.op : moOps.moOp,
                manufacturingOrder: mo,
                customer: mo
                    ? manufacturingOrders.filter(
                          (item) => item.ORDNO.trim() === mo.trim()
                      )[0]?.REFNO
                    : "",
                department: dep,
                machine: workcenter.wkctr,
                secondVerified: secondVerified,
                secondVerifiedBy: secondVerifiedBy,
                ppap: ppap,
                material: material,
                certification: certification,
                numCells: gearDepartmentNumbers.includes(dep)
                    ? ppap
                        ? 4
                        : 5
                    : ppap
                    ? 5
                    : 6,
                characteristics: characteristic.characteristics.map((item) => ({
                    Characteristic: item.Characteristic,
                    CharacteristicId: item.CharacteristicId,
                    InspectionId: item.InspectionId,
                    InspectionMethod: maintenance.inspectionMethods.find(
                        (inspectionMethod) =>
                            inspectionMethod.InspectionMethodId ===
                            item.InspectionMethodId
                    )?.InspectionMethod,
                    Frequency: maintenance.frequencies.find(
                        (frequency) =>
                            frequency.FrequencyId === item.FrequencyId
                    )?.Frequency,
                    ControlMethod: maintenance.controlMethods.find(
                        (controlMethod) =>
                            controlMethod.ControlMethodId ===
                            item.ControlMethodId
                    )?.ControlMethod,
                    Tool: item.Tool,
                    Reaction: item.Reaction,
                    Location: item.Location,
                    Gage: item.Gage,
                    Key: item.Key,
                    SequenceNumber: item.SequenceNumber
                }))
            })
        )
        let params = `personalbar=no,scrollbars=no,resizable=no,statusbar=no,locationbar=no,toolbar=no,menubar=no,
            width=1242,height=530`
        window
            .open(
                "/#/output",
                `${items.itm} ${revisions.rev} ${
                    primary ? "primary" : "alternate"
                } ${primary ? operations.op : moOps.moOp} ${
                    ppap ? "Control Plan" : "In Process"
                } ${secondVerified ? "Second Verified" : ""}`,
                params
            )
            .print()
    }

    const handleCloneOpen = () => {
        setLockToFrom(false)
        setBlankReport(false)
        setCloneDialog(true)
        if (cloneList?.length <= 0) {
            setCloneList(
                inspections.map((item) => {
                    const itm = itmprc.find(
                        (itmp) => item?.ItemId === itmp?.ITMPRCID
                    )
                    return {
                        itemNumber: itm?.ITNBR,
                        revision: itm?.ITRV,
                        operation: item?.Operation,
                        primary: item?.PrimaryFlag
                    }
                })
            )
        }
    }

    const handleCloneClose = () => {
        setCloneDialog(false)
        setCloneConfirm(false)
        setClonePrimary(true)
        setCloneItem("")
        setCloneRevision("")
        setCloneOperation("")
        setToFrom("to")
    }

    const handleClone = async () => {
        if (toFrom === "") {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Please select a clone type")
            return
        }
        const first = inspections.find(
            (item) =>
                item.ItemId ===
                    `PRD:::${cloneItem.trim()}:::${cloneRevision.trim()}` &&
                item.PrimaryFlag === clonePrimary &&
                item.Operation === cloneOperation.trim()
        )
        const second = inspections.find(
            (item) => item.InspectionId === inspectionId
        )
        const audit = {
            Action: "Clone Inspection",
            OldValue: "",
            NewValue: "",
            AssociateOID: auth.userData.AssociateOID
        }
        if (toFrom === "to") {
            if (first?.VerifiedByOID) {
                setAlertSeverity("error")
                setAlertDialog(true)
                setAlertText("Cannot clone to a verified inspection")
                return
            }
            await dispatch(cloneCharacteristic(first, second, audit))
        } else {
            if (second?.VerifiedByOID) {
                setAlertSeverity("error")
                setAlertDialog(true)
                setAlertText("Cannot clone to a verified inspection")
                return
            }
            await dispatch(cloneCharacteristic(second, first, audit))
        }
        await dispatch(getCharacteristics(inspectionId))
        setCloneDialog(false)
        setCloneConfirm(false)
        setClonePrimary(true)
        setCloneItem("")
        setCloneRevision("")
        setCloneOperation("")
        setToFrom("to")
        setAlertText("Characteristics cloned successfully")
        setAlertSeverity("success")
        setAlertDialog(true)
    }

    const handleToFromOnChange = (e) => {
        setToFrom(e.target.value)
    }

    const clonePrimaryOnChange = (e) => {
        setClonePrimary(!clonePrimary)
    }

    const handleCloneItemOnChange = (e, option) => {
        setCloneItem(option)
    }

    const handleCloneRevisionOnChange = (e, option) => {
        setCloneRevision(option)
    }

    const handleCloneOperationOnChange = (e, option) => {
        setCloneOperation(option)
    }

    const handleCloneOnConfirm = async () => {
        if (cloneItem === "") {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Please select an item")
            return
        }
        if (cloneRevision === "") {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Please select a revision")
            return
        }
        if (cloneOperation === "") {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Please select an operation")
            return
        }
        if (
            !inspections
                .map((item) => item.ItemId)
                .includes(`PRD:::${cloneItem.trim()}:::${cloneRevision.trim()}`)
        ) {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Inspection for this item does not exist")
            return
        }
        if (
            cloneItem.trim() === items.itm.trim() &&
            cloneRevision.trim() === revisions.rev.trim() &&
            clonePrimary === primary &&
            cloneOperation.trim() ===
                (primary ? operations.op.trim() : moOps.moOp.trim())
        ) {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("Cannot clone to the same inspection")
            return
        }
        setCloneConfirm(true)
    }

    const handleSendGageEmail = async () => {
        if (sendingEmail) {
            alertSeverity("error")
            setAlertDialog(true)
            setAlertText("Email is currently being sent")
            return
        }
        setSendingEmail(true)
        let email = `<!DOCTYPE html>
        <html>
        <head>
        </head>
        <body>
        <h4>These are the gages that are needed for operation ${
            primary ? operations.op.trim() : moOps.moOp.trim()
        } on item ${items.itm}, Rev. ${revisions.rev}</h4>
        <p>
        <table>
            <tr>
                <td style="font-family:ims;border-bottom: 1px solid orange;border-right: 1px solid orange">Characteristic </td>
                <td style="font-family:ims;border-bottom: 1px solid orange">Method </td>
            </tr>`
        let gageCounter = 0
        for (let i = 0; i < characteristic.characteristics.length; i++) {
            if (characteristic.characteristics[i]?.Gage === true) {
                const inspectionMethod = maintenance.inspectionMethods.filter(
                    (item) =>
                        item.InspectionMethodId ===
                        characteristic.characteristics[i]?.InspectionMethodId
                )[0].InspectionMethod
                gageCounter++
                email += `<tr>
                <td style="font-family:ims;border-right: 1px solid orange;padding: 5px">${characteristic.characteristics[i].Characteristic}</td>
                <td style="font-family:ims;padding: 5px">${inspectionMethod}</td>
            </tr>`
            }
        }

        email += `</table>
        </p>
        <br>
            Sent By ${auth.user.displayName}
        </body>
        </html>`

        if (gageCounter === 0) {
            setAlertSeverity("error")
            setAlertDialog(true)
            setAlertText("No gages are selected for this inspection")
            setSendingEmail(false)
            return
        }

        const emailInfoObject = {
            emailList: EMAIL_RECIPIENTS,
            emailSubject: `Gages List for ${items.itm} - ${
                revisions.rev
            }, Operation ${primary ? operations.op : moOps.moOp}`,
            emailContent: email
        }
        try {
            await sendEmail(emailInfoObject)
            setSendingEmail(false)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("Email sent successfully")
        } catch (error) {
            setSendingEmail(false)
            console.log(error)
        }
    }

    const handleOpenMaterialDialog = () => {
        setMaterialDialog(true)
    }

    const handleCloseMaterialDialog = () => {
        setNewMaterial("")
        setMaterialDialog(false)
    }

    const handleMaterialOnChange = (e) => {
        setNewMaterial(e.target.value)
    }

    const handleUpdateMaterial = async (e) => {
        try {
            const toUpdate = {
                InspectionId: inspectionId,
                Material: newMaterial,
                AuditLog: {
                    Action: "Update Material",
                    OldValue: material,
                    NewValue: newMaterial,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updateInspection(toUpdate))
            setMaterial(newMaterial)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("Inspection updated successfully")
            setMaterialDialog(false)
            setNewMaterial("")
        } catch (error) {
            console.log(error)
        }
        setNewMaterial("")
        setMaterialDialog(false)
        await dispatch(getInspections())
    }

    const handleOpenCertificationDialog = () => {
        setCertificationDialog(true)
    }

    const handleCloseCertificationDialog = () => {
        setCertificationDialog(false)
        setNewCertification("")
    }

    const handleCertificationOnChange = (e) => {
        setNewCertification(e.target.value)
    }

    const handleUpdateCertification = async (e) => {
        try {
            const toUpdate = {
                InspectionId: inspectionId,
                Certification: newCertification,
                AuditLog: {
                    Action: "Update Certification",
                    OldValue: certification,
                    NewValue: newCertification,
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(updateInspection(toUpdate))
            setCertification(newCertification)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("Inspection updated successfully")
            setCertificationDialog(false)
            setNewCertification("")
        } catch (error) {
            console.log(error)
        }
        setCertificationDialog(false)
        await dispatch(getInspections())
        setNewCertification("")
    }

    function getEmails(arr, userEmail) {
        if (!Array.isArray(arr)) {
            console.error(
                "Invalid input: expected an array, but received:",
                arr
            )
            return
        }

        let emails = arr?.map((item) => item.BusinessEmail)

        // Check if userEmail already exists in the array
        if (!emails?.includes(userEmail)) {
            emails?.push(userEmail)
        }
        const setEmails = [...new Set(emails)]
        return setEmails.join(",")
    }

    const handleSendFirstVerificationEmail = async () => {
        if (sendingFirstVerificationEmail) {
            alertSeverity("error")
            setAlertDialog(true)
            setAlertText("Email is currently being sent")
            return
        }

        setSendingFirstVerificationEmail(true)
        let email = `<!DOCTYPE html>
        <html>
        <head>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Century+Gothic:wght@400;700&display=swap');
        
            body {
              font-family: 'Century Gothic', sans-serif;
              color: #333333;
            }
          </style>
        </head>
        <body>
          <div class="email-content">
            <p>Hello Team,</p>
            <p>We need your help with the first verification for Item ${
                items.itm
            }, Revision ${revisions.rev}, Operation ${
            primary ? operations.op.trim() : moOps.moOp.trim()
        } - ${primary ? "Primary" : "Alternate"}.</p>
            <p>Thanks for your attention to this matter!</p>
            <p>Best,</p>
            <p>${auth.user.displayName} (${auth.user.email})</p>
          </div>
        </body>
        </html>`
        const emails = ppap
            ? getEmails(
                  [...new Set(firstVerificationEmails.concat(ppapEmails))],
                  auth.user.email
              )
            : getEmails(firstVerificationEmails, auth.user.email)
        const emailInfoObject = {
            emailList: emails,
            emailSubject: `First Verification Request for Item ${
                items.itm
            }, Rev. ${revisions.rev} Operation ${
                primary ? operations.op.trim() : moOps.moOp.trim()
            } ${primary ? "Primary" : "Alternate"}`,
            emailContent: email
        }
        try {
            await sendEmail(emailInfoObject)
            setSendingFirstVerificationEmail(false)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("First Verification request sent successfully")
        } catch (error) {
            setSendingFirstVerificationEmail(false)
            console.log(error)
        }
    }

    const handleSendSecondVerificationEmail = async () => {
        if (sendingSecondVerificationEmail) {
            alertSeverity("error")
            setAlertDialog(true)
            setAlertText("Email is currently being sent")
            return
        }

        setSendingSecondVerificationEmail(true)
        let email = `<!DOCTYPE html>
        <html>
        <head>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Century+Gothic:wght@400;700&display=swap');
        
            body {
              font-family: 'Century Gothic', sans-serif;
              color: #333333;
            }
          </style>
        </head>
        <body>
          <div class="email-content">
            <p>Hello Team,</p>
            <p>We need your assistance with the second verification for Item ${
                items.itm
            }, Revision ${revisions.rev}, Operation ${
            primary ? operations.op.trim() : moOps.moOp.trim()
        } - ${primary ? "Primary" : "Alternate"}.</p>
            <p>We appreciate your continued support!</p>
            <p>Best,</p>
            <p>${auth.user.displayName} (${auth.user.email})</p>
          </div>
        </body>
        </html>`
        const emails = getEmails(
            ppap ? ppapEmails : secondVerificationEmails,
            auth.user.email
        )
        const emailInfoObject = {
            emailList: emails,
            emailSubject: `Second Verification Request for Item ${
                items.itm
            }, Rev. ${revisions.rev} Operation ${
                primary ? operations.op.trim() : moOps.moOp.trim()
            } ${primary ? "Primary" : "Alternate"}`,
            emailContent: email
        }
        try {
            await sendEmail(emailInfoObject)
            setSendingSecondVerificationEmail(false)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("Second Verification request sent successfully")
        } catch (error) {
            setSendingSecondVerificationEmail(false)
            console.log(error)
        }
    }

    const handleSendPPAPEmail = async () => {
        if (sendingPpapEmail) {
            alertSeverity("error")
            setAlertDialog(true)
            setAlertText("Email is currently being sent")
            return
        }
        setSendingPpapEmail(true)
        let email = `<!DOCTYPE html>
        <html>
        <head>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Century+Gothic:wght@400;700&display=swap');
        
            body {
              font-family: 'Century Gothic', sans-serif;
              color: #333333;
            }
          </style>
        </head>
        <body>
          <div class="email-content">
            <p>Hello Team,</p>
            <p>We'd like to request a change to the PPAP for Item ${
                items.itm
            }, Revision ${revisions.rev}, Operation ${
            primary ? operations.op.trim() : moOps.moOp.trim()
        } - ${primary ? "Primary" : "Alternate"}.</p>
            <p>Thank you for your prompt attention to this matter.</p>
            <p>Best,</p>
            <p>${auth.user.displayName} (${auth.user.email})</p>
          </div>
        </body>
        </html>`
        const emails = getEmails(ppapEmails, auth.user.email)
        const emailInfoObject = {
            emailList: emails,
            emailSubject: `Change PPAP Request for Item ${items.itm}, Rev. ${
                revisions.rev
            } Operation ${primary ? operations.op.trim() : moOps.moOp.trim()} ${
                primary ? "Primary" : "Alternate"
            }`,
            emailContent: email
        }
        try {
            await sendEmail(emailInfoObject)
            setSendingPpapEmail(false)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("PPAP request sent successfully")
        } catch (error) {
            setSendingPpapEmail(false)
            console.log(error)
        }
    }

    const handleSendResetRoutingEmail = async () => {
        if (sendingResetRoutingEmail) {
            alertSeverity("error")
            setAlertDialog(true)
            setAlertText("Email is currently being sent")
            return
        }
        setSendingResetRoutingEmail(true)
        let email = `<!DOCTYPE html>
        <html>
        <head>
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Century+Gothic:wght@400;700&display=swap');
        
            body {
              font-family: 'Century Gothic', sans-serif;
              color: #333333;
            }
          </style>
        </head>
        <body>
          <div class="email-content">
            <p>Hello Team,</p>
            <p>We'd like to request a reset of the routing for Item ${items.itm}, Revision ${revisions.rev}.</p>
            <p>Could you please attend to this as soon as possible?</p>
            <p>Best,</p>
            <p>${auth.user.displayName} (${auth.user.email})</p>
          </div>
        </body>
        </html>`
        const emails = getEmails(updateRoutingEmails, auth.user.email)
        const emailInfoObject = {
            emailList: emails.toLowerCase(),
            emailSubject: `Reset Routing Request for Item ${items.itm}, Rev. ${revisions.rev}`,
            emailContent: email
        }
        try {
            await sendEmail(emailInfoObject)
            setSendingResetRoutingEmail(false)
            setAlertSeverity("success")
            setAlertDialog(true)
            setAlertText("Reset Routing request sent successfully")
        } catch (error) {
            setSendingResetRoutingEmail(false)
            console.log(error)
        }
    }

    const PPAPError = () => {
        setAlertSeverity("error")
        setAlertDialog(true)
        setAlertText(
            "You Do Not Have Permissions To Change PPAP/Frozen Process"
        )
        return
    }

    const handleOpenTicketDialog = () => {
        setOpenTicketDialog(true)
    }

    const handleCloseTicketDialog = () => {
        setOpenTicketDialog(false)
    }

    return (
        <>
            {auth.authenticated ? (
                <>
                    {loading ? (
                        <LoadingScreen />
                    ) : (
                        <Grid
                            container
                            className={classes.MainGrid}
                            justifyContent="space-evenly"
                            alignItems="stretch"
                            elevation={3}
                        >
                            <Grid
                                item
                                container
                                xs={12}
                                md={8}
                                className={classes.LeftGrid}
                            >
                                {/*main control paper. Item selector Revision selector Primary switch and get ops button*/}
                                <Grid item xs={12}>
                                    <Paper className={classes.RowPaper}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    maxOptions={20}
                                                    autoComplete
                                                    id="item-selector"
                                                    className={
                                                        classes.ItemAutoComplete
                                                    }
                                                    value={items.itm}
                                                    options={[
                                                        ...new Set(
                                                            items.itemsList.map(
                                                                (item) =>
                                                                    item.ITNBR?.trim()
                                                            )
                                                        )
                                                    ].sort()}
                                                    onInputChange={
                                                        handleItemInputChange
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    noOptionsText="No items found"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Item Number"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={
                                                                classes.ItemSelector
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Autocomplete
                                                    id="revision-selector"
                                                    className={
                                                        classes.RevisionAutoComplete
                                                    }
                                                    options={
                                                        revisions.revisionsList
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    onInputChange={(
                                                        event,
                                                        option
                                                    ) =>
                                                        handleRevisionInputChange(
                                                            event,
                                                            option
                                                        )
                                                    }
                                                    renderOption={(
                                                        props,
                                                        option,
                                                        index
                                                    ) =>
                                                        currentRevision ? (
                                                            option ===
                                                            currentRevision[0]
                                                                ?.ITRVT9 ? (
                                                                <li {...props}>
                                                                    {option}

                                                                    <CircleIcon
                                                                        className={
                                                                            classes.CurrentRevision
                                                                        }
                                                                        color="success"
                                                                    />
                                                                </li>
                                                            ) : (
                                                                <li {...props}>
                                                                    {option}
                                                                </li>
                                                            )
                                                        ) : index === 0 ? (
                                                            <li {...props}>
                                                                {option}
                                                                <span>
                                                                    <CircleIcon
                                                                        className={
                                                                            classes.CurrentRevision
                                                                        }
                                                                        color="success"
                                                                    />
                                                                </span>
                                                            </li>
                                                        ) : (
                                                            <li {...props}>
                                                                {option}
                                                            </li>
                                                        )
                                                    }
                                                    value={revisions.rev}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Rev"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={
                                                                classes.RevisionSelector
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={
                                                                primaryOnChange
                                                            }
                                                            defaultChecked
                                                            onClick={
                                                                handlePrimaryOnClick
                                                            }
                                                            checked={primary}
                                                        />
                                                    }
                                                    label={
                                                        primary
                                                            ? "Primary"
                                                            : "Alternate"
                                                    }
                                                    className={
                                                        classes.PrimarySelector
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                xs={3}
                                                justifyContent="center"
                                            >
                                                {permissions.UpdateRouting ? (
                                                    <Button
                                                        disabled={
                                                            items.itemID ===
                                                                "" ||
                                                            revisions.rev === ""
                                                        }
                                                        onClick={
                                                            handleUpdateRoutingOpen
                                                        }
                                                        className={
                                                            classes.CloneButton
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <SyncProblemRoundedIcon />
                                                        }
                                                    >
                                                        Reset Routing
                                                    </Button>
                                                ) : (
                                                    <Zoom
                                                        in={
                                                            items.itm &&
                                                            revisions.rev
                                                        }
                                                    >
                                                        <Button
                                                            disabled={
                                                                sendingResetRoutingEmail
                                                            }
                                                            onClick={
                                                                handleSendResetRoutingEmail
                                                            }
                                                            className={
                                                                classes.CloneButton
                                                            }
                                                            variant="outlined"
                                                            startIcon={
                                                                <SendRoundedIcon />
                                                            }
                                                        >
                                                            {sendingResetRoutingEmail && (
                                                                <CircularProgress
                                                                    size={24}
                                                                    className={
                                                                        classes.buttonProgress
                                                                    }
                                                                />
                                                            )}
                                                            Request Reset
                                                        </Button>
                                                    </Zoom>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                {/*If operations are loaded, displays operation buttons, otherwise shows loading bar */}
                                <Grid item xs={12}>
                                    {!loadingOperations ? (
                                        <Zoom
                                            in={viewOps && !loadingOperations}
                                        >
                                            <Paper
                                                className={
                                                    classes.ButtonContainerPaper
                                                }
                                            >
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="baseline"
                                                >
                                                    {!primary && (
                                                        <Zoom in={!primary}>
                                                            <Grid item xs={1}>
                                                                {inspectionMemo.length >
                                                                    0 &&
                                                                    permissions.UpdateRouting && (
                                                                        <Tooltip
                                                                            title={
                                                                                <Typography variant="body2">
                                                                                    Add
                                                                                    Operation
                                                                                </Typography>
                                                                            }
                                                                            arrow
                                                                            placement="bottom"
                                                                        >
                                                                            <span>
                                                                                <IconButton
                                                                                    onClick={
                                                                                        handleCreateOperationOpen
                                                                                    }
                                                                                >
                                                                                    <AddCircleRoundedIcon />
                                                                                </IconButton>
                                                                            </span>
                                                                        </Tooltip>
                                                                    )}
                                                            </Grid>
                                                        </Zoom>
                                                    )}

                                                    <Grid
                                                        item
                                                        xs={primary ? 12 : 11}
                                                    >
                                                        <Paper
                                                            elevation={0}
                                                            className={
                                                                classes.ButtonPaper
                                                            }
                                                        >
                                                            <Grid
                                                                container
                                                                justifyContent="space-evenly"
                                                                alignItems="stretch"
                                                                wrap="nowrap"
                                                                className={
                                                                    classes.ButtonGrid
                                                                }
                                                            >
                                                                {inspectionOpMemo.map(
                                                                    (item) => (
                                                                        <Tooltip
                                                                            title={
                                                                                <div>
                                                                                    <Typography variant="body2">
                                                                                        {`Operation:${item.Operation}`}
                                                                                    </Typography>
                                                                                    <Typography variant="body2">
                                                                                        {`Workcenter:${item.Workcenter}`}
                                                                                    </Typography>
                                                                                </div>
                                                                            }
                                                                            arrow
                                                                            placement="bottom"
                                                                        >
                                                                            <span>
                                                                                <Button
                                                                                    key={
                                                                                        item.Operation
                                                                                    }
                                                                                    variant="contained"
                                                                                    className={
                                                                                        classes.Button
                                                                                    }
                                                                                    onClick={
                                                                                        handleOperationButtonClick
                                                                                    }
                                                                                    value={
                                                                                        item.Operation
                                                                                    }
                                                                                    disabled={
                                                                                        primary
                                                                                            ? operations.op ===
                                                                                              item.Operation
                                                                                            : moOps.moOp ===
                                                                                              item.Operation
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.Operation
                                                                                    }
                                                                                </Button>
                                                                            </span>
                                                                        </Tooltip>
                                                                    )
                                                                )}
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    ) : (
                                        <Paper
                                            elevation={10}
                                            className={
                                                classes.ButtonContainerPaper
                                            }
                                        >
                                            <Box
                                                sx={{
                                                    width: "100%"
                                                }}
                                            >
                                                <LinearProgress />
                                            </Box>
                                        </Paper>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Zoom
                                        in={
                                            items.itemID &&
                                            revisions.rev &&
                                            ((operations.op && primary) ||
                                                (moOps.moOp && !primary)) &&
                                            inspectionOpMemo.length > 0
                                        }
                                    >
                                        {/*Report Details Paper*/}
                                        <Grid
                                            container
                                            className={classes.Stack}
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="stretch"
                                        >
                                            <Grid item xs={6}>
                                                <Paper>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid
                                                            item
                                                            align="center"
                                                            xs={12}
                                                        >
                                                            <Typography variant="h5">
                                                                Report Details{" "}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="h6"
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            >
                                                                Operation:{" "}
                                                                {primary
                                                                    ? operations.op
                                                                    : moOps.moOp}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            align="right"
                                                            xs={6}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            >
                                                                MO: {mo}
                                                                {permissions.EditInspectionDetails && (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={
                                                                            <Typography variant="body2">
                                                                                Select
                                                                                MO
                                                                            </Typography>
                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <span>
                                                                            <IconButton
                                                                                onClick={
                                                                                    handleOpenManufacturingOrderDialog
                                                                                }
                                                                                className={
                                                                                    classes.IconButton
                                                                                }
                                                                            >
                                                                                <EditRoundedIcon
                                                                                    className={
                                                                                        classes.EditButton
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            wrap="nowrap"
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                                noWrap
                                                            >
                                                                Workcenter:{" "}
                                                                {
                                                                    workcenter.wkctr
                                                                }
                                                                {permissions.EditInspectionDetails && (
                                                                    <Zoom
                                                                        in={
                                                                            !primary
                                                                        }
                                                                    >
                                                                        <Tooltip
                                                                            arrow
                                                                            title={
                                                                                <Typography variant="body2">
                                                                                    Edit
                                                                                    Workcenter
                                                                                </Typography>
                                                                            }
                                                                            placement="right"
                                                                        >
                                                                            <span>
                                                                                <IconButton
                                                                                    onClick={
                                                                                        handleEditWorkcenterOpen
                                                                                    }
                                                                                    className={
                                                                                        classes.IconButton
                                                                                    }
                                                                                >
                                                                                    <EditRoundedIcon
                                                                                        className={
                                                                                            classes.EditButton
                                                                                        }
                                                                                    />
                                                                                </IconButton>
                                                                            </span>
                                                                        </Tooltip>
                                                                    </Zoom>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            align="left"
                                                            xs={6}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                noWrap
                                                                align="right"
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            >
                                                                Cert No:{" "}
                                                                {certification}
                                                                {permissions.EditInspectionDetails && (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={
                                                                            <Typography variant="body2">
                                                                                Edit
                                                                                Certification
                                                                                Number
                                                                            </Typography>
                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <span>
                                                                            <IconButton
                                                                                onClick={
                                                                                    handleOpenCertificationDialog
                                                                                }
                                                                                className={
                                                                                    classes.IconButton
                                                                                }
                                                                            >
                                                                                <EditRoundedIcon
                                                                                    className={
                                                                                        classes.EditButton
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            align="left"
                                                            xs={6}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            >
                                                                Customer:{" "}
                                                                {manufacturingOrders
                                                                    ? mo
                                                                        ? manufacturingOrders.filter(
                                                                              (
                                                                                  item
                                                                              ) =>
                                                                                  item.ORDNO.trim() ===
                                                                                  mo.trim()
                                                                          )[0]
                                                                              ?.REFNO
                                                                        : ""
                                                                    : ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            align="right"
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <Typography
                                                                variant="h6"
                                                                noWrap
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            >
                                                                Material:{" "}
                                                                {material}
                                                                {permissions.EditInspectionDetails && (
                                                                    <Tooltip
                                                                        arrow
                                                                        title={
                                                                            <Typography variant="body2">
                                                                                Edit
                                                                                Material
                                                                            </Typography>
                                                                        }
                                                                        placement="right"
                                                                    >
                                                                        <span>
                                                                            <IconButton
                                                                                onClick={
                                                                                    handleOpenMaterialDialog
                                                                                }
                                                                                className={
                                                                                    classes.IconButton
                                                                                }
                                                                            >
                                                                                <EditRoundedIcon
                                                                                    className={
                                                                                        classes.EditButton
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            wrap="nowrap"
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        onClick={
                                                                            permissions.EditPPAP
                                                                                ? PPAPOnChange
                                                                                : PPAPError
                                                                        }
                                                                        checked={
                                                                            ppap
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    ppap ? (
                                                                        <Typography
                                                                            className={
                                                                                classes.GridContent
                                                                            }
                                                                        >
                                                                            {`PPAP/Frozen Process`}
                                                                        </Typography>
                                                                    ) : (
                                                                        "Standard"
                                                                    )
                                                                }
                                                                className={
                                                                    classes.GridContent
                                                                }
                                                            />
                                                            {!permissions.EditPPAP && (
                                                                <Tooltip
                                                                    arrow
                                                                    title={
                                                                        <Typography variant="body2">
                                                                            Request
                                                                            PPAP
                                                                            Change
                                                                        </Typography>
                                                                    }
                                                                    placement="right"
                                                                    onClick={
                                                                        handleSendPPAPEmail
                                                                    }
                                                                >
                                                                    <span>
                                                                        <IconButton
                                                                            className={
                                                                                classes.IconButton
                                                                            }
                                                                            disabled={
                                                                                sendingPpapEmail
                                                                            }
                                                                        >
                                                                            {sendingPpapEmail && (
                                                                                <CircularProgress
                                                                                    size={
                                                                                        24
                                                                                    }
                                                                                    className={
                                                                                        classes.buttonProgress
                                                                                    }
                                                                                />
                                                                            )}
                                                                            <SendRoundedIcon />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            {/*Verification Paper*/}

                                            <Grid
                                                item
                                                xs={6}
                                                alignItems="stretch"
                                            >
                                                <Paper
                                                    className={
                                                        classes.VerificationPaper
                                                    }
                                                >
                                                    <Grid container column>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="center"
                                                            alignContent="stretch"
                                                        >
                                                            <Typography variant="h5">
                                                                Verification
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            alignItems="center"
                                                        >
                                                            <Grid item xs={10}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            onChange={
                                                                                handleVerifyOpen
                                                                            }
                                                                        />
                                                                    }
                                                                    checked={
                                                                        verified
                                                                    }
                                                                    label={
                                                                        <Typography
                                                                            className={
                                                                                classes.GridContent
                                                                            }
                                                                        >
                                                                            {verified
                                                                                ? `First Verified by ${verifiedBy} on ${moment(
                                                                                      verifiedDate
                                                                                  ).format(
                                                                                      "MM/DD/YYYY"
                                                                                  )} `
                                                                                : "No First Verification"}
                                                                        </Typography>
                                                                    }
                                                                    className={
                                                                        classes.VerificationSelector
                                                                    }
                                                                />
                                                            </Grid>
                                                            {verified ? null : (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <Tooltip
                                                                        arrow
                                                                        title={
                                                                            <Typography variant="body2">
                                                                                Request
                                                                                First
                                                                                Verification
                                                                            </Typography>
                                                                        }
                                                                        placement="bottom"
                                                                    >
                                                                        <span>
                                                                            <IconButton
                                                                                className={
                                                                                    classes.IconButton
                                                                                }
                                                                                onClick={
                                                                                    handleSendFirstVerificationEmail
                                                                                }
                                                                                disabled={
                                                                                    sendingFirstVerificationEmail
                                                                                }
                                                                            >
                                                                                <SendRoundedIcon />
                                                                                {sendingFirstVerificationEmail && (
                                                                                    <CircularProgress
                                                                                        size={
                                                                                            24
                                                                                        }
                                                                                        className={
                                                                                            classes.buttonProgress
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </span>
                                                                    </Tooltip>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={10}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Switch
                                                                            onChange={
                                                                                handleSecondVerifyOpen
                                                                            }
                                                                        />
                                                                    }
                                                                    checked={
                                                                        secondVerified
                                                                    }
                                                                    label={
                                                                        <Typography
                                                                            className={
                                                                                classes.GridContent
                                                                            }
                                                                        >
                                                                            {secondVerified
                                                                                ? `Second Verified by ${secondVerifiedBy} on ${moment(
                                                                                      secondVerifiedDate
                                                                                  ).format(
                                                                                      "MM/DD/YYYY"
                                                                                  )} `
                                                                                : "No Second Verification"}
                                                                        </Typography>
                                                                    }
                                                                    className={
                                                                        classes.VerificationSelector
                                                                    }
                                                                />
                                                            </Grid>
                                                            {secondVerified
                                                                ? null
                                                                : verified && (
                                                                      <Grid
                                                                          item
                                                                          xs={2}
                                                                      >
                                                                          <Tooltip
                                                                              arrow
                                                                              title={
                                                                                  <Typography variant="body2">
                                                                                      Request
                                                                                      Second
                                                                                      Verification
                                                                                  </Typography>
                                                                              }
                                                                              placement="bottom"
                                                                          >
                                                                              <span>
                                                                                  <IconButton
                                                                                      className={
                                                                                          classes.IconButton
                                                                                      }
                                                                                      onClick={
                                                                                          handleSendSecondVerificationEmail
                                                                                      }
                                                                                      disabled={
                                                                                          sendingSecondVerificationEmail
                                                                                      }
                                                                                  >
                                                                                      <SendRoundedIcon />
                                                                                      {sendingSecondVerificationEmail && (
                                                                                          <CircularProgress
                                                                                              size={
                                                                                                  24
                                                                                              }
                                                                                              className={
                                                                                                  classes.buttonProgress
                                                                                              }
                                                                                          />
                                                                                      )}
                                                                                  </IconButton>
                                                                              </span>
                                                                          </Tooltip>
                                                                      </Grid>
                                                                  )}
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Zoom>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={4}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Zoom
                                    in={
                                        items.itemID &&
                                        revisions.rev &&
                                        ((operations.op && primary) ||
                                            (moOps.moOp && !primary)) &&
                                        inspectionOpMemo.length > 0
                                    }
                                >
                                    <Box>
                                        <Paper className={classes.ControlTab}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-start"
                                                wrap="nowrap"
                                                alignItems="baseline"
                                            >
                                                {permissions.PrintInspection && (
                                                    <Button
                                                        startIcon={
                                                            <PrintRoundedIcon />
                                                        }
                                                        className={
                                                            classes.PrintButton
                                                        }
                                                        variant="outlined"
                                                        onClick={printOnClick}
                                                    >
                                                        Print
                                                    </Button>
                                                )}

                                                {permissions.CloneCharacteristics && (
                                                    <Button
                                                        startIcon={
                                                            <ContentCopyRoundedIcon />
                                                        }
                                                        className={
                                                            classes.CloneButton
                                                        }
                                                        onClick={
                                                            handleCloneOpen
                                                        }
                                                        variant="outlined"
                                                    >
                                                        Clone
                                                    </Button>
                                                )}
                                                {permissions.SendGageEmail && (
                                                    <Button
                                                        startIcon={
                                                            <SendRoundedIcon />
                                                        }
                                                        className={
                                                            classes.CloneButton
                                                        }
                                                        variant="outlined"
                                                        onClick={
                                                            handleSendGageEmail
                                                        }
                                                        disabled={sendingEmail}
                                                    >
                                                        <Grid>
                                                            {!sendingEmail
                                                                ? "Gage"
                                                                : new Date().getSeconds() %
                                                                      3 ===
                                                                  0
                                                                ? "Sending."
                                                                : new Date().getSeconds() %
                                                                      3 ===
                                                                  1
                                                                ? "Sending.."
                                                                : "Sending..."}
                                                            {sendingEmail && (
                                                                <LinearProgress />
                                                            )}
                                                        </Grid>
                                                    </Button>
                                                )}
                                                <Button
                                                    startIcon={
                                                        <ContactSupportIcon />
                                                    }
                                                    className={
                                                        classes.HelpButton
                                                    }
                                                    onClick={
                                                        handleOpenTicketDialog
                                                    }
                                                    variant="contained"
                                                >
                                                    Help
                                                </Button>
                                                {permissions.ArchiveInspection && (
                                                    <Zoom in={!primary}>
                                                        <Button
                                                            startIcon={
                                                                <Inventory2RoundedIcon />
                                                            }
                                                            className={
                                                                classes.ArchiveButton
                                                            }
                                                            onClick={
                                                                handleArchiveOpen
                                                            }
                                                            variant="contained"
                                                            disabled={
                                                                !permissions.ArchiveInspection ||
                                                                primary
                                                            }
                                                        >
                                                            Archive
                                                        </Button>
                                                    </Zoom>
                                                )}
                                            </Grid>
                                        </Paper>
                                        <Paper className={classes.FavoritesTab}>
                                            <FavoritesTab />
                                        </Paper>
                                    </Box>
                                </Zoom>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                className={classes.GridMargin}
                            >
                                {items.itemID &&
                                revisions.rev &&
                                ((operations.op && primary) ||
                                    (moOps.moOp && !primary)) &&
                                inspectionOpMemo.length > 0 ? (
                                    <Grid item xs={12}>
                                        <CharacteristicTable />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    )}
                    {/*MO Select for alternate Dialog*/}
                    <Dialog
                        open={alternateDialog}
                        onClose={handleAlternateDialogClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            {alternateText}
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogActions>
                                    <Autocomplete
                                        id="mo-selector"
                                        options={manufacturingOrders.map(
                                            (item) => item.ORDNO
                                        )}
                                        getOptionLabel={(option) => option}
                                        onInputChange={
                                            handleManufacturingOrderSelectorChange
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="MO"
                                                variant="outlined"
                                                margin="normal"
                                                className={classes.MOSelector}
                                            />
                                        )}
                                    />
                                </DialogActions>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleAlternateSelect}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleAlternateDialogClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Alternate NoMO Dialog*/}
                    <Dialog
                        open={alternateNoMODialog}
                        onClose={handleSecondayNoMOClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            No MO Found
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogContentText>
                                    {alternateNoMOText}
                                </DialogContentText>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleSecondayNoMOConfirm}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleSecondayNoMOClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Add Operation Dialog*/}
                    <Dialog
                        open={createOperationDialog}
                        onClose={handleCreateOperationClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            {createOperationText}: Enter Op Number and
                            WorkCenter
                        </DialogTitle>

                        <DialogContent>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="operation"
                                        label="Op Number"
                                        variant="outlined"
                                        margin="normal"
                                        className={classes.WorkcenterSelector}
                                        onChange={handleCreateOperationInput}
                                    />
                                </Grid>
                                <Grid item xs={10} md={6}>
                                    <Autocomplete
                                        id="workcenter-selector"
                                        options={facmst.map(
                                            (item) => item.WKCTR
                                        )}
                                        getOptionLabel={(option) => option}
                                        onChange={handleNewWorkCenterChange}
                                        value={newWKCTR}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Workcenter"
                                                variant="outlined"
                                                margin="normal"
                                                className={
                                                    classes.WorkcenterSelector
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCreateNewOperation}
                            >
                                Create
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCreateOperationClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Edit Workcenter Dialog*/}
                    <Dialog
                        open={editWorkcenterDialog}
                        onClose={handleEditWorkcenterClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            {editWorkcenterText}: Enter New WorkCenter
                        </DialogTitle>

                        <DialogContent>
                            <Box className={classes.ContainerBox}>
                                <DialogActions>
                                    <Autocomplete
                                        id="workcenter-selector"
                                        options={facmst.map(
                                            (item) => item.WKCTR
                                        )}
                                        getOptionLabel={(option) => option}
                                        onChange={handleNewWorkCenterChange}
                                        value={newWKCTR}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Workcenter"
                                                variant="outlined"
                                                margin="normal"
                                                className={
                                                    classes.WorkcenterSelector
                                                }
                                            />
                                        )}
                                    />
                                </DialogActions>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleConfirmEditWorkcenter}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleEditWorkcenterClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Update Routing Dialog*/}
                    <Dialog
                        open={updateRoutingDialog}
                        onClose={handleUpdateRoutingClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <WarningRoundedIcon />
                                Reset Routing
                            </Grid>
                        </DialogTitle>

                        <DialogContent>
                            <Box className={classes.ContainerBox}>
                                <DialogContentText>
                                    {updateRoutingText}
                                </DialogContentText>
                                {!primary &&
                                    manufacturingOrders.length > 0 &&
                                    updateRoutingPercent === 0 && (
                                        <DialogActions>
                                            <Autocomplete
                                                id="mo-selector"
                                                options={manufacturingOrders.map(
                                                    (item) => item.ORDNO
                                                )}
                                                getOptionLabel={(option) =>
                                                    option
                                                }
                                                onInputChange={
                                                    handleManufacturingOrderSelectorChange
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="MO"
                                                        variant="outlined"
                                                        margin="normal"
                                                        className={
                                                            classes.MOSelector
                                                        }
                                                    />
                                                )}
                                            />
                                        </DialogActions>
                                    )}
                                <CircularProgress
                                    variant="determinate"
                                    value={updateRoutingPercent}
                                />
                            </Box>
                        </DialogContent>
                        {updateRoutingPercent === 0 && (
                            <DialogActions>
                                <Button
                                    className={classes.Dialog}
                                    onClick={updateRoutingClick}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className={classes.Dialog}
                                    onClick={handleUpdateRoutingClose}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        )}
                    </Dialog>

                    {/*Archive Report Dialog*/}
                    <Dialog
                        open={archiveDialog}
                        onClose={handleArchiveClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Archive Report
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogContentText>
                                    {archiveText}
                                </DialogContentText>
                                {archiveLoading && <LinearProgress />}
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleArchive}
                                disabled={archiveLoading}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleArchiveClose}
                                disabled={archiveLoading}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Verify Report Dialog*/}
                    <Dialog
                        open={verificationDialog}
                        onClose={handleVerifyClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Verify Report
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogContentText>
                                    {verificationText}
                                </DialogContentText>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={verifiedOnChange}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleVerifyClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Second Verify Report Dialog*/}
                    <Dialog
                        open={secondVerificationDialog}
                        onClose={handleSecondVerifyClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Second Verify Report
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogContentText>
                                    {secondVerificationText}
                                </DialogContentText>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={secondVerifiedOnChange}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleSecondVerifyClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Change Manufacturing Order Dialog*/}
                    <Dialog
                        open={manufacturingOrderDialog}
                        onClose={handleCloseManufacturingOrderDialog}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            {manufacturingOrderText}
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <Autocomplete
                                    id="mo-selector"
                                    options={manufacturingOrders.map(
                                        (item) => item.ORDNO
                                    )}
                                    getOptionLabel={(option) => option}
                                    onInputChange={
                                        handleManufacturingOrderSelectorChange
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="MO"
                                            variant="outlined"
                                            margin="normal"
                                            className={classes.MOSelector}
                                        />
                                    )}
                                />
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleUpdateManufacturingOrder}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCloseManufacturingOrderDialog}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Delete Characteristic Dialog*/}
                    <Dialog
                        open={characteristic.characteristicDialog}
                        onClose={handleCharacteristicClose}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Delete Characteristic
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography
                                        className={classes.CharacteristicDialog}
                                    >
                                        {`Are you sure you would like to delete characteristic ${characteristic.toDelete.Characteristic}`}
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCharacteristicDelete}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCharacteristicClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Clone Characteristic Dialog*/}
                    <Dialog
                        open={cloneDialog}
                        onClose={handleCloneClose}
                        PaperComponent={PaperComponent}
                        className={classes.CloneDialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            {blankReport
                                ? "Blank Report Detected"
                                : "Clone Characteristics"}
                            {blankReport && (
                                <Typography
                                    className={classes.CharacteristicDialog}
                                >
                                    {`This report is blank. Please select a report to clone from or press cancel to create a new report.`}
                                </Typography>
                            )}
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent className={classes.CloneContainer}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <DialogContentText>
                                        <Typography
                                            className={
                                                classes.CharacteristicDialog
                                            }
                                        >
                                            {`Clone ${items.itm} ${
                                                revisions.rev
                                            } ${
                                                primary
                                                    ? operations.op
                                                    : moOps.moOp
                                            } ${
                                                primary
                                                    ? "Primary"
                                                    : "Alternate"
                                            }`}
                                        </Typography>
                                    </DialogContentText>

                                    <Select
                                        value={toFrom}
                                        label="To / From"
                                        onChange={handleToFromOnChange}
                                        className={classes.ToFrom}
                                        variant="outlined"
                                        disabled={lockToFrom}
                                    >
                                        <MenuItem value={"to"}>To</MenuItem>
                                        <MenuItem value={"from"}>From</MenuItem>
                                    </Select>
                                    {!cloneConfirm ? (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            wrap="nowrap"
                                        >
                                            <Grid
                                                item
                                                className={classes.CloneGrid}
                                            >
                                                <Autocomplete
                                                    autoComplete
                                                    limit={20}
                                                    id="clone-item-selector"
                                                    value={cloneItem}
                                                    options={[
                                                        ...new Set(
                                                            cloneList.map(
                                                                (item) =>
                                                                    item?.itemNumber
                                                            )
                                                        )
                                                    ].sort()}
                                                    onInputChange={(
                                                        event,
                                                        option
                                                    ) =>
                                                        handleCloneItemOnChange(
                                                            event,
                                                            option
                                                        )
                                                    }
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Item Number"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={
                                                                classes.CloneItemSelector
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.CloneGrid}
                                            >
                                                <Autocomplete
                                                    id="revision-selector"
                                                    options={[
                                                        ...new Set(
                                                            cloneList
                                                                .filter(
                                                                    (item) =>
                                                                        item.itemNumber?.trim() ===
                                                                        cloneItem?.trim()
                                                                )
                                                                .map((item) =>
                                                                    item.revision?.trim()
                                                                )
                                                        )
                                                    ]}
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    value={cloneRevision}
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    onInputChange={(
                                                        event,
                                                        option
                                                    ) =>
                                                        handleCloneRevisionOnChange(
                                                            event,
                                                            option
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Rev"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={
                                                                classes.CloneRevisionSelector
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.CloneGrid}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={
                                                                clonePrimaryOnChange
                                                            }
                                                            value={clonePrimary}
                                                            defaultChecked
                                                        />
                                                    }
                                                    label={
                                                        clonePrimary
                                                            ? "Primary"
                                                            : "Alternate"
                                                    }
                                                    className={
                                                        classes.PrimarySelector
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.CloneGrid}
                                            >
                                                <Autocomplete
                                                    autoComplete
                                                    id="operation-selector"
                                                    value={cloneOperation}
                                                    options={[
                                                        ...new Set(
                                                            cloneList
                                                                .filter(
                                                                    (item) =>
                                                                        item.itemNumber?.trim() ===
                                                                            cloneItem?.trim() &&
                                                                        item.revision?.trim() ===
                                                                            cloneRevision?.trim() &&
                                                                        item.primary ===
                                                                            clonePrimary
                                                                )
                                                                .map(
                                                                    (item) =>
                                                                        item?.operation
                                                                )
                                                        )
                                                    ].sort()}
                                                    onInputChange={(
                                                        event,
                                                        option
                                                    ) =>
                                                        handleCloneOperationOnChange(
                                                            event,
                                                            option
                                                        )
                                                    }
                                                    filterOptions={
                                                        filterOptions
                                                    }
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Operation"
                                                            variant="outlined"
                                                            margin="normal"
                                                            className={
                                                                classes.ItemSelector
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <DialogContentText>
                                            <Typography
                                                className={
                                                    classes.CharacteristicDialog
                                                }
                                            >
                                                {`${cloneItem} ${cloneRevision} ${cloneOperation} ${
                                                    clonePrimary
                                                        ? "Primary"
                                                        : "Alternate"
                                                }`}
                                            </Typography>
                                        </DialogContentText>
                                    )}
                                </Grid>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={
                                    !cloneConfirm
                                        ? handleCloneOnConfirm
                                        : handleClone
                                }
                            >
                                {!cloneConfirm ? "Select" : "Confirm"}
                            </Button>
                            {cloneConfirm && (
                                <Button
                                    className={classes.Dialog}
                                    onClick={handleGoBack}
                                >
                                    Go Back
                                </Button>
                            )}
                            <Button
                                className={classes.Dialog}
                                onClick={handleCloneClose}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Change Material Dialog*/}
                    <Dialog
                        open={materialDialog}
                        onClose={handleCloseMaterialDialog}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Please Enter a Material
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <TextField
                                    id="material"
                                    label="Material"
                                    variant="outlined"
                                    margin="normal"
                                    defaultValue={material}
                                    value={newMaterial}
                                    onChange={handleMaterialOnChange}
                                    className={classes.MaterialSelector}
                                />
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleUpdateMaterial}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCloseMaterialDialog}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*Change cert Dialog*/}
                    <Dialog
                        open={certificationDialog}
                        onClose={handleCloseCertificationDialog}
                        PaperComponent={PaperComponent}
                        className={classes.Dialog}
                    >
                        <DialogTitle
                            style={{ cursor: "move" }}
                            id="draggable-dialog-title"
                        >
                            Please Enter a Certification
                        </DialogTitle>
                        <Box className={classes.ContainerBox}>
                            <DialogContent>
                                <TextField
                                    id="cert"
                                    label="Certification"
                                    variant="outlined"
                                    margin="normal"
                                    defaultValue={certification}
                                    value={newCertification}
                                    onChange={handleCertificationOnChange}
                                    className={classes.MaterialSelector}
                                />
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button
                                className={classes.Dialog}
                                onClick={handleUpdateCertification}
                            >
                                Confirm
                            </Button>
                            <Button
                                className={classes.Dialog}
                                onClick={handleCloseCertificationDialog}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <TicketDialog
                        open={openTicketDialog}
                        handleClose={handleCloseTicketDialog}
                        itmNumber={items.itm ? items.itm : ""}
                        itmRevision={revisions.rev ? revisions.rev : ""}
                        op={
                            (primary ? operations.op : moOps.moOp)
                                ? primary
                                    ? operations.op
                                    : moOps.moOp
                                : ""
                        }
                        primary={primary}
                        setMainAlertDialog={setAlertDialog}
                        setMainAlertText={setAlertText}
                        setMainAlertSeverity={setAlertSeverity}
                    />

                    {/* Alert Dialog */}
                    <div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right"
                            }}
                            open={alertDialog}
                            autoHideDuration={5000}
                            onClose={handleAlertDialogClose}
                        >
                            <Alert
                                onClose={handleAlertDialogClose}
                                severity={alertSeverity}
                                className={classes.Dialog}
                            >
                                {alertText}
                            </Alert>
                        </Snackbar>
                    </div>
                </>
            ) : (
                <LoginPrompt />
            )}
        </>
    )
}

export default InspectionPage
