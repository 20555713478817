export const leadingSymbols = [
    "\xfb",
    "\xfc",
    "\xe1",
    "\xe2",
    "\xe3",
    "\xe4",
    "\xe5",
    "\xe6",
    "\xe7",
    "\xe8",
    "\xb3",
    "\xba"
]

export const tolerances = [
    "ùîîîï",
    "ùîîîð",
    "ùîîîñ",
    "ùîîîò",
    "ùîîîó",
    "ùîîîô",
    "ùîîîõ",
    "ùîîîö",
    "ùîîî÷",
    "ùîîï",
    "ùîîð",
    "ùîîó",
    "ùîïî",
    "ùîïó",
    "ùîðî"
]

export const datums = ["\xdd", "\xde", "\xdf", "\u2026", "\x9f", "ž", "χ"]

export const alphabets = [
    "\xc0",
    "\xc1",
    "\xc2",
    "\xc3",
    "\xc4",
    "\xc5",
    "\xc6",
    "\xc7",
    "\xc8",
    "\xc9",
    "\xca",
    "\xcb",
    "\xcc",
    "\xcd",
    "\xce",
    "\xcf",
    "\xd0",
    "\xd1",
    "\xd2",
    "\xd3",
    "\xd4",
    "\xd5",
    "\xd6",
    "\xd7",
    "\xd8",
    "\xd9"
]

export const roots = [
    "\xa3",
    "\xac",
    "\u02c6",
    "\xdb",
    "\xda",
    "\xb4",
    "\xbb",
    "\xab"
]

export const numbers = [
    "\xf9",
    "\xee",
    "\xef",
    "\xf0",
    "\xf1",
    "\xf2",
    "\xf3",
    "\xf4",
    "\xf5",
    "\xf6",
    "\xf7"
]

export const fractions = ["\xbc", "\xbd", "\xbe"]

export const additionalModifiers = leadingSymbols.concat([
    "à",
    "\u2013",
    "\xf9",
    "\xfa",
    "\u2022"
])

export const trailingContainers = ["\xed", "\xb6", "\xea"]

export const middleContainers = ["\xec"]

export const leadingContainers = ["\xeb", "\xb9", "\xe9"]

export const containers = leadingContainers.concat(
    middleContainers,
    trailingContainers
)

export const modifier = "à"
export const modifierLabel = "\xd8"

export const row1 = [
    "ï",
    "ð",
    "ñ",
    "ò",
    "ó",
    "ô",
    "õ",
    "ö",
    "÷",
    "î",
    "ú",
    "ύ",
    "\xec"
]
export const row2 = [
    "Ð",
    "Ö",
    "Ä",
    "Ñ",
    "Ó",
    "Ø",
    "Ô",
    "È",
    "Î",
    "Ï",
    "\xeb",
    "\xed"
]
export const row3 = ["À", "Ò", "Ã", "Å", "Æ", "Ç", "É", "Ê", "Ë"]
export const row4 = ["Ù", "×", "Â", "Õ", "Á", "Í", "Ì", "ù"]

export const library = leadingSymbols.concat(
    tolerances,
    datums,
    alphabets,
    roots,
    numbers,
    fractions,
    leadingSymbols,
    containers,
    modifier
)
