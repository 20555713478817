import { makeStyles } from "@material-ui/core/styles"
import "../../../resources/fonts/fonts.css"

const useStyles = makeStyles((theme) => ({
    CenterCard: {
        height: "80vh",
        width: "95vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "1",
        opacity: "0.75"
    },
    CenterText: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        textAlign: "center",
        position: "absolute",
        zIndex: "1"
    },
    SpinningGears: {
        zIndex: "-1",
        opacity: "0.5"
    }
}))

export default useStyles
