import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    Input
} from "@material-ui/core"
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import useStyles from "../styles/MaintenanceStyles"
import {
    createInspectionMethod,
    getInspectionMethods,
    deleteInspectionMethod,
    updateInspectionMethod,
    updateselected,
    setAlertDialog,
    setAlertText
} from "../../../actions/maintenanceActions"
import axios from "../../../auth/axiosAuth"
import { backendURL } from "../../../config"

function InspectionMethodToolBar() {
    const dispatch = useDispatch()
    const classes = useStyles()

    // Store Variables
    const maintenance = useSelector((state) => state.maintenance)
    const auth = useSelector((state) => state.auth)
    const permissions = useSelector((state) => state.auth.permissions)

    // Add Dialog State
    const [addDialogValues, setAddDialogValues] = useState({
        inspectionMethod: ""
    })
    const [addDialogOpen, setAddDialogOpen] = useState(false)

    //edit dialog state
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [editDialogValues, setEditDialogValues] = useState({
        inspectionMethod: ""
    })

    // Delete Dialog State
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    // Alert Handlers
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        dispatch(setAlertDialog(false))
    }

    // Add Dialog Handlers
    const handleAddDialogOpen = () => {
        clearAddDialogValues()
        setAddDialogOpen(true)
        handleAlertDialogClose(null, null)
    }

    const handleAddDialogClose = () => {
        clearAddDialogValues()
        setAddDialogOpen(false)
    }

    const clearAddDialogValues = () => {
        setAddDialogValues({ inspectionMethod: "" })
    }

    const handleConfirmAdd = async () => {
        await dispatch(getInspectionMethods())
        if (addDialogValues.inspectionMethod !== "") {
            if (
                maintenance.inspectionMethods?.filter(
                    (inspectionMethod) =>
                        inspectionMethod.InspectionMethod.toLowerCase() ===
                        addDialogValues.inspectionMethod.toLowerCase()
                ).length > 0
            ) {
                dispatch(setAlertText("This inspection method already exists"))
                dispatch(setAlertDialog(true))
            } else {
                try {
                    addDialogValues.inspectionMethod =
                        addDialogValues.inspectionMethod.trim()
                    const newInspectionMethod = {
                        InspectionMethod: addDialogValues.inspectionMethod,
                        AuditLog: {
                            Action: "Create Inspection Method",
                            OldValue: "",
                            NewValue: addDialogValues.inspectionMethod,
                            AssociateOID: auth.userData.AssociateOID
                        }
                    }
                    await dispatch(createInspectionMethod(newInspectionMethod))
                    await dispatch(getInspectionMethods())
                    setAddDialogOpen(false)
                    clearAddDialogValues()
                } catch (error) {
                    console.log(error)
                    clearAddDialogValues()
                }
            }
        } else {
            dispatch(
                setAlertText("Inspection Method Name field must be filled out")
            )
            dispatch(setAlertDialog(true))
        }
    }

    const handleUpdateAddDialogValues = (e) => {
        const name = e.target.name
        const value = e.target.value

        setAddDialogValues({
            ...addDialogValues,
            [name]: value
        })
    }

    // Delete Dialog Handlers
    const handleDeleteDialogOpen = async () => {
        const res = await axios.get(
            `${backendURL}/inspectionmethods/check/${maintenance.selected.InspectionMethodId}`
        )
        if (res.data.length > 0) {
            dispatch(
                setAlertText(
                    "This inspection method is being used and cannot be deleted"
                )
            )
            dispatch(setAlertDialog(true))
            return
        }
        if (maintenance.selected.InspectionMethodId) {
            setAddDialogValues({
                inspectionMethod: maintenance.selected.InspectionMethod
            })
            setDeleteDialogOpen(true)
        }
    }

    const handleDeleteDialogClose = () => {
        clearAddDialogValues()
        setDeleteDialogOpen(false)
    }

    const handleConfirmDelete = async () => {
        try {
            const deleteMethod = {
                InspectionMethodId: maintenance.selected.InspectionMethodId,
                AuditLog: {
                    Action: "Delete Inspection Method",
                    OldValue: maintenance.selected.InspectionMethod,
                    NewValue: "",
                    AssociateOID: auth.userData.AssociateOID
                }
            }
            await dispatch(deleteInspectionMethod(deleteMethod))
            await dispatch(getInspectionMethods())
            dispatch(updateselected([]))
            setDeleteDialogOpen(false)
        } catch (error) {
            await dispatch(getInspectionMethods())
            dispatch(updateselected([]))
            setDeleteDialogOpen(false)
        }
    }

    //Edit Dialog Handlers
    const handleEditDialogOpen = async () => {
        const res = await axios.get(
            `${backendURL}/inspectionmethods/check/${maintenance.selected.InspectionMethodId}`
        )
        if (res.data.length > 0) {
            dispatch(
                setAlertText(
                    "This inspection method is being used and cannot be updated"
                )
            )
            dispatch(setAlertDialog(true))
            return
        }
        if (maintenance.selected.InspectionMethodId) {
            setEditDialogValues({
                inspectionMethod: maintenance.selected.InspectionMethod
            })
            setEditDialogOpen(true)
        }
    }

    const handleEditDialogClose = () => {
        clearAddDialogValues()
        setEditDialogOpen(false)
    }

    const handleConfirmEdit = async () => {
        if (editDialogValues.inspectionMethod !== "") {
            if (
                maintenance.inspectionMethods?.filter(
                    (inspectionMethod) =>
                        inspectionMethod.InspectionMethod.toLowerCase() ===
                        editDialogValues.inspectionMethod.toLowerCase()
                ).length > 0
            ) {
                dispatch(setAlertText("This inspection method already exists"))
                dispatch(setAlertDialog(true))
            } else {
                try {
                    editDialogValues.inspectionMethod =
                        editDialogValues.inspectionMethod.trim()
                    const updatedInspectionMethod = {
                        InspectionMethodId:
                            maintenance.selected.InspectionMethodId,
                        InspectionMethod: editDialogValues.inspectionMethod
                    }
                    const newInspectionMethod = {
                        InspectionMethod: updatedInspectionMethod,
                        AuditLog: {
                            InspectionMethodId:
                                maintenance.selected.InspectionMethodId,
                            Action: "Update Inspection Method",
                            OldValue: maintenance.selected.InspectionMethod,
                            NewValue: editDialogValues.inspectionMethod,
                            AssociateOID: auth.userData.AssociateOID
                        }
                    }
                    await dispatch(updateInspectionMethod(newInspectionMethod))
                    dispatch(updateselected([updatedInspectionMethod]))
                    await dispatch(getInspectionMethods())
                    setEditDialogOpen(false)
                    clearAddDialogValues()
                } catch (error) {
                    console.log(error)
                    clearAddDialogValues()
                }
            }
        } else {
            dispatch(
                setAlertText("Inspection Method Name field must be filled out")
            )
            dispatch(setAlertDialog(true))
        }
    }

    const handleUpdateEditDialogValues = (e) => {
        const name = e.target.name
        const value = e.target.value

        setEditDialogValues({
            ...editDialogValues,
            [name]: value
        })
    }

    return (
        <Grid>
            {/*ToolBar*/}
            <GridToolbarContainer className={classes.Button}>
                <GridToolbarFilterButton className={classes.Button} />
                <GridToolbarDensitySelector className={classes.Button} />
                <GridToolbarExport className={classes.Button} />
                {permissions.EditMaintenance && (
                    <>
                        <Button
                            startIcon={<AddIcon />}
                            onClick={handleAddDialogOpen}
                            className={classes.Button}
                            disabled={maintenance.isBeingEdited}
                        >
                            Add
                        </Button>
                        {maintenance.selected.InspectionMethodId && (
                            <>
                                <Button
                                    startIcon={<EditIcon />}
                                    onClick={handleEditDialogOpen}
                                    className={classes.Button}
                                    disabled={maintenance.isBeingEdited}
                                >
                                    Edit
                                </Button>
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteDialogOpen}
                                    className={classes.Button}
                                    disabled={maintenance.isBeingEdited}
                                >
                                    Delete
                                </Button>
                            </>
                        )}
                    </>
                )}
            </GridToolbarContainer>

            {/*Add inspection Method Dialog*/}
            <Dialog
                open={addDialogOpen}
                onClose={handleAddDialogClose}
                className={classes.Dialog}
            >
                <DialogTitle>Add Inspection Method</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText className={classes.Dialog}>
                        Please enter the inspection method you would like to add
                    </DialogContentText>
                    <Input
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Inspection Method Name"
                        onChange={handleUpdateAddDialogValues}
                        name="inspectionMethod"
                        className={classes.Dialog}
                        value={addDialogValues.inspectionMethod}
                        fullWidth
                        variant="standard"
                        placeholder="Inspection Method Name"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmAdd}
                        className={classes.Dialog}
                    >
                        Add
                    </Button>
                    <Button
                        onClick={handleAddDialogClose}
                        className={classes.Dialog}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Delete Inspection Method Dialog*/}
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                className={classes.Dialog}
            >
                <DialogTitle>Delete Inspection Method</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText className={classes.Dialog}>
                        Are you sure you would like to delete{" "}
                        {addDialogValues.inspectionMethod}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmDelete}
                        className={classes.Dialog}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={handleDeleteDialogClose}
                        className={classes.Dialog}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {/*Edit inspection Method Dialog*/}
            <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                className={classes.Dialog}
            >
                <DialogTitle>Edit Inspection Method</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText className={classes.Dialog}>
                        What would you like to change the inspection method to?
                    </DialogContentText>
                    <Input
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Inspection Method Name"
                        onChange={handleUpdateEditDialogValues}
                        name="inspectionMethod"
                        className={classes.Dialog}
                        value={editDialogValues.inspectionMethod}
                        fullWidth
                        variant="standard"
                        placeholder="Inspection Method Name"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmEdit}
                        className={classes.Dialog}
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={handleEditDialogClose}
                        className={classes.Dialog}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default InspectionMethodToolBar
