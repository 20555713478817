import { combineReducers } from "redux"
import authReducer from "./authReducer"
import maintenanceReducer from "./maintenanceReducer"
import adminReducer from "./adminReducer"
import erpReducer from "./erpReducer"
import inspectionReducer from "./inspectionReducer"
import characteristicReducer from "./characteristicReducer"
import outputReducer from "./outputReducer"

export default combineReducers({
    auth: authReducer,
    maintenance: maintenanceReducer,
    admin: adminReducer,
    erp: erpReducer,
    inspection: inspectionReducer,
    characteristic: characteristicReducer,
    output: outputReducer
})
