import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DataGrid } from "@mui/x-data-grid"
import useStyles from "../styles/AdminStyles"
import { getAuditLog } from "../../../actions/adminActions"
import { toColumns } from "../AdminSharedFunctions"
import AuditToolBar from "./AuditToolBar"

export default function AuditGrid() {
    const dispatch = useDispatch()
    const classes = useStyles()

    // Store Variables
    const admin = useSelector((state) => state.admin)
    const auth = useSelector((state) => state.auth)
    const permissions = useSelector((state) => state.auth.permissions)

    useEffect(() => {
        if (auth.authenticated) {
            async function runUseEffectFunctions() {
                await dispatch(getAuditLog())
            }
            runUseEffectFunctions()
        }
    }, [dispatch, auth])

    //creating columns and rows for the grid
    let columns = []
    let rows = []
    const auditLog = admin.auditLog
    if (auditLog.length > 0) {
        columns = toColumns(Object.keys(auditLog[0]), permissions)
        rows = auditLog
    }

    return (
        <div className={classes.DataGridWrapper}>
            <DataGrid
                getRowId={(row) => row.ChangeId}
                rows={rows}
                columns={columns}
                components={{ Toolbar: AuditToolBar }}
                className={classes.DataGrid}
                columnVisibilityModel={{
                    ChangeId: false
                }}
            />
        </div>
    )
}
