import React, { useEffect, useState, useRef, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { DataGrid } from "@mui/x-data-grid"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core"
import useStyles from "../styles/MaintenanceStyles"
import {
    getControlMethods,
    updateControlMethod,
    updateselected,
    updateIsBeingEdited,
    setAlertDialog,
    setAlertText
} from "../../../actions/maintenanceActions"
import {
    toColumns,
    useControlMethodMutation,
    computeMutation
} from "../MaintenanceSharedFunctions"
import ControlMethodToolBar from "./ControlMethodToolBar"
import axios from "../../../auth/axiosAuth"
import { backendURL } from "../../../config"

export default function ControlMethod() {
    const dispatch = useDispatch()
    const mutateRow = useControlMethodMutation()
    const classes = useStyles()
    const noButtonRef = useRef(null)

    // Store Variables
    const maintenance = useSelector((state) => state.maintenance)
    const auth = useSelector((state) => state.auth)
    const loggedIn = useSelector(
        (state) => state.auth.user && state.auth.user.auth
    )
    const permissions = useSelector((state) => state.auth.permissions)

    const [promiseArguments, setPromiseArguments] = useState(null)

    useEffect(() => {
        if (loggedIn) {
            async function runUseEffectFunctions() {
                await dispatch(getControlMethods())
            }
            runUseEffectFunctions()
        }
    }, [dispatch, loggedIn])

    //creating columns and rows for the grid
    let columns = []
    let rows = []
    const controlMethods = maintenance.controlMethods
    if (controlMethods?.length > 0) {
        columns = toColumns(Object.keys(controlMethods[0]), permissions)
        rows = controlMethods
    }

    //handling the grid's row update
    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise((resolve, reject) => {
                const mutation = computeMutation(newRow, oldRow)
                if (mutation) {
                    // Save the arguments to resolve or reject the promise later

                    setPromiseArguments({ resolve, reject, newRow, oldRow })
                } else {
                    resolve(oldRow) // Nothing was changed
                }
            }),
        []
    )

    //handling error in row update
    const onProcessRowUpdateError = () => {
        if (promiseArguments) {
            promiseArguments.reject()
            setPromiseArguments(null)
        }
    }

    //handle closing confirmation dialog on no
    const handleCancelUpdateControlMethod = () => {
        const { oldRow, resolve } = promiseArguments
        resolve(oldRow) // Resolve with the old row to not update the internal state
        setPromiseArguments(null)
        dispatch(setAlertDialog(false))
        dispatch(updateselected([]))
        dispatch(updateIsBeingEdited(false))
    }

    //handle closing confirmation dialog on yes
    const handleConfirmUpdateControlMethod = async () => {
        const { newRow, oldRow, reject, resolve } = promiseArguments
        const res = await axios.get(
            `${backendURL}/controlmethods/check/${oldRow.ControlMethodId}`
        )
        if (res.data.length > 0) {
            dispatch(
                setAlertText(
                    "This control method is being used and cannot be updated"
                )
            )
            dispatch(setAlertDialog(true))
            const { oldRow, resolve } = promiseArguments
            resolve(oldRow) // Resolve with the old row to not update the internal state
            setPromiseArguments(null)
            dispatch(updateselected([]))
            dispatch(updateIsBeingEdited(false))
            return
        }
        if (newRow.ControlMethod.trim() !== "") {
            if (
                maintenance.controlMethods?.filter(
                    (controlMethod) =>
                        controlMethod.ControlMethod.toLowerCase() ===
                        newRow.ControlMethod.toLowerCase()
                ).length > 0
            ) {
                dispatch(setAlertText("This control method already exists"))
                dispatch(setAlertDialog(true))
            } else {
                try {
                    const response = await mutateRow(newRow)
                    // Make the HTTP request to save in the backend
                    const newControlMethod = {
                        ControlMethod: response,
                        AuditLog: {
                            ControlMethodId: response.ControlMethodId,
                            Action: "Update Control Method",
                            OldValue: oldRow.ControlMethod,
                            NewValue: newRow.ControlMethod,
                            AssociateOID: auth.userData.AssociateOID
                        }
                    }
                    // if(){
                    //     handleCancelUpdateControlMethod()
                    // }
                    // Update control method and refresh store data
                    await dispatch(updateControlMethod(newControlMethod))
                    await dispatch(getControlMethods())
                    await dispatch(updateselected([]))
                    await dispatch(updateIsBeingEdited(false))
                    // Resolve with the new row to update the internal state
                    resolve(response)
                    // Clear the promise arguments to avoid resolving or rejecting the same promise twice
                    setPromiseArguments(null)
                } catch (error) {
                    reject(oldRow)
                    console.log(error)
                    setPromiseArguments(null)
                    await dispatch(updateselected([]))
                    await dispatch(updateIsBeingEdited(false))
                }
            }
        } else {
            reject(oldRow)
            setPromiseArguments(null)
        }
    }

    //update store when clicking on the row
    const handleRowClick = (event, row) => {
        dispatch(updateselected(event.row))
    }

    const handleRowEdit = async (row) => {
        await dispatch(updateIsBeingEdited(true))
    }

    const handleRowEditStop = async (row) => {
        await dispatch(updateIsBeingEdited(false))
    }

    const handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "No". Instead, we manually focus the "No" button once
        // the dialog is fully open.
        // noButtonRef.current?.focus();
    }

    // Handles rendering the dialog to confirm updating a control method
    const renderConfirmUpdateControlMethodDialog = () => {
        if (!promiseArguments) {
            return null
        }

        const { newRow, oldRow } = promiseArguments
        const mutation = computeMutation(newRow, oldRow)

        return (
            <Dialog
                className={classes.Dialog}
                maxWidth="xs"
                TransitionProps={{ onEntered: handleEntered }}
                open={!!promiseArguments}
                onClose={handleCancelUpdateControlMethod}
            >
                <DialogTitle className={classes.Dialog}>
                    Are you sure?
                </DialogTitle>
                <DialogContent dividers>
                    {`Pressing 'Yes' will change ${mutation}.`}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmUpdateControlMethod}
                        className={classes.Dialog}
                    >
                        Yes
                    </Button>
                    <Button
                        ref={noButtonRef}
                        onClick={handleCancelUpdateControlMethod}
                        className={classes.Dialog}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div className={classes.DataGridWrapper}>
            {renderConfirmUpdateControlMethodDialog()}
            <DataGrid
                getRowId={(row) => row.ControlMethodId}
                rows={rows}
                columns={columns}
                columnVisibilityModel={{
                    ControlMethodId: false
                }}
                processRowUpdate={processRowUpdate}
                onRowClick={handleRowClick}
                onProcessRowUpdateError={onProcessRowUpdateError}
                experimentalFeatures={{ newEditingApi: true }}
                components={{ Toolbar: ControlMethodToolBar }}
                className={classes.DataGrid}
                onCellEditStart={handleRowEdit}
                onCellEditStop={handleRowEditStop}
            />
        </div>
    )
}
