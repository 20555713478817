import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
    TextField,
    Paper,
    Typography,
    Grid,
    Button,
    Divider,
    IconButton,
    AppBar,
    Checkbox,
    Zoom,
    Collapse,
    Tooltip,
    Snackbar
} from "@material-ui/core"
import { TransitionGroup } from "react-transition-group"
import Autocomplete from "@mui/material/Autocomplete"
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded"
import AddIcon from "@mui/icons-material/Add"
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded"
import KeyboardIcon from "@mui/icons-material/Keyboard"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import "../../resources/fonts/fonts.css"
import {
    getCharacteristics,
    createCharacteristic,
    updateCharacteristic,
    setCharacteristicDialog,
    setToDelete,
    newUpdateSequence
} from "../../actions/characteristicActions"

import Alert from "../main/Alert"
import useStyles from "./styles/InspectionStyle"

function CharactisticTable() {
    const dispatch = useDispatch()

    const auth = useSelector((state) => state.auth)
    const permissions = useSelector((state) => state.auth.permissions)
    const inspection = useSelector((state) => state.inspection)
    const characteristic = useSelector((state) => state.characteristic)
    const maintenance = useSelector((state) => state.maintenance)

    const classes = useStyles()

    const inspectionObjectMemo = useMemo(
        () =>
            inspection.inspections?.find(
                (ins) => ins.InspectionId === inspection.inspectionId
            ),
        [inspection]
    )

    const [allowAdd, setAllowAdd] = useState(true)
    const [alertText, setAlertText] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [alertDialog, setAlertDialog] = useState(false)
    const handleAlertDialogClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlertDialog(false)
    }

    const onDragEnd = async (result) => {
        if (!permissions.EditCharacteristics) {
            setAlertText(
                "You do not have permission to reorder characteristics"
            )
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        } else if (inspectionObjectMemo?.VerifiedByOID) {
            setAlertText(
                "You cannot reorder characteristics on a verified inspection"
            )
            setAlertSeverity("error")
            setAlertDialog(true)
            return
        }
        if (!result.destination) return
        const items = Array.from(characteristic.characteristics)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)
        items.map((item, index) => {
            item.SequenceNumber = index
            return item
        })
        await dispatch(newUpdateSequence(items))
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    //adds a new item to the list when user presses ctrl + enter
    const handleKeyDown = async (e) => {
        if (e.ctrlKey && e.key === "Enter") {
            setAllowAdd(false)
            const items = Array.from(characteristic.characteristics)
            await dispatch(
                createCharacteristic({
                    InspectionId: inspection.inspectionId,
                    Characteristic: "",
                    SequenceNumber:
                        items.length <= 0
                            ? 0
                            : items[items.length - 1].SequenceNumber + 1,
                    InspectionMethodId: null,
                    ControlMethodId: null,
                    FrequencyId: null,
                    AuditLog: {
                        Action: "Create Characteristic",
                        OldValue: "",
                        NewValue: "",
                        AssociateOID: auth.userData.AssociateOID
                    }
                })
            )
            setAllowAdd(true)
            await dispatch(getCharacteristics(inspection.inspectionId))
        }
    }

    const handleAddClick = async () => {
        setAllowAdd(false)
        const items = Array.from(characteristic.characteristics)
        await dispatch(
            createCharacteristic({
                InspectionId: inspection.inspectionId,
                Characteristic: "",
                SequenceNumber:
                    items.length <= 0
                        ? 0
                        : items[items.length - 1].SequenceNumber + 1,
                InspectionMethodId: null,
                ControlMethodId: null,
                FrequencyId: null,
                AuditLog: {
                    Action: "Create Characteristic",
                    OldValue: "",
                    NewValue: "",
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
        setAllowAdd(true)
    }

    const handleCharacteristicChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Characteristic
        if (e.target.value.trim() === "") {
            setAlertText("Characteristic cannot be empty")
            setAlertDialog(true)
            setAlertSeverity("error")
            e.target.value = oldValue
            return
        }
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                Characteristic: e.target.value,
                CharacteristicId: item.CharacteristicId,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.value,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const frequencyOnChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        if (e.target.value === "" || e.target.value === undefined) {
            items[index].FrequencyId = null
            return
        }
        const frequency = maintenance.frequencies.filter(
            (frequency) =>
                frequency.Frequency.replace(/ +(?= )/g, "") ===
                e.target.innerText.replace(/ +(?= )/g, "")
        )[0]?.FrequencyId
        const oldValue = items[index].FrequencyId
        if (oldValue === e.target.value) return
        items[index].FrequencyId = frequency
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                FrequencyId: frequency,
                CharacteristicId: items[index].CharacteristicId,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.innerText,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const inspectionMethodOnChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        if (e.target.value === "" || e.target.value === undefined) {
            items[index].InspectionMethodId = null
            return
        }
        const inspectionMethod = maintenance.inspectionMethods.filter(
            (inspectionMethod) =>
                inspectionMethod.InspectionMethod.replace(/ +(?= )/g, "") ===
                e.target.innerText.replace(/ +(?= )/g, "")
        )[0]?.InspectionMethodId
        const oldValue = items[index].InspectionMethodId
        if (oldValue === e.target.value) return
        items[index].InspectionMethodId = inspectionMethod
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                InspectionMethodId: inspectionMethod,
                CharacteristicId: items[index].CharacteristicId,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.innerText,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const controlMethodOnChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        if (e.target.value === "" || e.target.value === undefined) {
            items[index].ControlMethodId = null
            return
        }
        const controlMethod = maintenance.controlMethods.filter(
            (controlMethod) =>
                controlMethod.ControlMethod.replace(/ +(?= )/g, "") ===
                e.target.innerText.replace(/ +(?= )/g, "")
        )[0]?.ControlMethodId
        const oldValue = items[index].ControlMethodId
        items[index].ControlMethodId = controlMethod
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                ControlMethodId: controlMethod,
                CharacteristicId: items[index].CharacteristicId,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.innerText,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const handleToolChange = async (e, item, index) => {
        if (e.target.value === "") return
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Tool
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                Tool: e.target.value,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.value,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const handleReactionChange = async (e, item, index) => {
        if (e.target.value === "") return
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Reaction
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                Reaction: e.target.value,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.value,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const handleLocationChange = async (e, item, index) => {
        if (e.target.value === "") return
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Location
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                Location: e.target.value,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: e.target.value,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const handleGageChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Gage
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                Gage: !item.Gage,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: !oldValue,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const handleKeyChange = async (e, item, index) => {
        const items = Array.from(characteristic.characteristics)
        const oldValue = items[index].Key
        if (oldValue === e.target.value) return
        await dispatch(
            updateCharacteristic({
                InspectionId: inspection.inspectionId,
                CharacteristicId: item.CharacteristicId,
                Key: !item.Key,
                AuditLog: {
                    Action: "Update Characteristic",
                    OldValue: oldValue,
                    NewValue: !oldValue,
                    AssociateOID: auth.userData.AssociateOID
                }
            })
        )
        await dispatch(getCharacteristics(inspection.inspectionId))
    }

    const openDeleteDialog = (item) => {
        dispatch(setCharacteristicDialog(true))
        dispatch(setToDelete(item))
    }

    const openPopup = () => {
        let params = `personalbar=no,scrollbars=no,resizable=no,statusbar=no,locationbar=no,toolbar=no,menubar=no,
        width=900,height=485,left=100,top=100`
        window.open("/#/gts", "GTS Keyboard", params)
    }

    return (
        <>
            <Zoom in={inspection.inspectionId}>
                <Paper
                    className={classes.CharacteristicStack}
                    onKeyDown={handleKeyDown}
                >
                    <AppBar position="sticky">
                        <Grid
                            container
                            columns={16}
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                {permissions.EditCharacteristics &&
                                    !inspectionObjectMemo?.VerifiedByOID && (
                                        <Button
                                            size="small"
                                            className={
                                                classes.AddCharacteristicButton
                                            }
                                            onClick={handleAddClick}
                                            variant="outlined"
                                            disabled={!allowAdd}
                                            startIcon={<AddIcon />}
                                        >
                                            Add Characteristic
                                        </Button>
                                    )}
                                <Button
                                    size="small"
                                    className={classes.AddCharacteristicButton}
                                    onClick={openPopup}
                                    variant="outlined"
                                    startIcon={<KeyboardIcon />}
                                >
                                    GTS Keyboard
                                </Button>
                                {permissions.EditCharacteristics &&
                                    !inspectionObjectMemo?.VerifiedByOID && (
                                        <Tooltip
                                            title={
                                                <Typography variant="body2">
                                                    Press Control + Enter to add
                                                    a new row
                                                </Typography>
                                            }
                                            arrow
                                            placement="left"
                                        >
                                            <IconButton
                                                style={{
                                                    marginLeft: "auto"
                                                }}
                                            >
                                                <InfoOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                className={classes.ColumnHeaderGrid}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={6}
                                >
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Sequence
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Characteristic*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Frequency*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Method*
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    item
                                    xs={6}
                                >
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Tool
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Control*
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Reaction
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Location
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Gage
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant="subtitle2"
                                            className={classes.ColumnHeader}
                                            noWrap
                                        >
                                            Key
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AppBar>
                    <Paper
                        className={
                            inspection.inspectionId?.includes("primary")
                                ? classes.CharacteristicPaper
                                : classes.CharacteristicPaperAlt
                        }
                    >
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="drop">
                                {(provided) => (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="stretch"
                                        name="Drop"
                                        ref={provided.innerRef}
                                        className={classes.CharactisticGrid}
                                    >
                                        <TransitionGroup
                                            className={classes.FullWidth}
                                        >
                                            {characteristic.characteristics
                                                ?.sort(
                                                    (a, b) =>
                                                        a.SequenceNumber -
                                                        b.SequenceNumber
                                                )
                                                .map(
                                                    (item, index) =>
                                                        item.CharacteristicId >
                                                            0 && (
                                                            <Collapse
                                                                key={index}
                                                                className={
                                                                    classes.FullWidth
                                                                }
                                                            >
                                                                <Draggable
                                                                    draggableId={`${item.CharacteristicId}`}
                                                                    key={`${item.CharacteristicId}`}
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {(
                                                                        provided,
                                                                        snapshot
                                                                    ) => (
                                                                        <Grid
                                                                            item
                                                                            container
                                                                            xs={
                                                                                12
                                                                            }
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={
                                                                                snapshot.isDragging
                                                                                    ? classes.RowBorderLast
                                                                                    : index ===
                                                                                      0
                                                                                    ? classes.RowBorderLast
                                                                                    : classes.RowBorder
                                                                            }
                                                                            alignItems="stretch"
                                                                        >
                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                justifyContent="flex-start"
                                                                                alignItems="stretch"
                                                                            >
                                                                                <Grid
                                                                                    container
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    direction="row"
                                                                                    justifyContent="flex-start"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Grid
                                                                                        container
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                        direction="row"
                                                                                        justifyContent="flex-start"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                6
                                                                                            }
                                                                                        >
                                                                                            <IconButton
                                                                                                disabled
                                                                                                tabIndex={
                                                                                                    -1
                                                                                                }
                                                                                            >
                                                                                                <UnfoldMoreRoundedIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Grid>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                6
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                name="sequence"
                                                                                                variant="subtitle2"
                                                                                                className={
                                                                                                    classes.TableCell
                                                                                                }
                                                                                            >
                                                                                                {index +
                                                                                                    1}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        container
                                                                                        item
                                                                                        xs={
                                                                                            4
                                                                                        }
                                                                                        direction="row"
                                                                                        justifyContent="flex-start"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                        >
                                                                                            <TextField
                                                                                                multiline
                                                                                                fullWidth
                                                                                                name="characteristic-box"
                                                                                                size="small"
                                                                                                variant="standard"
                                                                                                className={
                                                                                                    classes.TableCellMargin
                                                                                                }
                                                                                                InputLabelProps={{
                                                                                                    classes:
                                                                                                        {
                                                                                                            root: classes.TableCell,
                                                                                                            input: classes.TableCell
                                                                                                        }
                                                                                                }}
                                                                                                InputProps={{
                                                                                                    disableUnderline: true,
                                                                                                    classes:
                                                                                                        {
                                                                                                            root: classes.TableCell,
                                                                                                            input: classes.TableCell
                                                                                                        }
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    item.Characteristic
                                                                                                        ? item.Characteristic
                                                                                                        : ""
                                                                                                }
                                                                                                onBlur={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleCharacteristicChange(
                                                                                                        e,
                                                                                                        item,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    !permissions.EditCharacteristics ||
                                                                                                    inspectionObjectMemo?.VerifiedByOID
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                        {/* <Grid
                                                                                        item
                                                                                        xs={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() =>
                                                                                                openZoomDialog(
                                                                                                    item
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ZoomInIcon fontSize="small" />
                                                                                        </IconButton>
                                                                                    </Grid> */}
                                                                                    </Grid>

                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            3
                                                                                        }
                                                                                    >
                                                                                        <Autocomplete
                                                                                            disableClearable
                                                                                            id="frequency-selector"
                                                                                            value={
                                                                                                maintenance.frequencies.filter(
                                                                                                    (
                                                                                                        frequency
                                                                                                    ) =>
                                                                                                        frequency.FrequencyId ===
                                                                                                        item.FrequencyId
                                                                                                )[0]
                                                                                                    ?.Frequency
                                                                                            }
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            options={[
                                                                                                ...new Set(
                                                                                                    maintenance.frequencies.map(
                                                                                                        (
                                                                                                            item
                                                                                                        ) =>
                                                                                                            item.Frequency
                                                                                                    )
                                                                                                )
                                                                                            ].sort()}
                                                                                            getOptionLabel={(
                                                                                                option
                                                                                            ) =>
                                                                                                option
                                                                                            }
                                                                                            onChange={(
                                                                                                event
                                                                                            ) =>
                                                                                                frequencyOnChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            renderInput={(
                                                                                                params
                                                                                            ) => (
                                                                                                <TextField
                                                                                                    multiline
                                                                                                    fullWidth
                                                                                                    {...params}
                                                                                                    size="small"
                                                                                                    variant="standard"
                                                                                                    disabled={
                                                                                                        !permissions.EditCharacteristics ||
                                                                                                        inspectionObjectMemo?.VerifiedByOID
                                                                                                    }
                                                                                                    className={
                                                                                                        classes.TableCellMargin
                                                                                                    }
                                                                                                    InputLabelProps={{
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        ...params.InputProps,
                                                                                                        disableUnderline: true,
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            3
                                                                                        }
                                                                                    >
                                                                                        <Autocomplete
                                                                                            disableClearable
                                                                                            autoComplete
                                                                                            id="inspection-method-selector"
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            options={[
                                                                                                ...new Set(
                                                                                                    maintenance.inspectionMethods.map(
                                                                                                        (
                                                                                                            item
                                                                                                        ) =>
                                                                                                            item.InspectionMethod
                                                                                                    )
                                                                                                )
                                                                                            ].sort()}
                                                                                            getOptionLabel={(
                                                                                                option
                                                                                            ) =>
                                                                                                option
                                                                                            }
                                                                                            value={
                                                                                                maintenance.inspectionMethods.filter(
                                                                                                    (
                                                                                                        inspectionMethod
                                                                                                    ) =>
                                                                                                        inspectionMethod.InspectionMethodId ===
                                                                                                        item.InspectionMethodId
                                                                                                )[0]
                                                                                                    ?.InspectionMethod
                                                                                            }
                                                                                            onChange={(
                                                                                                event
                                                                                            ) =>
                                                                                                inspectionMethodOnChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            renderInput={(
                                                                                                params
                                                                                            ) => (
                                                                                                <TextField
                                                                                                    multiline
                                                                                                    fullWidth
                                                                                                    disabled={
                                                                                                        !permissions.EditCharacteristics ||
                                                                                                        inspectionObjectMemo?.VerifiedByOID
                                                                                                    }
                                                                                                    {...params}
                                                                                                    size="small"
                                                                                                    variant="standard"
                                                                                                    className={
                                                                                                        classes.TableCellMargin
                                                                                                    }
                                                                                                    InputLabelProps={{
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        ...params.InputProps,
                                                                                                        disableUnderline: true,
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                </Grid>
                                                                                <Grid
                                                                                    container
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    direction="row"
                                                                                    justifyContent="flex-start"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        <TextField
                                                                                            multiline
                                                                                            fullWidth
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            name="tool-number"
                                                                                            size="small"
                                                                                            variant="standard"
                                                                                            className={
                                                                                                classes.TableCellMargin
                                                                                            }
                                                                                            InputLabelProps={{
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            defaultValue={
                                                                                                item.Tool
                                                                                                    ? item.Tool
                                                                                                    : ""
                                                                                            }
                                                                                            onBlur={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleToolChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            3
                                                                                        }
                                                                                    >
                                                                                        <Autocomplete
                                                                                            disableClearable
                                                                                            autoComplete
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            id="control-method-selector"
                                                                                            value={
                                                                                                maintenance.controlMethods.filter(
                                                                                                    (
                                                                                                        controlMethod
                                                                                                    ) =>
                                                                                                        controlMethod.ControlMethodId ===
                                                                                                        item.ControlMethodId
                                                                                                )[0]
                                                                                                    ?.ControlMethod
                                                                                            }
                                                                                            options={[
                                                                                                ...new Set(
                                                                                                    maintenance.controlMethods.map(
                                                                                                        (
                                                                                                            item
                                                                                                        ) =>
                                                                                                            item.ControlMethod
                                                                                                    )
                                                                                                )
                                                                                            ].sort()}
                                                                                            getOptionLabel={(
                                                                                                option
                                                                                            ) =>
                                                                                                option
                                                                                            }
                                                                                            onChange={(
                                                                                                event
                                                                                            ) =>
                                                                                                controlMethodOnChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                            renderInput={(
                                                                                                params
                                                                                            ) => (
                                                                                                <TextField
                                                                                                    multiline
                                                                                                    fullWidth
                                                                                                    disabled={
                                                                                                        !permissions.EditCharacteristics ||
                                                                                                        inspectionObjectMemo?.VerifiedByOID
                                                                                                    }
                                                                                                    {...params}
                                                                                                    size="small"
                                                                                                    variant="standard"
                                                                                                    className={
                                                                                                        classes.TableCellMargin
                                                                                                    }
                                                                                                    InputLabelProps={{
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                    InputProps={{
                                                                                                        ...params.InputProps,
                                                                                                        disableUnderline: true,
                                                                                                        classes:
                                                                                                            {
                                                                                                                root: classes.TableCell
                                                                                                            }
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        <TextField
                                                                                            multiline
                                                                                            fullWidth
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            name="reaction-box"
                                                                                            size="small"
                                                                                            variant="standard"
                                                                                            className={
                                                                                                classes.TableCellMargin
                                                                                            }
                                                                                            InputLabelProps={{
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            defaultValue={
                                                                                                item.Reaction
                                                                                                    ? item.Reaction
                                                                                                    : ""
                                                                                            }
                                                                                            onBlur={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleReactionChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            2
                                                                                        }
                                                                                    >
                                                                                        <TextField
                                                                                            multiline
                                                                                            fullWidth
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            name="location-box"
                                                                                            size="small"
                                                                                            variant="standard"
                                                                                            className={
                                                                                                classes.TableCellMargin
                                                                                            }
                                                                                            InputLabelProps={{
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            InputProps={{
                                                                                                disableUnderline: true,
                                                                                                classes:
                                                                                                    {
                                                                                                        root: classes.TableCell,
                                                                                                        input: classes.TableCell
                                                                                                    }
                                                                                            }}
                                                                                            defaultValue={
                                                                                                item.Location
                                                                                                    ? item.Location
                                                                                                    : ""
                                                                                            }
                                                                                            onBlur={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleLocationChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                1
                                                                                            }
                                                                                        >
                                                                                            <Checkbox
                                                                                                disabled={
                                                                                                    !permissions.EditCharacteristics ||
                                                                                                    inspectionObjectMemo?.VerifiedByOID
                                                                                                }
                                                                                                name="gage-box"
                                                                                                size="small"
                                                                                                defaultChecked={
                                                                                                    item.Gage
                                                                                                }
                                                                                                onChange={(
                                                                                                    event
                                                                                                ) =>
                                                                                                    handleGageChange(
                                                                                                        event,
                                                                                                        item,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        <Checkbox
                                                                                            disabled={
                                                                                                !permissions.EditCharacteristics ||
                                                                                                inspectionObjectMemo?.VerifiedByOID
                                                                                            }
                                                                                            name="key-box"
                                                                                            size="small"
                                                                                            defaultChecked={
                                                                                                item.Key
                                                                                            }
                                                                                            onChange={(
                                                                                                event
                                                                                            ) =>
                                                                                                handleKeyChange(
                                                                                                    event,
                                                                                                    item,
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Divider
                                                                                        orientation="vertical"
                                                                                        flexItem
                                                                                        className={
                                                                                            classes.CellDivider
                                                                                        }
                                                                                    />
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        {permissions.EditCharacteristics &&
                                                                                            !inspectionObjectMemo?.VerifiedByOID && (
                                                                                                <IconButton
                                                                                                    onClick={(
                                                                                                        e
                                                                                                    ) =>
                                                                                                        openDeleteDialog(
                                                                                                            item
                                                                                                        )
                                                                                                    }
                                                                                                    tabIndex={
                                                                                                        -1
                                                                                                    }
                                                                                                >
                                                                                                    <DeleteRoundedIcon fontSize="small" />
                                                                                                </IconButton>
                                                                                            )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </Draggable>
                                                            </Collapse>
                                                        )
                                                )}
                                        </TransitionGroup>
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Paper>
                </Paper>
            </Zoom>
            {/* Alert Dialog */}
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={alertDialog}
                    autoHideDuration={5000}
                    onClose={handleAlertDialogClose}
                >
                    <Alert
                        onClose={handleAlertDialogClose}
                        severity={alertSeverity}
                        className={classes.Dialog}
                    >
                        {alertText}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default CharactisticTable
